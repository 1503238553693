import React, { useState, useEffect } from "react";
import {
  ClockStyled,
  HourStyled,
  DateStyled,
  HourText,
  DateText
} from "./Clock.style";
import { ClockIcon } from "Assets";
import { formatDateTime } from "Utils";
import { useTranslate } from "i18n";
import moment from "moment";

const Clock: React.FC = () => {
  const { t } = useTranslate();
  moment.locale(t("Wavelop.application.locale"));
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let timeout = 1;
    const timerID = setInterval(() => {
      const delta = 59 - new Date().getSeconds();
      if (delta === 0) {
        timeout = 60;
      }
      tick();
    }, timeout * 1000);
    return (): void => {
      clearInterval(timerID);
    };
  }, []);

  const tick = (): void => {
    setDate(new Date());
  };

  const localeDate = moment(date).locale(t("Wavelop.application.locale"));
  const hourToDisplay: string = formatDateTime(localeDate)[3];
  const dateToDisplay: string =
    t("Wavelop.application.today") +
    formatDateTime(localeDate)[0] +
    " " +
    formatDateTime(localeDate)[1];

  return (
    <ClockStyled>
      <HourStyled>
        <img src={ClockIcon} alt="clock icon" />
        <HourText>{hourToDisplay}</HourText>
      </HourStyled>
      <DateStyled>
        <DateText>{dateToDisplay}</DateText>
      </DateStyled>
    </ClockStyled>
  );
};
export default Clock;
