import React from "react";
import { SortingSelectorStyled, ButtonStyled } from "./SortingSelector.style";
import { DownIcon } from "Assets";
import { useTranslate } from "i18n";
import { useActivityState } from "Services";

const SortingSelector: React.FC = () => {
  const [active, setActive] = React.useState(false);
  const { dispatch } = useActivityState();
  const { t } = useTranslate();

  const handleClick = (): void => {
    setActive(!active);
    const sorting = active ? "asc" : "desc";
    dispatch({
      type: "CHANGE_SORTING",
      sorting
    });
  };

  return (
    <SortingSelectorStyled>
      <ButtonStyled
        btn="primary"
        name="Ordinamento"
        text={t("Wavelop.application.filter.orderByText")}
        type="button"
        onClick={(): void => handleClick()}
        active={active}
        ImageRight={DownIcon}
      />
    </SortingSelectorStyled>
  );
};

export default SortingSelector;
