import { Button } from "Components";
import { useTranslate } from "i18n";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ButtonRowMobile,
  Buttons,
  ButtonsActivities,
  ButtonsActivitiesEdit
} from "./ButtonsRow.style";

interface IProps {
  redirect?: boolean;
  copy?: (event: any) => void;
  destinationTitle?: string;
  queryParams?: string;
  state?: any;
  destinationPath?: string;
  handleReset: () => void;
  onSubmit: (event: any) => Promise<any>;
  saveCopy?: boolean;
}

const ButtonRow: React.FC<IProps> = ({
  redirect,
  copy,
  destinationPath,
  queryParams,
  state,
  destinationTitle,
  handleReset,
  onSubmit,
  saveCopy
}: IProps) => {
  const history = useHistory();
  const { t } = useTranslate();

  const goTo = (where: string, queryParams?: string, state?: any): void => {
    history.push({
      pathname: where,
      search: queryParams,
      state
    });
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth < 768 &&
      destinationPath?.includes("activities") &&
      !destinationTitle?.includes("Undo") ? (
          destinationPath?.includes("edit") ? (
            <ButtonsActivities>
              <span>
                <ButtonRowMobile>
                  <Button
                    btn="reset"
                    onClick={handleReset}
                    name="edit"
                    type="button"
                    text={t("Wavelop.application.activityForm.cancelButtonText")}
                  />
                  {destinationPath && redirect === true && (
                    <Button
                      btn="secondary"
                      name="edit"
                      type="button"
                      text={destinationTitle}
                      onClick={(): void =>
                        goTo(destinationPath, queryParams, state)
                      }
                    />
                  )}
                </ButtonRowMobile>
              </span>
              <span>
                <ButtonRowMobile>
                  {saveCopy && saveCopy === true && (
                    <Button
                      btn="secondary"
                      name="saveCopy"
                      type="button"
                      text={t(
                        "Wavelop.application.activityForm.saveCopyButtonText"
                      )}
                      onClick={copy}
                    />
                  )}
                  <Button
                    btn="primary"
                    name="submit"
                    text={t("Wavelop.application.activityForm.saveButtonText")}
                    type="submit"
                    onClick={onSubmit}
                  />
                </ButtonRowMobile>
              </span>
            </ButtonsActivities>
          ) : (
            <ButtonsActivitiesEdit>
              <span>
                <ButtonRowMobile>
                  <Button
                    btn="reset"
                    onClick={handleReset}
                    name="edit"
                    type="button"
                    text={t("Wavelop.application.activityForm.cancelButtonText")}
                  />
                  {destinationPath && redirect === true && (
                    <Button
                      btn="secondary"
                      name="edit"
                      type="button"
                      text={destinationTitle}
                      onClick={(): void =>
                        goTo(destinationPath, queryParams, state)
                      }
                    />
                  )}
                </ButtonRowMobile>
              </span>
              <span>
                <ButtonRowMobile>
                  {saveCopy && saveCopy === true && (
                    <Button
                      btn="secondary"
                      name="saveCopy"
                      type="button"
                      text={t(
                        "Wavelop.application.activityForm.saveCopyButtonText"
                      )}
                      onClick={copy}
                    />
                  )}
                  <Button
                    btn="primary"
                    name="submit"
                    text={t("Wavelop.application.activityForm.saveButtonText")}
                    type="submit"
                    onClick={onSubmit}
                  />
                </ButtonRowMobile>
              </span>
            </ButtonsActivitiesEdit>
          )
        ) : (
          <Buttons>
            <span>
              <Button
                btn="reset"
                onClick={handleReset}
                name="edit"
                type="button"
                text={t("Wavelop.application.activityForm.cancelButtonText")}
              />
            </span>
            <span>
              <ButtonRowMobile>
                {destinationPath && redirect === true && (
                  <Button
                    btn="secondary"
                    name="edit"
                    type="button"
                    text={destinationTitle}
                    onClick={(): void =>
                      goTo(destinationPath, queryParams, state)
                    }
                  />
                )}
                {saveCopy && saveCopy === true && (
                  <Button
                    btn="secondary"
                    name="saveCopy"
                    type="button"
                    text={t(
                      "Wavelop.application.activityForm.saveCopyButtonText"
                    )}
                    onClick={copy}
                  />
                )}
                <Button
                  btn="primary"
                  name="submit"
                  text={t("Wavelop.application.activityForm.saveButtonText")}
                  type="submit"
                  onClick={onSubmit}
                />
              </ButtonRowMobile>
            </span>
          </Buttons>
        )}
    </>
  );
};

export default ButtonRow;
