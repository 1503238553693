import React from "react";
import {
  CalendarLegendWrapper,
  CalendarLegendElement,
  CalendarLegendLine
} from "./CalendarLegend.style";

interface IProps {
  states: { type: string; description: string }[];
}

const CalendarLegend: React.FC<any> = ({ states }: IProps) => {
  const legendGenerator = (): React.ReactElement => {
    const legendsElement = states.map(({ type, description }, index) => (
      <CalendarLegendElement key={index} type={type}>
        {description}
      </CalendarLegendElement>
    ));

    const result: any = [];

    legendsElement.forEach((legend, index) => {
      if (index % 2 === 1) {
        const temp: any = [];
        temp.push(legendsElement[index - 1]);
        temp.push(legend);
        result.push(temp);
      }
    });
    if (legendsElement.length % 2 === 1) {
      result.push([legendsElement[legendsElement.length - 1]]);
    }

    return result.map((element, index) => {
      return <CalendarLegendLine key={index}>{element}</CalendarLegendLine>;
    });
  };

  return <CalendarLegendWrapper>{legendGenerator()}</CalendarLegendWrapper>;
};

export default CalendarLegend;
