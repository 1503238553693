import React, { useState } from "react";
import {
  InputStyled,
  InputSectionStyled,
  InputLabelStyled,
  InputContainer,
  SpanInput
} from "./InputButton.style";
import { Button } from "Components";
import RateModal from "src/components/RateModal/RateModal";

interface IProps {
  attributes?: any;
  disabled?: boolean;
  error: boolean;
  errorMessage: string;
  htmlFor: string;
  id: string;
  inputLabel: string;
  name: string;
  onBlur: (event: any) => void;
  onChange: (event: any) => void;
  onClick: (event: any) => void;
  placeholder: string;
  readOnly?: boolean;
  required: boolean;
  showErrorOnInput: boolean;
  type: string[];
  value?: string;
  buttonText: string;
  text: string;
}

const InputButton: React.FC<any> = ({
  attributes,
  disabled,
  error,
  htmlFor,
  id,
  inputLabel,
  name,
  onBlur,
  onChange,
  onClick,
  placeholder,
  readOnly,
  required,
  type,
  value
}: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  return (
    <>
      <InputSectionStyled>
        {inputLabel && (
          <InputLabelStyled htmlFor={htmlFor}>{inputLabel}</InputLabelStyled>
        )}
        <InputContainer>
          {type && type[1] === "icon" && (
            <SpanInput styleType={type && type[1]}>
              <img src={attributes?.icon} alt="" />
            </SpanInput>
          )}

          <InputStyled
            disabled={true}
            error={error}
            id={id}
            name={name}
            onBlur={(event: any): void => {
              !readOnly && !disabled && onBlur(event);
            }}
            onChange={(event: any): void => {
              !readOnly && !disabled && onChange(event);
            }}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            type={type && type[0]}
            value={value || ""}
            styleType={type && type[1]}
            min={attributes && attributes.min}
            max={attributes && attributes.max}
          />
          <Button
            btn="secondary"
            name="Button"
            text={attributes.buttonText}
            type="button"
            onClick={() => setShowModal(true)}
          />
        </InputContainer>
      </InputSectionStyled>
      {showModal && (
        <RateModal
          show={showModal}
          closeModal={closeModal}
          handleSubmit={attributes.setRate}
          value={value}
          minDate={attributes.minDate}
          setRate={attributes.setRate}
          destinationPath={attributes.path}
        />
      )}
    </>
  );
};

export default InputButton;
