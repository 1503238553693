import { CrudType, ICrud } from "Models";
import { Crud as crudService } from "Services";
import { config } from "Config";
import { Utils as utilsService } from "Utils";

const create = async (body: any): Promise<any> => {
  try {
    return await crudService(CrudType.Post, config.api.feedback.create, body);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const read = async (id: string, queryparams: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.feedback.read, { id }),
      queryparams
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const update = async (body: any): Promise<any> => {
  try {
    return await crudService(CrudType.Put, config.api.feedback.update, body);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const remove = async (id: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Delete,
      utilsService.apiPlaceholderReplace(config.api.feedback.remove, { id })
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const FeedbackService: ICrud = {
  create: (body: any) => create(body),
  read: (id: string, queryParams: string) => read(id, queryParams),
  update: (body: any) => update(body),
  delete: (id: string) => remove(id)
};
