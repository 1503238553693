import React, { useEffect, useState } from "react";
import {
  TableContentStyled,
  RowStyled,
  ButtonStyled,
  RowStyledMobileProject
} from "./ProjectRow.style";
import { DeleteIcon, ModifyIcon } from "Assets";
import { useTranslate } from "i18n";
import { Link, useHistory } from "react-router-dom";
import "moment/locale/it";
import "moment/locale/en-gb";
import { formatStatus, formatTimeCell, fromHoursToTime } from "Utils";
import { useUserState } from "Services";
export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
  wsRole?: string;
}

const ProjectRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  data,
  wsRole
}: IProps) => {
  const history = useHistory();
  const { t } = useTranslate();
  const [h, m] = fromHoursToTime(data.totalWork);
  const workPerc = formatTimeCell(h, m, data.estimateTime);
  const user = useUserState().state.user;

  const onDeleteButton = (event): void => {
    onDelete(id);
    event.stopPropagation();
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const projectManager = data.users.find(
    projectUser => projectUser.id === user?.id
  )?.isProjectManager;
  return (
    <TableContentStyled
      index={index}
      onClick={(): void | null =>
        wsRole === "admin" || projectManager
          ? history.push(`projects/edit/${id}`)
          : null
      }
    >
      <RowStyled>{data.name}</RowStyled>
      <RowStyled>{data.client.name}</RowStyled>
      {projectManager || wsRole === "admin" ? (
        <>
          {windowWidth < 768 ? <></> : <RowStyled>{workPerc}</RowStyled>}
          <RowStyled>{t(formatStatus(data.status))}</RowStyled>
          {windowWidth < 768 ? (
            <RowStyledMobileProject>
              <Link
                to={`projects/edit/${id}`}
                onClick={(event): void =>
                  wsRole === "admin" || projectManager
                    ? event.stopPropagation()
                    : event.preventDefault()
                }
              ></Link>
              <ButtonStyled
                onClick={
                  wsRole === "admin" || projectManager ? onDeleteButton : null
                }
                type="image"
                src={DeleteIcon}
                alt={t("Wavelop.application.table.altDelete")}
                disabled={wsRole === "admin" || projectManager ? false : true}
              />
            </RowStyledMobileProject>
          ) : (
            <RowStyled>
              <Link
                to={`projects/edit/${id}`}
                onClick={(event): void =>
                  wsRole === "admin" || projectManager
                    ? event.stopPropagation()
                    : event.preventDefault()
                }
              >
                <ButtonStyled
                  type="image"
                  src={ModifyIcon}
                  alt={t("Wavelop.application.table.altEdit")}
                  disabled={wsRole === "admin" || projectManager ? false : true}
                />
              </Link>
              <ButtonStyled
                onClick={wsRole === "admin" ? onDeleteButton : null}
                type="image"
                src={DeleteIcon}
                alt={t("Wavelop.application.table.altDelete")}
                disabled={wsRole === "admin" ? false : true}
              />
            </RowStyled>
          )}
        </>
      ) : (
        <></>
      )}
    </TableContentStyled>
  );
};

export default ProjectRow;
