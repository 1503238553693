import React, { createContext, useContext, useReducer } from "react";
import {
  ActivityContextInterface,
  ActivityStateInterface,
  ActionType
} from "./Models";
import { getCurrentRange } from "Utils";

const dailyRange: Date[] = getCurrentRange(0, new Date(), "it");

export const ActivityContext = createContext<ActivityContextInterface>({
  state: {
    from: dailyRange[0],
    to: dailyRange[1],
    display: "grid",
    sorting: "asc",
    view: "day"
  },
  dispatch: () => {}
});

function activityReducer(
  state: ActivityStateInterface,
  action: ActionType
): ActivityStateInterface {
  const { type, from, to, display, sorting, view } = action;
  switch (type) {
    case "CHANGE_VIEW": {
      return { ...state, from, to, view };
    }
    case "CHANGE_RANGE": {
      return { ...state, from, to };
    }
    case "CHANGE_SORTING": {
      return { ...state, sorting };
    }
    case "CHANGE_DISPLAY": {
      return { ...state, display };
    }
    case "TO_UPDATE": {
      return { ...state };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

export const ActivityProvider: React.FC<{ children: React.ReactNode }> = (
  props: Partial<{ children: React.ReactNode }>
) => {
  const [state, dispatch] = useReducer(activityReducer, {
    from: dailyRange[0],
    to: dailyRange[1],
    display: "grid",
    sorting: "asc",
    view: "day"
  });
  const values = { state, dispatch };

  return (
    <ActivityContext.Provider value={values}>
      {props.children}
    </ActivityContext.Provider>
  );
};

export const useActivityState = (): {
  state: ActivityStateInterface;
  dispatch: (action: ActionType) => void;
} => {
  const context = useContext(ActivityContext);
  if (context === undefined) {
    throw new Error("useActivityState must be used within an ActivityProvider");
  }
  return context;
};
