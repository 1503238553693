import { ToastContainer } from "react-toastify";
import styled from "styled-components";

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress"
})`
  .toast {
    font-size: ${({ theme }): string => theme.fontSize.unit(2)};
    font-weight: bold;
    padding: ${({ theme }): string => theme.spacing.unit(1)}
      ${({ theme }): string => theme.spacing.unit(2)};
    border-radius: ${({ theme }): string => theme.borderRadiusAlert};
    box-shadow: ${({ theme }): string => theme.toastShadow};
    color: ${({ theme }): string => theme.colors.toastTitleColor};
  }

  &&.Toastify__toast-container {
    width: ${({ theme }): string => theme.spacing.unit(30)};
  }

  .Toastify__close-button {
    margin: auto 0;
  }

  .Toastify__close-button {
    color: ${({ theme }): string => theme.colors.toastTitleColor};
  }

  .Toastify__toast--success {
    background-color: ${({ theme }): string =>
    theme.colors.status02Transparence};
  }

  .Toastify__toast--error {
    background-color: ${({ theme }): string =>
    theme.colors.status01Transparence};
  }

  @media (max-width: 480px) {
    .toast {
      width: auto;
      margin: ${({ theme }): string => theme.spacing.unit(2)};
    }

    &&.Toastify__toast-container {
      width: 100%;
    }
  }
`;
