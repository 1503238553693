import styled from "styled-components";

export const FilterStyled = styled.div`
  background-color: ${({ theme }): string => theme.colors.surface};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  margin: ${({ theme }): string => theme.spacing.unit(1.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  padding: ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(2)};
  border-radius: ${({ theme }): string => theme.borderRadiusFilter};
  & > div {
    border-radius: ${({ theme }): string => theme.borderRadiusFilter};
    display: flex;
    margin: ${({ theme }): string => theme.spacing.unit(1)} 0;
  }
  & > div > button {
    padding-left: ${({ theme }): string => theme.spacing.unit(0.75)};
    padding-right: ${({ theme }): string => theme.spacing.unit(0.75)};
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 1024px) {
    background-color: ${({ theme }): string => theme.colors.sidebarBackgorund};
    margin: ${({ theme }): string => theme.spacing.unit(1.5)} 0;
    & > div {
      border-radius: ${({ theme }): string => theme.borderRadiusFilter};
      display: flex;
      margin: ${({ theme }): string => theme.spacing.unit(1)} 0;
    }
  }
`;
