import { Button } from "Components";
import { endOfMonth, getDate, getMonth, getYear, startOfMonth } from "date-fns";
import { useTranslate } from "i18n";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Activity as activityService,
  useLoading,
  useLoadingState,
  useUserState,
  Project as projectService
} from "Services";
import {
  PageTitleH1,
  PageTitleH1Span,
  PageTitleStyled
} from "src/components/PageTitle/PageTitle.style";
import ReportCalendar from "src/components/ReportTable/ReportCalendar";
import ReportChart from "src/components/ReportTable/ReportChart/ReportChart";
import ReportColumnsModal from "src/components/ReportTable/ReportColumnsModal/ReportColumnsModal";
import ReportDatePickerButton from "src/components/ReportTable/ReportDatePickerButton";
import ReportTableNavigation from "src/components/ReportTable/ReportTableFooter/ReportTableNavigation";
import ReportTablePagination from "src/components/ReportTable/ReportTableFooter/ReportTablePagination";
import ReportExportMenu from "src/components/ReportTable/ReportTableHeader/ReportExportMenu";
import ReportsFilter from "src/components/ReportTable/ReportTableHeader/ReportFilter";
import ReportGrouping from "src/components/ReportTable/ReportTableHeader/ReportGrouping";
import {
  TableTitleStyled,
  TitleSpan
} from "src/components/TableAndTitle/TableAndTitle.style";
import { ClientService } from "src/services/Client/Client";
import { ProjectService } from "src/services/Project/Project";
import { UserService } from "src/services/User/User";
import { WorkspaceService } from "src/services/Workspace/Workspace";
import { fromHoursToTimeString } from "Utils";
import ReportTable from "../../components/ReportTable/ReportTable";
import { EmptyActivities } from "../Activities/Activities.style";
import {
  CalendarStyled,
  FilterStyled,
  MaxRowsStyled,
  NavigationStyled
} from "./Reports.style";
import { GroupService } from "src/services/Group/Group";

export interface Item {
  id: string;
  name: string;
  fullName?: string;
}

export interface Filter {
  type: "clients" | "projects" | "tasks" | "users";
  filter: Item[];
}

export interface GroupedData {
  hours: number;
  _id: {
    name: string;
    id: string;
  };
}

const Reports: React.FC = (props: any) => {
  const [data, setData] = useState([]);
  const { startLoading, endLoading, incrementLoading } = useLoading();
  const dispatchLoadingState = useLoadingState().dispatch;
  const { state } = useUserState();

  const [currentRole, setCurrentRole] = useState<string>("team");
  const [maxRows, setMaxRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalRowsGrouped, setTotalRowsGrouped] = useState(0);

  const [selectedColumns, setSelectedColumns] = useState({
    user: true,
    client: true,
    project: true,
    task: true,
    description: true,
    day: true
  });

  const [showColumnsModal, setShowColumnsModal] = useState(false);

  const [exportFormat, setExportFormat] = useState("pdf");
  const [users, setUsers] = useState<Filter>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const [clients, setClients] = useState<Filter>();
  const [selectedClients, setSelectedClients] = useState<string[]>([]);

  const [projects, setProjects] = useState<Filter>();
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const [tasks, setTasks] = useState<Filter>();
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);

  const [totalHours, setTotalHours] = useState<number>(0);

  const [showTimeFilter, setShowTimeFilter] = useState<boolean>(false);
  const [timeFilter, setTimeFilter] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection"
    }
  ]);
  const [groupOne, setGroupOne] = useState<string>("none");
  const [groupTwo, setGroupTwo] = useState<string>("none");
  const [groupThree, setGroupThree] = useState<string>("none");
  const [groupedData, setGroupedData] = useState<GroupedData[]>([]);
  const [chartData, setChartData] = useState([]);

  const [isProjectManager, setIsProjectManager] = useState(false);
  const [isTeamManager, setIsTeamManager] = useState(false);
  const managedProjectsRef = useRef<string[]>([]);
  const managedGroupsRef = useRef<string[]>([]);
  const managedUsersRef = useRef<string[]>([]);

  const calendar = useRef<any>(null);

  const { t } = useTranslate();
  const fetchFilters = useCallback(async () => {
    try {
      const tasksResponse = await ProjectService.readAllTasks("", props.token);
      if (tasksResponse) {
        const tasksFilter: Filter = {
          type: "tasks",
          filter: tasksResponse.items
        };
        setTasks(tasksFilter);
      }
      let usersResponse = await UserService.readByWorkspace("", props.token);
      usersResponse = usersResponse.items;
      const groupsResponse = await GroupService.readByWorkspace(
        "",
        props.token
      );
      usersResponse = usersResponse.concat(groupsResponse.items);
      if (currentRole !== "admin") {
        const allProjects = await projectService.readAll({}, props.token);
        const managedProjects: any = [];
        allProjects.items.forEach((project: any) => {
          project.users.forEach(element => {
            if (element.id === state.user?.id && element.isProjectManager) {
              managedProjects.push(project);
            }
          });
        });
        const managedGroups: any = [];
        groupsResponse.items.forEach((group: any) => {
          group.users.forEach((element: any) => {
            if (element.id === state.user?.id && element.isManager) {
              managedGroups.push(group);
            }
          });
        });
        managedGroupsRef.current = managedGroups.map(
          managedGroup => managedGroup.id
        );
        if (managedGroups.length > 0) setIsTeamManager(true);
        const allUsers = await UserService.readAll({}, props.token);
        const managedUsers: any = [];
        allUsers.forEach((user: any) => {
          managedProjects.forEach((project: any) => {
            project.users.forEach(element => {
              if (element.id === user.id && !managedUsers.includes(user)) {
                managedUsers.push(user);
              }
            });
          });
          managedGroups.forEach((group: any) => {
            group.users.forEach(element => {
              if (element.id === user.id && !managedUsers.includes(user)) {
                managedUsers.push(user);
              }
            });
            if (!managedUsers.includes(group)) managedUsers.push(group);
          });
        });
        if (managedUsers.length > 0) {
          setIsProjectManager(true);
          const managedUsersFilter: Filter = {
            type: "users",
            filter: managedUsers
          };
          managedUsersRef.current = managedUsers.map(
            managedUser => managedUser.id
          );
          setUsers(managedUsersFilter);
          managedProjectsRef.current = managedProjects.map(
            managedProjects => managedProjects.id
          );
        }
      }

      if (usersResponse && currentRole === "admin") {
        const usersFilter: Filter = {
          type: "users",
          filter: usersResponse
        };
        setUsers(usersFilter);
      }
      const projectsResponse = await ProjectService.readAll("", props.token);
      const projectsItems = projectsResponse.items.filter((project: any) => {
        if (state?.user && state?.user.id) {
          return project.users.find(user => user.id === state.user?.id);
        } else {
          return false;
        }
      });
      if (projectsResponse) {
        const projectsFilter: Filter = {
          type: "projects",
          filter:
            state.user?.workspaces.find(
              workspace => workspace.id === state.user?.currentWorkspace
            )?.role === "admin"
              ? projectsResponse.items
              : projectsItems
        };

        setProjects(projectsFilter);
      }
      const clientsResponse = await ClientService.readAll("", props.token);
      const clientsItems = clientsResponse.items.filter(client => {
        return projectsItems.some(project => project.client.id === client.id);
      });
      if (clientsResponse) {
        const clientsFilter: Filter = {
          type: "clients",
          filter:
            state.user?.workspaces.find(
              workspace => workspace.id === state.user?.currentWorkspace
            )?.role === "admin"
              ? clientsResponse.items
              : clientsItems
        };
        setClients(clientsFilter);
      }
      endLoading(dispatchLoadingState);
    } catch (err) {
      console.log(err);
    }
  }, [props.token, endLoading, state.user, currentRole, dispatchLoadingState]);

  const fetchData = useCallback(async () => {
    try {
      const count: number = await activityService.count(
        {
          from: timeFilter[0].startDate.toISOString(),
          to: timeFilter[0].endDate.toISOString(),
          clients:
            selectedClients.length > 0
              ? JSON.stringify(selectedClients)
              : JSON.stringify(null),
          users:
            selectedUsers.length > 0
              ? JSON.stringify(selectedUsers)
              : JSON.stringify(null),
          projects:
            selectedProjects.length > 0
              ? JSON.stringify(selectedProjects)
              : JSON.stringify(null),
          tasks:
            selectedTasks.length > 0
              ? JSON.stringify(selectedTasks)
              : JSON.stringify(null),
          projectManager: JSON.stringify(isProjectManager),
          managedProjects: JSON.stringify(managedProjectsRef.current),
          managedGroups: JSON.stringify(managedGroupsRef.current),
          managedUsers: JSON.stringify(managedUsersRef.current)
        },
        props.token
      );

      const resultData = await activityService.read(
        "report",
        {
          from: timeFilter[0].startDate.toISOString(),
          to: timeFilter[0].endDate.toISOString(),
          sort: -1,
          limit: maxRows,
          clients:
            selectedClients.length > 0
              ? JSON.stringify(selectedClients)
              : JSON.stringify(null),
          users:
            selectedUsers.length > 0
              ? JSON.stringify(selectedUsers)
              : JSON.stringify(null),
          projects:
            selectedProjects.length > 0
              ? JSON.stringify(selectedProjects)
              : JSON.stringify(null),
          tasks:
            selectedTasks.length > 0
              ? JSON.stringify(selectedTasks)
              : JSON.stringify(null),
          skip: currentPage === 1 ? 0 : (currentPage - 1) * maxRows,
          groupOne: groupOne,
          groupTwo: groupTwo,
          groupThree: groupThree,
          projectManager: isProjectManager,
          managedProjects: JSON.stringify(managedProjectsRef.current),
          managedGroups: JSON.stringify(managedGroupsRef.current),
          managedUsers: JSON.stringify(managedUsersRef.current)
        },
        props.token
      );
      const chartResultData = await activityService.read(
        "chart",
        {
          from: timeFilter[0].startDate.toISOString(),
          to: timeFilter[0].endDate.toISOString(),
          sort: -1,
          clients:
            selectedClients.length > 0
              ? JSON.stringify(selectedClients)
              : JSON.stringify(null),
          users:
            selectedUsers.length > 0
              ? JSON.stringify(selectedUsers)
              : JSON.stringify(null),
          projects:
            selectedProjects.length > 0
              ? JSON.stringify(selectedProjects)
              : JSON.stringify(null),
          tasks:
            selectedTasks.length > 0
              ? JSON.stringify(selectedTasks)
              : JSON.stringify(null),
          groupOne: groupOne === "none" ? "day" : groupOne,
          projectManager: isProjectManager,
          managedProjects: JSON.stringify(managedProjectsRef.current),
          managedGroups: JSON.stringify(managedGroupsRef.current),
          managedUsers: JSON.stringify(managedUsersRef.current)
        },
        props.token
      );
      if (chartResultData) {
        setChartData(chartResultData);
      }
      if (count) {
        setTotalRows(count);
      }

      if (resultData) {
        setData(resultData.data.items);
        setGroupedData(resultData.groupOne);
        if (resultData.data.count > 0) {
          setTotalHours(resultData.total[0].sum);
          setTotalRowsGrouped(resultData.groupOneCount[0].groupOneCount);
        }
      }
      endLoading(dispatchLoadingState);
    } catch (err) {
      console.log(err);
    }
  }, [
    timeFilter,
    selectedClients,
    selectedUsers,
    selectedProjects,
    selectedTasks,
    props.token,
    maxRows,
    currentPage,
    groupOne,
    groupTwo,
    groupThree,
    endLoading,
    dispatchLoadingState,
    isProjectManager,
    managedProjectsRef,
    managedGroupsRef,
    managedUsersRef
  ]);

  const resetFilters = () => {
    setSelectedClients([]);
    setSelectedUsers([]);
    setSelectedProjects([]);
    setSelectedTasks([]);
    setTimeFilter([
      {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        key: "selection"
      }
    ]);
  };

  const closeCalendarOnClick = e => {
    if (
      calendar.current &&
      showTimeFilter &&
      !calendar.current.contains(e.target)
    ) {
      setShowTimeFilter(false);
    }
  };

  document.addEventListener("mousedown", closeCalendarOnClick);

  const openColumnsModal = (type: string) => {
    setShowColumnsModal(true);
  };

  const closeColumnsModal = () => {
    setShowColumnsModal(false);
  };

  const exportAs = async (type: string) => {
    let file: any = "";
    const queryparams = {
      from: timeFilter[0].startDate,
      to: timeFilter[0].endDate,
      sort: -1,
      limit: Number.MAX_SAFE_INTEGER,
      clients:
        selectedClients.length > 0
          ? JSON.stringify(selectedClients)
          : JSON.stringify(null),
      users:
        selectedUsers.length > 0
          ? JSON.stringify(selectedUsers)
          : JSON.stringify(null),
      projects:
        selectedProjects.length > 0
          ? JSON.stringify(selectedProjects)
          : JSON.stringify(null),
      tasks:
        selectedTasks.length > 0
          ? JSON.stringify(selectedTasks)
          : JSON.stringify(null),
      skip: 0,
      groupOne: groupOne,
      groupTwo: groupTwo,
      groupThree: groupThree
    };
    const workspace = await WorkspaceService.readOne(
      state.user?.currentWorkspace || "",
      "",
      props.token
    );
    const data = await activityService.read("report", queryparams, props.token);
    data.groupings = {
      groupOne: groupOne,
      groupTwo: groupTwo,
      groupThree: groupThree
    };
    data.columns = {
      columns: selectedColumns
    };
    data.dates = {
      from: timeFilter[0].startDate,
      to: timeFilter[0].endDate
    };
    data.currentWorkspace = {
      name: workspace.item.name
    };
    data.locale = {
      locale: t("Wavelop.application.locale")
    };
    if (type === "csv") {
      file = await activityService.getCSV(data, props.token);
    } else if (type === "pdf") {
      file = Uint8Array.from(
        atob(await activityService.getPDF(data, props.token)),
        c => c.charCodeAt(0)
      );
    }

    const url = window.URL.createObjectURL(new Blob([file]));
    const downloadLink = document.createElement("a");

    downloadLink.href = url;
    downloadLink.download = file;
    downloadLink.setAttribute(
      "download",
      `${getYear(new Date())}${getMonth(new Date())}${getDate(
        new Date()
      )}report.${type === "csv" ? "csv" : "pdf"}`
    );
    downloadLink.click();
  };
  useEffect(() => {
    if (groupOne === "none") {
      setGroupTwo("none");
      setGroupThree("none");
    }
    if (groupOne === groupTwo) {
      setGroupTwo("none");
    }
    if (groupOne === groupThree) {
      setGroupThree("none");
    }
    if (groupTwo === groupThree) {
      setGroupThree("none");
    }
  }, [groupOne, groupThree, groupTwo]);

  useEffect(() => {
    startLoading(dispatchLoadingState);
    incrementLoading(dispatchLoadingState);
    setCurrentRole(
      state.user?.workspaces?.filter?.(
        row => row.id === state.user?.currentWorkspace
      )[0].role || ""
    );
    fetchFilters().then(() => fetchData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatchLoadingState,
    incrementLoading,
    startLoading,
    maxRows,
    state,
    currentPage,
    timeFilter,
    groupOne,
    groupTwo,
    groupThree,
    isProjectManager,
    managedProjectsRef,
    managedGroupsRef,
    currentRole,
    managedUsersRef
  ]);

  return clients ? (
    <React.Fragment>
      <PageTitleStyled>
        <PageTitleH1Span>
          <PageTitleH1>Reports</PageTitleH1>
        </PageTitleH1Span>
        <ReportDatePickerButton
          setShowTimeFilter={setShowTimeFilter}
          timeFilter={timeFilter}
        />
        {showTimeFilter && (
          <CalendarStyled ref={calendar}>
            <ReportCalendar
              timeFilter={timeFilter}
              setTimeFilter={setTimeFilter}
            />
          </CalendarStyled>
        )}
      </PageTitleStyled>
      <FilterStyled>
        <ReportsFilter
          type={clients?.type}
          filters={clients}
          setCurrentPage={setCurrentPage}
          selectedFilters={selectedClients}
          setSelectedFilters={setSelectedClients}
        />

        {currentRole === "admin" && (
          <ReportsFilter
            type={users?.type}
            filters={users}
            setCurrentPage={setCurrentPage}
            selectedFilters={selectedUsers}
            setSelectedFilters={setSelectedUsers}
          />
        )}
        {currentRole !== "admin" && (isProjectManager || isTeamManager) && (
          <ReportsFilter
            type={users?.type}
            filters={users}
            setCurrentPage={setCurrentPage}
            selectedFilters={selectedUsers}
            setSelectedFilters={setSelectedUsers}
          />
        )}
        <ReportsFilter
          type={projects?.type}
          filters={projects}
          setCurrentPage={setCurrentPage}
          selectedFilters={selectedProjects}
          setSelectedFilters={setSelectedProjects}
        />
        <ReportsFilter
          type={tasks?.type}
          filters={tasks}
          setCurrentPage={setCurrentPage}
          selectedFilters={selectedTasks}
          setSelectedFilters={setSelectedTasks}
        />
        <Button
          style={{ height: "100%", marginLeft: "auto", marginRight: "-4px" }}
          btn="secondary"
          name="resetFilters"
          onClick={resetFilters}
          text="Reset"
          type="button"
        />
        <Button
          style={{ height: "100%" }}
          btn="primary"
          name="confirmFilter"
          onClick={() => {
            fetchData();
            setCurrentPage(1);
          }}
          text={t("Wavelop.application.modalConfirm.confirmButton")}
          type="button"
        />
      </FilterStyled>
      <FilterStyled>
        <span style={{ fontWeight: "400" }}>
          {t("Wavelop.application.reportsGroupings.title")}
        </span>
        <ReportGrouping
          group={groupOne}
          setGroup={setGroupOne}
          setCurrentPage={setCurrentPage}
        />
        {groupOne !== "none" && (
          <ReportGrouping
            group={groupTwo}
            groupOne={groupOne}
            setGroup={setGroupTwo}
            setCurrentPage={setCurrentPage}
          />
        )}
        {groupOne !== "none" && groupTwo !== "none" && (
          <ReportGrouping
            group={groupThree}
            groupOne={groupOne}
            groupTwo={groupTwo}
            setGroup={setGroupThree}
            setCurrentPage={setCurrentPage}
          />
        )}
        <ReportExportMenu
          groupOne={groupOne}
          setExportFormat={setExportFormat}
          exportAs={exportAs}
          openColumnsModal={openColumnsModal}
        />
      </FilterStyled>
      {showColumnsModal && (
        <ReportColumnsModal
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          exportFormat={exportFormat}
          closeColumnsModal={closeColumnsModal}
          show={showColumnsModal}
          confirm={exportAs}
        />
      )}
      {data.length > 0 ? (
        <React.Fragment>
          <TableTitleStyled>
            <span>{t("Wavelop.application.reportsTable.totalHours")}</span>
            <TitleSpan>{fromHoursToTimeString(totalHours)}</TitleSpan>
          </TableTitleStyled>
          <ReportChart chartData={chartData} />
          {groupOne === "none" || groupedData[0]._id.name === "undefined" ? (
            <ReportTable data={data} />
          ) : (
            <ReportTable data={groupedData} />
          )}
          <NavigationStyled>
            <div>
              <ReportTableNavigation
                maxRows={maxRows}
                totalRows={
                  groupOne === "none" || groupedData[0]._id.name === "undefined"
                    ? totalRows
                    : totalRowsGrouped
                }
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <MaxRowsStyled>
              <ReportTablePagination
                setCurrentPage={setCurrentPage}
                rows={maxRows}
                setRows={setMaxRows}
              />
            </MaxRowsStyled>
          </NavigationStyled>
        </React.Fragment>
      ) : (
        <EmptyActivities>
          <h2>Non è registrata alcuna attività</h2>
        </EmptyActivities>
      )}
    </React.Fragment>
  ) : null;
};

export default Reports;
