import { RouteType } from "../../models/Components/IComponents";
import {
  ClientsIcon,
  ProjectsIcon,
  ReportsIcon,
  TeamIcon,
  SettingsIcon,
  ActivitiesIcon
} from "Assets";

export const navbarConfig = (
  routeType: RouteType,
  t,
  workspaceId: any,
  role: any
): { [key: string]: any } => {
  if (routeType === RouteType.PRIVATE) {
    if (role === "admin") {
      return {
        mainLinks: [
          {
            icon: ActivitiesIcon,
            alt: t("Wavelop.application.navbar.activity.alt"),
            to: "/activities",
            text: t("Wavelop.application.navbar.activity.text")
          },
          {
            icon: ReportsIcon,
            alt: t("Wavelop.application.navbar.reports.alt"),
            to: "/reports",
            text: t("Wavelop.application.navbar.reports.text")
          },
          {
            icon: ProjectsIcon,
            alt: t("Wavelop.application.navbar.projects.alt"),
            to: "/projects",
            text: t("Wavelop.application.navbar.projects.text")
          },
          {
            icon: ClientsIcon,
            alt: t("Wavelop.application.navbar.clients.alt"),
            to: "/clients",
            text: t("Wavelop.application.navbar.clients.text")
          },
          {
            icon: TeamIcon,
            alt: t("Wavelop.application.navbar.team.alt"),
            to: "/team",
            text: t("Wavelop.application.navbar.team.text")
          }
        ],
        footerLinks: [
          {
            icon: SettingsIcon,
            alt: t("Wavelop.application.navbar.settings.alt"),
            to: `/settings/${workspaceId}`,
            text: t("Wavelop.application.navbar.settings.text")
          }
        ]
      };
    } else if (role === "team")
      return {
        mainLinks: [
          {
            icon: ActivitiesIcon,
            alt: t("Wavelop.application.navbar.activity.alt"),
            to: "/activities",
            text: t("Wavelop.application.navbar.activity.text")
          },
          {
            icon: ReportsIcon,
            alt: t("Wavelop.application.navbar.reports.alt"),
            to: "/reports",
            text: t("Wavelop.application.navbar.reports.text")
          },
          {
            icon: ProjectsIcon,
            alt: t("Wavelop.application.navbar.projects.alt"),
            to: "/projects",
            text: t("Wavelop.application.navbar.projects.text")
          }
        ],
        footerLinks: []
      };
  }
  return {};
};
