import React from "react";

import { Clock } from "Components";
import { RouteType } from "Models";
import { useAppState } from "Services";
import { splitTitle } from "Utils";

import {
  PageTitleH1,
  PageTitleH1Span,
  PageTitleStyled
} from "./PageTitle.style";

interface IProps {
  routeType: RouteType;
}

const PageTitle: React.FC<IProps> = ({ routeType }: IProps) => {
  const { state } = useAppState();
  const { title } = state || {};

  return state.page !== "/reports" ? (
    <PageTitleStyled>
      <PageTitleH1Span>
        <PageTitleH1>{title ? splitTitle(title) : ""}</PageTitleH1>
      </PageTitleH1Span>
      {routeType === RouteType.PRIVATE && state.page !== "/reports" && (
        <Clock />
      )}
    </PageTitleStyled>
  ) : null;
};

export default PageTitle;
