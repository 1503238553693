import React, { createContext, useContext, useReducer } from "react";
import { AppContextInterface, AppStateInterface, ActionType } from "./Models";

export const AppContext = createContext<AppContextInterface>({
  state: { page: "/", title: "Timesheet", parameters: [""] },
  dispatch: () => {}
});

function appReducer(
  state: AppStateInterface,
  action: ActionType
): AppStateInterface {
  const { type, page, title, parameters, workspaces, fetchUser } = action;
  switch (type) {
    case "CHANGE_PAGE": {
      return { ...state, page, title, parameters };
    }
    case "GET_WORKSPACES": {
      return { ...state, workspaces, fetchUser };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

export const AppProvider: React.FC<{ children: React.ReactNode }> = (
  props: Partial<{ children: React.ReactNode }>
) => {
  const [state, dispatch] = useReducer(appReducer, {
    page: "/",
    title: "Timesheet",
    parameters: [""]
  });
  const values = { state, dispatch };

  return (
    <AppContext.Provider value={values}>{props.children}</AppContext.Provider>
  );
};

export const useAppState = (): {
  state: AppStateInterface;
  dispatch: (action: ActionType) => void;
} => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within an AppProvider");
  }
  return context;
};
