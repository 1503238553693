import { CrudType, IClient } from "Models";
import { Crud as crudService } from "Services";
import { config } from "Config";
import { Utils as utilsService } from "Utils";

const create = async (body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.clients.create,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const read = async (id: string, queryparams: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.clients.read, { id }),
      null,
      queryparams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readAll = async (queryparams: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.clients.readAll,
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const update = async (id: string, body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      utilsService.apiPlaceholderReplace(config.api.clients.update, { id }),
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const remove = async (id: string, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Delete,
      utilsService.apiPlaceholderReplace(config.api.clients.remove, { id }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ClientService: IClient = {
  create,
  read,
  update,
  readAll,
  delete: remove
};
