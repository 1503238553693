import styled from "styled-components";

export const DropdownList = styled.ul`
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
`;

export const DropdownBody = styled.div`
  border-radius: ${({ theme }): string =>
    theme.borderRadiusQuickInsertionSelect};
  padding: 0;
  margin: ${({ theme }): string => theme.spacing.unit(0.5)} 0 0 0;
  background: ${({ theme }): string => theme.colors.surface};
  position: absolute;
  width: 100%;
  box-shadow: ${({ theme }): string => theme.selectListShadow};
  z-index: 1;
  max-height: ${({ theme, type }): string =>
    type !== "secondary" ? theme.spacing.unit(10) : "none"};
  overflow-y: auto;
  text-align: ${({ type }): string =>
    type === "secondary" ? "center" : "left"};
`;
