import { config } from "Config";
import moment from "moment";

export const groupByDate = (activities: any[]): any => {
  const groups = {};
  activities &&
    activities.forEach(item => {
      const date =
        item.day.getFullYear() +
        "-" +
        (item.day.getMonth() + 1) +
        "-" +
        item.day.getDate();
      if (date in groups) {
        groups[date].push(item);
      } else {
        groups[date] = new Array(item);
      }
    });
  return groups;
};

export const splitDateTime = (date: any): string => {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

export const formatDateTitle = (date: string, locale: string): string => {
  return moment(date, "YYYY-MM-DD")
    .locale(locale)
    .format("D MMMM");
};
export const checkDateIsValidAndFormat = (data: string, locale: string) => {
  let check;
  if (moment(data).isValid()) {
    check = moment(data.split("T")[0], undefined, true)
      .locale(locale)
      .format("D MMMM, YYYY");
  } else {
    return data;
  }

  return check;
};
export const formatDateRow = (date: string, locale: string): string => {
  const splittedDate = splitDateTime(date);
  return moment(splittedDate, "YYYY-MM-DD")
    .locale(locale)
    .format("D MMM, YYYY");
};

export const dailyHours = (activities: any[]): number => {
  let totalHoursPerDay = 0;
  activities &&
    activities.forEach(item => {
      return (totalHoursPerDay += item.work);
    });
  return totalHoursPerDay;
};

export const formatTotalHours = (activities: any): string => {
  let totalHours = "";
  if (fromHoursToTime(dailyHours(activities))[0] > 0)
    totalHours = fromHoursToTime(dailyHours(activities))[0].toString() + "h ";
  else totalHours = "";
  if (fromHoursToTime(dailyHours(activities))[1] > 0)
    totalHours =
      totalHours + fromHoursToTime(dailyHours(activities))[1].toString() + "m";
  else totalHours = totalHours + "";
  return totalHours;
};

export const planningColors = (day: string, activities: any[]): string => {
  const currentDay = moment();
  const hoursPerDay: number = dailyHours(activities);
  if (
    (moment(day).isBefore(currentDay, "day") ||
      moment(day).isSame(currentDay, "day")) &&
    hoursPerDay < config.workspace.defaultWorkingHour
  )
    return "red";
  if (
    (moment(day).isBefore(currentDay, "day") ||
      moment(day).isSame(currentDay, "day")) &&
    hoursPerDay >= 8
  )
    return "green";
  if (
    moment(day).isAfter(currentDay, "day") &&
    hoursPerDay >= config.workspace.defaultWorkingHour
  )
    return "blue";
  if (
    moment(day).isAfter(currentDay, "day") &&
    hoursPerDay < config.workspace.defaultWorkingHour &&
    hoursPerDay > 0
  )
    return "yellow";
  return "white";
};

export const getCurrentRange = (index: number, locale: string): Date[] => {
  const result: Date[] = [];
  if (index === 0) {
    result[0] = new Date(moment().format("YYYY-MM-DD"));
    result[1] = new Date(
      moment()
        .add(1, "days")
        .format("YYYY-MM-DD")
    );
  }
  if (index === 1) {
    result[0] = moment
      .utc()
      .locale(locale)
      .startOf("isoWeek")
      .toDate();
    result[1] = moment
      .utc()
      .locale(locale)
      .endOf("week")
      .toDate();
  }
  if (index === 2) {
    result[0] = moment
      .utc()
      .locale(locale)
      .startOf("month")
      .toDate();
    result[1] = moment
      .utc()
      .locale(locale)
      .endOf("month")
      .toDate();
  }
  return result;
};

export const getContextView = (index: number): "day" | "week" | "month" => {
  if (index === 0) {
    return "day";
  }
  if (index === 1) {
    return "week";
  }
  if (index === 2) {
    return "month";
  }
  return "day";
};

export const formatFormErrors = (
  errorType: string,
  numberOfErrors: number,
  errors: any
): any => {
  if (errorType === "server")
    return {
      errorType,
      numberOfErrors: 1,
      errors
    };
  if (errorType === "validation")
    return {
      errorType,
      numberOfErrors,
      errors
    };
  return {
    numberOfErrors: 0,
    errors: {}
  };
};

export const formatProjectOptions = (data: any): any => {
  return data && data.map(a => ({ value: a.id, name: a.name }));
};

export const formatTaskOptions = (data: any, project: string): any => {
  return data
    ?.filter(it => (project ? it.id === project : ""))
    ?.map(a => a.tasks)[0]
    ?.map((el: any) => ({ value: el, name: el }));
};

export const formatWorkplaceOptions = (
  office: string,
  remote: string,
  client: string
): any => {
  return [
    {
      value: "office",
      name: office
    },
    {
      value: "remote",
      name: remote
    },
    {
      value: "client",
      name: client
    }
  ];
};

export const formatWorkplace = (workplace: string): string => {
  if (workplace === "remote") return "Wavelop.application.workplace.remote";
  if (workplace === "office") return "Wavelop.application.workplace.office";
  if (workplace === "client") return "Wavelop.application.workplace.client";
  return "";
};

export const formatMinutesOptions = (): Array<any> => {
  let options: Array<any> = [];
  for (let i = 0; i <= 45; i = i + 15) {
    options = [
      ...options,
      {
        name: i === 0 ? i.toString() + "0" : i.toString(),
        value: i.toString()
      }
    ];
  }
  return options;
};

export const fromTimeToHours = (hours: number, minutes: number): number => {
  return hours + minutes * (1 / 60);
};

export const fromHoursToTime = (hours: number): Array<number> => {
  const hour = Math.floor(hours);
  const minutes = (hours - hour) * 60;
  return [hour, minutes];
};

export const fromHoursToTimeString = (hours: number): string => {
  const hour = Math.floor(hours);
  const minutes = (hours - hour) * 60;
  return `${hour}:${minutes < 10 ? "0" + minutes : minutes}`;
};

export const formatErrorObject = (errors: any, arr: Array<any>): Array<any> => {
  errors &&
    Object.values(errors).forEach((val: any) => {
      const obj = val[0] !== undefined ? val[0].message : "";
      arr = [...arr, obj];
    });
  return arr;
};

export const appendError = (
  e: any,
  entity: string,
  kind: string,
  message: string
): any => {
  return {
    errors: {
      ...e.errors,
      [entity]: [
        {
          kind,
          message
        }
      ]
    },
    numberOfErrors: (e as any).numberOfErrors
      ? (e as any).numberOfErrors + 1
      : 1,
    errorType: e.errorType
  };
};
