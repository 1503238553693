import { Select, CustomRow } from "Components";

export const form: any = (t: any, data: any, icons: any, id: string) => {
  const result = [
    {
      name: "row1",
      tag: "row",
      customRow: CustomRow,
      fields: [
        {
          name: "cell1",
          tag: "row",
          customRow: CustomRow,
          fields: [
            {
              name: "role",
              helperText: t("Wavelop.application.teamForm.rolePlaceholder"),
              type: ["team"],
              attributes: {
                class: "large"
              },
              tag: Select,
              readOnly: data.codeDisabled,
              options: [
                {
                  name: t("Wavelop.application.roleForm.roleOption1"),
                  value: "admin",
                  fatherId: id
                },
                {
                  name: t("Wavelop.application.roleForm.roleOption2"),
                  value: "team",
                  fatherId: id
                }
              ],
              outerWidth: "110%"
            }
          ]
        }
      ]
    }
  ];
  return result;
};
