// Font face imported in global.css
// This to avoid flickering
// - https://styled-components.com/docs/faqs#how-do-i-fix-flickering-text-after-server-side-rendering
// - https://github.com/styled-components/styled-components/issues/1593

const fontConfig = {
  default: {
    thin: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "100"
    },
    extraLight: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "200"
    },
    light: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "300"
    },
    regular: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "400"
    },
    medium: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "500"
    },
    semiBold: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "600"
    },
    bold: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "700"
    },
    extraBold: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "800"
    },
    black: {
      fontFamily: "'Heebo', Helvetica, 'Open Sans', Arial, sans-serif",
      fontWeight: "900"
    }
  }
};

export const configureFonts = (
  config: { [key: string]: any } = {}
): { [key: string]: any } => {
  return { ...fontConfig, ...config };
};
