import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import { useTranslate } from "i18n";
import React from "react";

export interface PaginationProps {
  setCurrentPage: any;
  rows: number;
  setRows: any;
}

const ReportTablePagination: React.FC<PaginationProps> = (
  props: PaginationProps
) => {
  const options = ["5", "10", "20"];
  const { t } = useTranslate();

  const handleChange = (event: any) => {
    props.setRows(event.target.value);
    props.setCurrentPage(1);
  };
  const getMenuItems = () => {
    return options.map((item, index) => {
      return (
        <MenuItem value={item} key={index}>
          {item}
        </MenuItem>
      );
    });
  };

  return (
    <Box>
      <FormControl
        style={{ minWidth: "200px" }}
        variant={"outlined"}
        size="small"
      >
        <InputLabel>{t("Wavelop.application.reportsTable.maxRows")}</InputLabel>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          value={props.rows}
          input={
            <OutlinedInput
              style={{ borderRadius: "12px", backgroundColor: "#eff3fb" }}
              label={t("Wavelop.application.reportsTable.maxRows")}
            />
          }
          label="group"
          onChange={handleChange}
        >
          {getMenuItems()}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ReportTablePagination;
