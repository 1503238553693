import { useTranslate } from "i18n";
import React from "react";
import { Button } from "../Form";

interface ReportDatePickerButtonProps {
  setShowTimeFilter: any;
  timeFilter: any;
}
const ReportDatePickerButton: React.FC<ReportDatePickerButtonProps> = (
  props: ReportDatePickerButtonProps
) => {
  const { t } = useTranslate();
  return (
    <Button
      style={{
        height: "100%",
        position: "relative",
        backgroundColor: "#f6f9fd"
      }}
      btn="reset"
      name="timeFilter"
      onClick={() => {
        props.setShowTimeFilter(true);
      }}
      text={
        props.timeFilter[0].startDate.getDate().toString() +
        " " +
        props.timeFilter[0].startDate.toLocaleDateString(
          t("Wavelop.application.reportsCalendar.locale"),
          {
            month: "long"
          }
        ) +
        " - " +
        props.timeFilter[0].endDate.getDate().toString() +
        " " +
        props.timeFilter[0].endDate.toLocaleDateString(
          t("Wavelop.application.reportsCalendar.locale"),
          {
            month: "long"
          }
        )
      }
      type="button"
    />
  );
};

export default ReportDatePickerButton;
