import styled from "styled-components";

export const ButtonStyled = styled.button`
  text-align: center;

  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};

  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};

  margin: 0 ${({ theme }): string => theme.spacing.unit(0.25)};

  background-color: ${({ btn, theme }): string => {
    if (btn === "primary") return theme.colors.buttonFilterBackgroundActive;
    if (btn === "secondary") return theme.colors.surface;
    if (btn === "reset") return theme.colors.resetButtonBackground;
    if (btn === "disabled") return theme.colors.surface;
    return theme.colors.buttonFilterBackground;
  }};

  color: ${({ theme, btn }): string => {
    if (btn === "primary") return theme.colors.surface;
    if (btn === "secondary") return theme.colors.secondaryButtonText;
    if (btn === "disabled") return theme.colors.disabledButtonText;
    return theme.colors.onSurface;
  }};

  box-shadow: ${({ theme, btn }): string => {
    if (btn === "primary") return theme.colors.buttonPrimaryShadow;
    if (btn === "reset") return theme.colors.buttonResetShadow;
    return "";
  }};

  /*z-index: ${({ btn }): string => {
    if (btn === "primary") return "1";
    return "0";
  }};*/

  border: ${({ theme, btn }): string => {
    if (btn === "primary")
      return "1px solid " + theme.colors.buttonFilterBackgroundActive;
    if (btn === "secondary")
      return "1px solid " + theme.colors.secondaryButtonBorder;
    if (btn === "reset")
      return "1px solid " + theme.colors.resetButtonBackground;
    return "1px solid " + theme.colors.buttonFilterBackground;
  }};

  outline: none;

  transition: 0.3s;

  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(0.75)};
  cursor: pointer;
  border-radius: ${({ theme }): string => theme.borderRadiusTables};
  & > span > svg > path {
    fill: ${({ btn, theme }): string =>
    btn === "primary" ? theme.colors.surface : theme.colors.onSurface};
  }

  cursor: ${({ btn }): string => {
    let result = "pointer";
    result = btn === "disabled" ? "not-allowed" : result;
    return result;
  }};

  &:focus,
  &:hover {
    background-color: ${({ btn, theme }): string => {
    if (btn === "primary")
      return theme.colors.darken(theme.colors.buttonFilterBackgroundActive);
    if (btn === "secondary")
      return theme.colors.alpha(
        theme.colors.surface,
        0.1,
        theme.colors.primary
      );
    if (btn === "reset")
      return theme.colors.darken(theme.colors.resetButtonBackground);
    if (btn === "disabled") return theme.colors.surface;
    return theme.colors.darken(theme.colors.buttonFilterBackground);
  }};

    &:focus {
      outline: none;
      box-shadow: ${({ theme, btn }): string => {
    if (btn === "primary") return theme.colors.buttonPrimaryShadowDarker;
    if (btn === "secondary")
      return theme.colors.buttonSecondaryShadowDarker;
    if (btn === "reset") return theme.colors.buttonResetShadowDarker;
    return "";
  }};
    }

    border: ${({ theme, btn }): string => {
    if (btn === "primary")
      return (
        "1px solid " +
          theme.colors.darken(theme.colors.buttonFilterBackgroundActive)
      );
    if (btn === "secondary")
      return "1px solid " + theme.colors.secondaryButtonBorder;
    if (btn === "reset")
      return (
        "1px solid " + theme.colors.darken(theme.colors.resetButtonBackground)
      );
    return "1px solid " + theme.colors.buttonFilterBackground;
  }};
  }
`;

export const IconLeft = styled.span`
  padding-right: ${({ theme }): string => theme.spacing.unit(0.5)};
  vertical-align: middle;
`;

export const IconRight = styled.span`
  padding-left: ${({ theme }): string => theme.spacing.unit(0.5)};
  vertical-align: middle;
`;
