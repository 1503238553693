export const dev = {
  api: {
    prefix: "/api",
    feedback: {
      create: "/feedback",
      read: "/feedback/{id}",
      update: "/feedback",
      remove: "/feedback/{id}"
    },
    activities: {
      create: "/activities",
      createBulk: "/activities/bulk",
      read: "/activities/{id}",
      count: "/activities/count",
      csv: "/activities/report/csv",
      pdf: "/activities/report/pdf",
      readByClient: "/activities/client",
      update: "/activities/{id}",
      remove: "/activities/{id}",
      getHoursSummary: "/activities/stats/hours-summary/"
    },
    clients: {
      create: "/client",
      read: "/clients/{id}",
      readAll: "/clients/",
      update: "/client/{id}",
      remove: "/client/{id}"
    },
    user: {
      create: "/user",
      read: "/userinfo",
      readAll: "/user",
      readByWorkspace: "/users/workspace",
      readById: "/user/{id}",
      checkUserInvite: "/userInviteCheck/{inviteToken}",
      update: "/user",
      updateTeamUser: "/teamUser/{id}",
      updateCurrentWorkspace: "/user/changeworkspace",
      updateInviteWorkspaces: "/user/{reply}/{inviteToken}",
      updateInfoCurrentWorkspace: "/userTeam/{id}/updateInfoWorkspace",
      remove: "/user/{id}"
    },
    projects: {
      create: "/project",
      read: "/projects/user",
      readAll: "/projects",
      readAllTasks: "/projects/tasks",
      readByClients: "/projects/client/",
      readOne: "/project/{id}",
      update: "/project/{id}",
      remove: "/project/{id}"
    },
    workspace: {
      create: "/workspace",
      readOne: "/workspace/{id}",
      readToken: "/invite/{inviteToken}",
      readByUser: "/workspaces/user",
      read: "/workspaces",
      update: "/workspace/{id}",
      remove: "/workspace/{id}",
      invite: "/team/workspace/invite"
    },
    groups: {
      create: "/group",
      update: "/group/{id}",
      remove: "/group/{id}",
      read: "/group/{id}",
      readByWorkspace: "/groups/workspace",
      readAll: "/groups",
      readByUser: "/groups/user/{id}",
      removeUser: "groups/user"
    }
  },
  auth0: {
    domain: "wavelop.eu.auth0.com",
    clientId: "5yooEU5403CJfddm5ZWjE1m9DO2Dr3Xu",
    redirectUri: `${window.location.origin}/`,
    audience: "https://wavelop.eu.auth0.com/api/v2/"
  },
  workspace: {
    defaultWorkingHour: 8
  }
};
