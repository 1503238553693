import React from "react";
import { ButtonStyled, IconLeft, IconRight } from "./Button.style";

interface IProps {
  ImageLeft?: React.FunctionComponent;
  ImageRight?: React.FunctionComponent;
  btn?: "primary" | "secondary" | "disabled" | "reset";
  className?: string;
  name: string;
  onClick?: (event: any) => void;
  text: string;
  type: "button" | "submit" | "reset";
}

const Button: React.FC<any> = ({
  ImageLeft,
  ImageRight,
  btn,
  className,
  name,
  onClick,
  text,
  type,
  ...props
}: IProps) => {
  const disabled = btn === "disabled";
  const attributes = {
    className,
    btn,
    name,
    type,
    onClick
  };

  if (disabled) {
    attributes["disabled"] = disabled;
    attributes["tabIndex"] = -1;
  }

  return (
    <ButtonStyled {...props} {...attributes}>
      {ImageLeft ? (
        <IconLeft>
          <ImageLeft />
        </IconLeft>
      ) : (
        ""
      )}
      {text}
      {ImageRight ? (
        <IconRight>
          <ImageRight />
        </IconRight>
      ) : (
        ""
      )}
    </ButtonStyled>
  );
};

export default Button;
