import React from "react";
import { AlertStyled, AlertLi, AlertUl } from "./Alert.style";
import { AlertIcon, SuccessIcon } from "Assets";
import { formatErrorObject } from "Utils";

interface IProps {
  type: "success" | "fail";
  messageType: "short" | "extended";
  errors?: any;
  text: string;
}

const Alert: React.FC<IProps> = ({
  type,
  text,
  messageType,
  errors
}: IProps) => {
  let arr: Array<any> = [];

  const displayList = (): (JSX.Element | null)[] => {
    arr = formatErrorObject(errors, arr);
    return arr.map((value: string, i: number) =>
      value ? <AlertLi key={i}>{value}</AlertLi> : null
    );
  };

  return (
    <AlertStyled>
      {type === "fail" && <img src={AlertIcon} alt="alert icon" />}
      {type === "success" && <img src={SuccessIcon} alt="success icon" />}
      <span>
        {text}
        {messageType === "extended" && <AlertUl>{displayList()}</AlertUl>}
      </span>
    </AlertStyled>
  );
};

export default Alert;
