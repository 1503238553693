import styled from "styled-components";

export const CalendarDayWrapper = styled.button`
  height: ${({ theme }): string => theme.spacing.unit(2)};
  width: ${({ theme }): string => theme.spacing.unit(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }): string => theme.borderRadiusTables};
  border: 2px solid transparent;
  margin: ${({ theme }): string => theme.spacing.unit(0.25)} 0;
  position: relative;
  background-color: transparent;
  outline: 0;
  padding: 0;
  cursor: ${({ isSelected }): string => {
    if (!isSelected) return "pointer";
    return "";
  }};
  color: ${({ theme, isToday, isSelected, dayOff, outOfMonth }): string => {
    if (isSelected) return theme.colors.surface;
    if (isToday) return theme.colors.surface;
    if (dayOff) return theme.colors.headerColor;
    if (outOfMonth) return theme.colors.headerColor;
    return theme.colors.onSurface;
  }};

  &:after {
    content: " ";
    position: absolute;
    height: ${({ theme }): string => theme.spacing.unit(0.5)};
    width: ${({ theme }): string => theme.spacing.unit(0.5)};
    background-color: ${({ theme, dayStatus }): string => {
    if (dayStatus === "ok") return theme.colors.status02;
    if (dayStatus === "ko") return theme.colors.status01;
    if (dayStatus === "future-ok") return theme.colors.status04;
    if (dayStatus === "future-ko") return theme.colors.status03;
    return "";
  }};

    bottom: -${({ theme }): string => theme.spacing.unit(0.45)};
    border-radius: 50%;
    border: 1px solid
      ${({ theme, dayStatus }): string => {
    if (
      dayStatus === "ok" ||
          dayStatus === "ko" ||
          dayStatus === "future-ok" ||
          dayStatus === "future-ko"
    )
      return theme.colors.surface;
    return "transparent";
  }};

    ${({ theme, dayOff, dayOffBottom }): string => {
    return dayOff && !dayOffBottom
      ? `
    width: 100%;
    background-color: ${theme.colors.tableBackground};
    border-radius: 0;
    bottom: -10px;
    border: 0;
    border-right: 1px solid ${theme.colors.tableBackground};
    border-left: 1px solid ${theme.colors.tableBackground};
    margin: 0;
    `
      : "";
  }}
  } /* End of after */

  &:focus,
  &:hover {
    border: 2px solid ${({ theme }): string => theme.colors.tableHover};
  }

  background-color: ${({ theme, isToday, isSelected, dayOff }): string => {
    if (dayOff && !isSelected && !isToday) return theme.colors.tableBackground;
    if (isSelected) return theme.colors.tableHover;
    if (isToday) return theme.colors.primaryLigth;
    return "transparent";
  }};

  ${({ dayOff }): string => {
    return dayOff
      ? `
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  margin-right: 1px;
  `
      : "";
  }}

  ${({ dayOff, dayOffBottom, dayOffTop }): string => {
    return dayOff && dayOffBottom
      ? `
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  `
      : dayOff && dayOffTop
        ? `
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  `
        : dayOff
          ? `
  border-radius: 0;
  `
          : "";
  }}

&:hover:after {
    ${({ dayOff, dayOffBottom }): string => {
    return dayOff && !dayOffBottom
      ? `
  width: calc(100% + 2px);
  `
      : "";
  }}
  }
`;
