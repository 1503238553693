import { unit } from "./units";

/* 
xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px 

value         |0px     600px    960px    1280px   1920px
key           |xs      sm       md       lg       xl
screen width  |--------|--------|--------|--------|-------->
range         |   xs   |   sm   |   md   |   lg   |   xl

*/

// Variables

export const keys: Array<string> = ["xs", "sm", "md", "lg", "xl"];
export const values: { [key: string]: number } = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
};
export const step = 5;

// Functions

export const up = (key: string): string => {
  const value = typeof values[key] === "number" ? values[key] : key;
  return `@media (min-width:${value}${unit})`;
};

export const down = (key: string): string => {
  const endIndex = keys.indexOf(key);
  const upperbound = values[keys[endIndex]];

  if (endIndex === keys.length) {
    // xl down applies to all sizes
    return up("xs");
  }

  const value = typeof upperbound === "number" && endIndex > 0 ? upperbound : 0;
  return `@media (max-width:${value - step / 100}${unit})`;
};

export const between = (start: string, end: string): string => {
  const endIndex = keys.indexOf(end);

  if (endIndex === keys.length - 1) {
    return up(start);
  }

  return (
    `@media (min-width:${
      typeof values[start] === "number" ? values[start] : start
    }${unit}) and ` +
    `(max-width:${(endIndex !== -1 &&
    typeof values[keys[endIndex + 1]] === "number"
      ? values[keys[endIndex + 1]]
      : 0) -
      step / 100}${unit})`
  );
};

export const only = (key: string): string => {
  return between(key, key);
};
