import styled from "styled-components";

export const NavbarStyled = styled.div`
  background-color: ${({ theme }): string => theme.colors.navbarBackgorund};
  flex: 1;
  width: 248px;
  max-width: 248px;
  padding-right: ${({ theme }): string => theme.borderRadiusContent};
  overflow: auto;
`;

export const NavbarContent = styled.div`
  text-align: center;
  position: relative;
  height: calc(100% - ${({ theme }): string => theme.spacing.unit(2)});
  margin-top: ${({ theme }): string => theme.spacing.unit(2)};
`;

export const NavbarFooter = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  @media (max-height:768px) {
    position: relative;
`;

export const Title = styled.h2`
  margin: 0 ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(2)}
    ${({ theme }): string => theme.spacing.unit(0.5)};
  color: ${({ theme }): string => theme.colors.navbarText};
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
  font-weight: ${({ theme }): string => theme.fonts.default.bold.fontWeight};
  font-size: ${({ theme }): string => theme.fontSize.unit(3)};
`;

export const SubTitle = styled.h3`
  color: ${({ theme }): string => theme.colors.navbarText};
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
`;

export const Links = styled.div`
  margin: 2em 0;
`;

export const Ul = styled.ul`
  padding: 0;
`;

export const ButtonStyled = styled.button`
  text-align: center;

  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};

  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};

  margin: 0 ${({ theme }): string => theme.spacing.unit(0.25)};

  background-color: ${({ btn, theme }): string => {
    if (btn === "primary") return theme.colors.buttonFilterBackgroundActive;
    if (btn === "secondary") return theme.colors.surface;
    if (btn === "reset") return theme.colors.resetButtonBackground;
    if (btn === "disabled") return theme.colors.surface;
    return theme.colors.buttonFilterBackground;
  }};

  width: calc(100% - ${({ theme }): string => theme.spacing.unit(4)});

  transition: 0.3s;
  cursor: pointer;
  border: none;
  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(0.75)};
  border-radius: ${({ theme }): string => theme.borderRadiusTables};

  &:hover {
    background-color: ${({ theme }): string => {
    return theme.colors.darken(theme.colors.buttonFilterBackground);
  }};
`;

export const IconRight = styled.span`
  padding-left: ${({ theme }): string => theme.spacing.unit(0.5)};
  vertical-align: middle;
`;
