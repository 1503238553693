import styled from "styled-components";
import { Button } from "Components";

export const ButtonStyled = styled(Button)`
  flex: 1;
  margin: 0;
`;

export const DateSelectorStyled = styled.div`
  background-color: ${({ theme }): string => theme.colors.clockBackground};
`;
