import React from "react";
import { CustomRoute } from "Components";
import { useUserState } from "Services";

interface IProps {
  component: any;
  exact?: boolean;
  restricted?: any;
  name: string;
  rolesAllowed: string[];
  token?: string;
  path: string | string[];
}

const RoleBasedRoute: React.FC<IProps> = ({
  component,
  exact,
  name,
  token,
  rolesAllowed,
  path,
  ...props
}: any) => {
  const { user } = useUserState().state;
  const userAuth = user?.workspaces.find(
    item => item.id === user?.currentWorkspace
  )?.role;
  return rolesAllowed.includes(userAuth) ? (
    <CustomRoute
      exact={exact}
      path={path}
      name={name}
      token={token}
      component={component}
    />
  ) : (
    <></>
  );
};

export default RoleBasedRoute;
