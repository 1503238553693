import styled from "styled-components";

export const Label = styled.label`
  display: flex;
  position: relative;

  font-size: ${({ theme }): string => theme.fontSize.unit(2)};

  color: ${({ theme }): string => theme.colors.dropdownTextColor};

  margin: 0;

  font-family: ${({ theme }): string => theme.fonts.default.regular.fontFamily};

  font-size: ${({ theme }): string => theme.fontSize.unit(2)};

  border-radius: ${({ theme }): string =>
    theme.borderRadiusQuickInsertionSelect};

  background-color: ${({ theme, readOnly, disabled }): string => {
    let result = theme.colors.surface;
    result = readOnly ? theme.colors.readOnly : result;
    result = disabled ? theme.colors.disabled : result;
    return result;
  }};

  padding: ${({ theme, styleType }): string =>
    styleType !== "icon"
      ? `${theme.spacing.unit(0.35)} ${theme.spacing.unit(1)}`
      : `${theme.spacing.unit(0.35)} ${theme.spacing.unit(
        1
      )} ${theme.spacing.unit(0.35)} ${theme.spacing.unit(3.5)}`};

  margin: ${({ theme }): string => theme.spacing.unit(0.25)}
    ${({ theme }): string => theme.spacing.unit(0.25)};
  border: ${({ theme }): string =>
    "2px solid " + theme.colors.inputIconBackground + "29"};
  gap: 2rem;
  justify-content: flex-end;
`;

export const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;
  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;

export const SwitchSectionStyled = styled.section`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const SwitchLabelStyled = styled.label`
  font-size: ${({ theme }): string => theme.fontSize.unit(1.5)};
  margin: 0px 4px;
  padding: 4px 2px 0px;
  color: gray;
`;

export const SwitchText = styled.span`
  align-self: center;
  margin-left: 40px;
`;

export const SpanInput = styled.span`
  ${({ type, theme }): string =>
    type === "icon"
      ? `
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  border-radius: ${theme.borderRadiusQuickInsertionSelectIcon};
  background-color: ${theme.colors.inputIconBackground}29;
  height: 100%;
  width: ${theme.spacing.unit(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: ${theme.spacing.unit(1)};
    max-height: ${theme.spacing.unit(1)};
  }
`
      : ""}
`;
