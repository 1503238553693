import React, { useCallback } from "react";
import { useGuard } from "src/hooks/useGuard";

const Guard = ({ children, name }) => {
  const { isLoading, canISee } = useGuard(name);

  const heavyReRender = useCallback(() => {
    const Component = children[0] || children;
    let Error = <></>;

    if (children && children[1]) {
      Error = children[1];
    }
    if (!children || (children && children.length > 2)) {
      return <span>Wrong numeber of children. Check ADR!</span>;
    }
    return (
      <React.Fragment>
        {!isLoading &&
          (canISee ? (
            <Component.type {...Component.props}></Component.type>
          ) : Error ? (
            <Error.type {...Error.props}></Error.type>
          ) : (
            <></>
          ))}
      </React.Fragment>
    );
  }, [canISee, children, isLoading]);

  return heavyReRender();
};

export default Guard;
