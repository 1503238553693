import styled from "styled-components";

export const PageTitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: fadeIn ease 0.3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 1rem;
  }
`;

export const PageTitleH1Span = styled.span``;

export const PageTitleH1 = styled.h1`
  font-size: ${({ theme }): string => theme.fontSize.unit(7)};
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
  font-weight: ${({ theme }): string => theme.fonts.default.bold.fontWeight};
  margin: 0;
  @media (max-width: 768px) {
    font-size: ${({ theme }): string => theme.fontSize.unit(3)};
  }
`;
