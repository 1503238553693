import { ActivityForm, Alert, DateSelector } from "Components";
import { useTranslate } from "i18n";
import React, { useState } from "react";
import { useLoading, useLoadingState } from "Services";
import { fromTimeToHours } from "Utils";
import { ActivityQuickInsert } from "./QuickInsertion.style";

export interface IProps {
  type: string;
  data?: any;
  handleDynamicSuccess: () => Promise<any>;
}

const QuickInsertion: React.FC<IProps> = ({
  type,
  data,
  handleDynamicSuccess
}: IProps) => {
  const [results, setResults] = useState({
    numberOfErrors: 0,
    errors: {},
    errorType: ""
  });
  const { t } = useTranslate();
  const [date, setDate] = useState(new Date());
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const dispatchLoadingState = useLoadingState().dispatch;
  const { endLoading } = useLoading();

  const handleDateChange = (newDate: any): void => {
    setDate(newDate);
  };

  const handleHourChange = (event: any): void => {
    const value = parseInt(event.target.value);
    if (isNaN(value)) setHours(0);
    else {
      if (value < 24 && value >= 0) setHours(value);
      if (value >= 24) setHours(hours);
    }
  };

  const handleMinutesChange = (event: any): void => {
    setMinutes(event.target.value);
  };

  const handleDynamicResults = (result: any): void => {
    setResults({ ...result });
    result.numberOfErrors === 0 && handleDynamicSuccess();
    result.numberOfErrors !== 0 && endLoading(dispatchLoadingState);
  };

  const resetDatePicker = (): void => {
    setDate(new Date());
    setHours(0);
    setMinutes(0);
  };

  const showAlert = (): JSX.Element => {
    if (results.numberOfErrors > 0 && results.errorType === "validation") {
      return (
        <Alert
          text={t("Wavelop.error.message.validation")}
          errors={results.errors}
          type="fail"
          messageType="extended"
        />
      );
    }
    if (results.numberOfErrors > 0 && results.errorType === "server") {
      return (
        <Alert
          text={t("Wavelop.error.message.server") + ": " + results.errors}
          type="fail"
          messageType="short"
        />
      );
    }
    return <React.Fragment />;
  };

  const switchQuickInsertion = (type: string, data: any): JSX.Element => {
    switch (type) {
      case "/activities":
        return (
          <React.Fragment>
            {showAlert()}
            <ActivityQuickInsert>
              <DateSelector
                date={date}
                hours={hours}
                minutes={minutes}
                handleDateChange={handleDateChange}
                handleHourChange={handleHourChange}
                handleMinutesChange={handleMinutesChange}
              />
              <ActivityForm
                data={data}
                handleDynamicResults={handleDynamicResults}
                resetDatePicker={resetDatePicker}
                date={date}
                duration={fromTimeToHours(hours, minutes)}
              />
            </ActivityQuickInsert>
          </React.Fragment>
        );
      default:
        return <React.Fragment />;
    }
  };

  return switchQuickInsertion(type, data);
};

export default QuickInsertion;
