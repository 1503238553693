import { Chip } from "@material-ui/core";
import { DownArrowIcon, RightArrowIcon } from "Assets";
import { useTranslate } from "i18n";
import React, { Fragment, useState } from "react";
import { checkDateIsValidAndFormat, fromHoursToTimeString } from "Utils";
import { RowStyled, TableContentGroupedTwoStyled } from "./ReportRow.style";
import ReportRowGroupedThree from "./ReportRowGroupedThree";

const ReportRowGroupedTwo: React.FC<any> = props => {
  const { t } = useTranslate();
  const [showGroupThree, setShowGroupThree] = useState(false);

  const groupThreeRows = (item, index, type) => {
    return (
      <ReportRowGroupedThree
        data={item}
        key={`${type}${props.generalIndex}${index}`}
      />
    );
  };

  return (
    <Fragment>
      <TableContentGroupedTwoStyled
        onClick={() => setShowGroupThree(!showGroupThree)}
      >
        <RowStyled type={"grouped"} style={{ marginLeft: "12px" }}>
          {props.data.groupThree[0]._id.name !== "undefined" &&
            (showGroupThree ? (
              <DownArrowIcon style={{ verticalAlign: "middle" }} />
            ) : (
              <RightArrowIcon style={{ verticalAlign: "middle" }} />
            ))}
          {props.data.groupThree[0]._id.name !== "undefined" && (
            <Chip
              style={{ marginRight: "4px" }}
              size="small"
              label={props.data.groupThree.length}
            />
          )}
          {checkDateIsValidAndFormat(
            props.data._id.name,
            t("Wavelop.application.locale")
          )}
        </RowStyled>
        <RowStyled type={"grouped"}>{`${fromHoursToTimeString(
          props.data.hours
        )}`}</RowStyled>
      </TableContentGroupedTwoStyled>
      {showGroupThree &&
        props.data.groupThree[0]._id.name !== "undefined" &&
        props.data.groupThree.map((item, index) => {
          return groupThreeRows(item, index, "groupThree");
        })}
    </Fragment>
  );
};

export default ReportRowGroupedTwo;
