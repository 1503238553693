import React, { createContext, useContext, useReducer } from "react";

import {
  ActionType,
  LanguageInterface,
  TranslateContextInterface,
  TranslateProviderPropsInterface,
  TranslateStateInterface
} from "./Models";

import {
  getCurrentLanguage,
  getFallbackLanguage,
  getFlags,
  getLanguages,
  getTranslations,
  setCurrentLanguage,
  setFallbackLanguage,
  setLanguages,
  setTranslations,
  t
} from "./Translate";

import { init, initialState } from "./Utils";

init();

// Contexts
const TranslateContext = createContext<TranslateContextInterface>({
  getCurrentLanguage: getCurrentLanguage,
  setCurrentLanguage: setCurrentLanguage,
  getFallbackLanguage: getFallbackLanguage,
  setFallbackLanguage: setFallbackLanguage,
  getLanguages: getLanguages,
  setLanguages: setLanguages,
  getFlags: getFlags,
  getTranslations: getTranslations,
  setTranslations: setTranslations,
  t: t
});

const TranslateStateContext = createContext<TranslateStateInterface>({
  state: initialState,
  dispatch: () => {}
});

// Reducers
function translateReducer(
  state: LanguageInterface = initialState,
  action: ActionType
): any {
  const { type, language } = action;
  switch (type) {
    case "CHANGE_LANGUAGE": {
      setCurrentLanguage(language);
      return { ...state, language: language };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

export const TranslateProvider: React.FC<TranslateProviderPropsInterface> = (
  props: Partial<TranslateProviderPropsInterface>
) => {
  const value = {
    getCurrentLanguage: getCurrentLanguage,
    setCurrentLanguage: setCurrentLanguage,
    getFallbackLanguage: getFallbackLanguage,
    setFallbackLanguage: setFallbackLanguage,
    getLanguages: getLanguages,
    setLanguages: setLanguages,
    getFlags: getFlags,
    getTranslations: getTranslations,
    setTranslations: setTranslations,
    t: t
  };
  const [state, dispatch] = useReducer(translateReducer, initialState);
  const values = { state, dispatch };

  return (
    <TranslateContext.Provider value={value}>
      <TranslateStateContext.Provider value={values}>
        {props.children}
      </TranslateStateContext.Provider>
    </TranslateContext.Provider>
  );
};

export const useTranslate = (): TranslateContextInterface => {
  const context = useContext(TranslateContext);
  if (context === undefined) {
    throw new Error("useTranslate must be used within a TranslateProvider");
  }
  return context;
};

export const useTranslateState = (): TranslateStateInterface => {
  const context = useContext(TranslateStateContext);
  if (context === undefined) {
    throw new Error(
      "useTranslateState must be used within a TranslateProvider"
    );
  }
  return context;
};
