export const formatClientRequest = (data: any): any => {
  return {
    ...data,
    description: data.description || ""
  };
};

export const formatStatus = (status: string): string => {
  if (status === "active") return "Wavelop.application.status.active";
  if (status === "inactive") return "Wavelop.application.status.inactive";
  return "Wavelop.application.status.deleted";
};
