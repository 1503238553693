import React from "react";
import { CalendarHeader, CalendarHeaderDay } from "./CalendarDaysHeader.style";
import { useTranslate } from "i18n";

const CalendarDaysHeader: React.FC<any> = () => {
  const { t } = useTranslate();

  const dayLabels: string[] = [
    t("Wavelop.application.days.monday"),
    t("Wavelop.application.days.tuesday"),
    t("Wavelop.application.days.wednesday"),
    t("Wavelop.application.days.thursday"),
    t("Wavelop.application.days.friday"),
    t("Wavelop.application.days.saturday"),
    t("Wavelop.application.days.sunday")
  ];

  return (
    <CalendarHeader>
      {dayLabels.map((day, index) => {
        return (
          <CalendarHeaderDay key={`${day}_${index}`}>
            {day.slice(0, 3)}
          </CalendarHeaderDay>
        );
      })}
    </CalendarHeader>
  );
};

export default CalendarDaysHeader;
