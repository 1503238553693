import styled from "styled-components";

export const CalendarWrapper = styled.section`
  margin: ${({ theme }): string => theme.spacing.unit(1.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
`;

export const CalendarTitle = styled.h2`
  margin: 0 0 ${({ theme }): string => theme.spacing.unit(1)} 0;
  font-weight: ${({ theme }): string => theme.fonts.default.medium.fontWeight};
`;

export const CalendarBody = styled.div`
  background-color: ${({ theme }): string => theme.colors.surface};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }): string => theme.borderRadiusFilter};
  padding: ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(2)};
  min-width: 190px;

  ${({ theme }): string => theme.breakpoints.down("lg")} {
    padding: ${({ theme }): string => theme.spacing.unit(0.75)};
  }

  ${({ theme }): string => theme.breakpoints.down("md")} {
    padding: ${({ theme }): string => theme.spacing.unit(0.25)};
  }
`;

export const CalendarLegend = styled.div``;
