import styled from "styled-components";

export const ReportHeadStyled = styled.div`
  padding: 0 ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(0.25)}
    ${({ theme }): string => theme.spacing.unit(2)};
  font-weight: bold;
  display: flex;
  font-size: ${({ theme }): string => theme.fontSize.unit(1.5)};
`;

export const ReportHeadTitle = styled.span`
  width: ${({ type }): string => (type === "grouped" ? "90%" : "22.5%")};
  &:last-child {
    width: 10%;
  }
`;
