import styled from "styled-components";

export const ClockStyled = styled.span`
  padding: ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(0.5)};
  background-color: ${({ theme }): string => theme.colors.clockBackground};
  border-radius: 15px;
  color: ${({ theme }): string => theme.colors.clockColor};
  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};
`;

export const HourStyled = styled.span`
  img {
    vertical-align: middle;
    padding: 0 ${({ theme }): string => theme.spacing.unit(0.5)};
  }
`;

export const HourText = styled.span`
  vertical-align: middle;
`;

export const DateStyled = styled.span`
  float: right;
  padding: 0 ${({ theme }): string => theme.spacing.unit(0.5)};
`;

export const DateText = styled.span`
  vertical-align: middle;
`;
