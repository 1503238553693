export const splitTitle = (title: string): string => {
  const titleSplitted: string[] = title.split("-", 1);
  titleSplitted[0] = titleSplitted[0].trim();
  return titleSplitted[0];
};

export const splitEditTitle = (title: string): string => {
  const titleSplitted: string[] = title.split(" ");
  if (titleSplitted.length > 1) {
    titleSplitted[1] = titleSplitted[1].trim();
    return titleSplitted[1].charAt(0).toUpperCase() + titleSplitted[1].slice(1);
  }
  return titleSplitted[0];
};

export const backButtonPath = (path: string): string => {
  let pathToNavigate = "";
  if (path.includes("activities/edit")) {
    pathToNavigate = "/activities";
  }
  if (path.includes("clients/edit")) {
    pathToNavigate = "/clients";
  }
  if (path.includes("projects/edit")) {
    pathToNavigate = "/projects";
  }
  if (path.includes("groups/edit")) {
    pathToNavigate = "/groups";
  }
  if (path.includes("clients/new")) {
    pathToNavigate = "/clients";
  }
  if (path.includes("projects/new")) {
    pathToNavigate = "/projects";
  }
  if (path.includes("team/edit")) {
    pathToNavigate = "/team";
  }
  return pathToNavigate;
};

export const newButtonPath = (path: string): string => {
  let pathToNavigate = "";
  if (path.includes("clients")) {
    pathToNavigate = "/clients/new";
  }
  if (path.includes("projects")) {
    pathToNavigate = "/projects/new";
  }
  if (path.includes("workspaces")) {
    pathToNavigate = "/workspace/new";
  }
  return pathToNavigate;
};

export const switchTabButtonPath = (path: string): string => {
  let pathToNavigate = "";
  if (path.includes("team")) {
    pathToNavigate = "/groups";
  }
  if (path.includes("groups")) {
    pathToNavigate = "/team";
  }
  return pathToNavigate;
};
