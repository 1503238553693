import React, { useEffect, useState } from "react";
import {
  TableContentStyled,
  RowStyled,
  ButtonStyled
} from "./WorkspaceRow.style";
import { DeleteIcon, ModifyIcon } from "Assets";
import { useTranslate } from "i18n";
import { Link, useHistory } from "react-router-dom";
import "moment/locale/it";
import "moment/locale/en-gb";
import { formatStatus } from "Utils";
import { User, useUserState } from "Services";

export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
}

const WorkspaceRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  data,
  type
}: IProps) => {
  const { t } = useTranslate();

  const { state, dispatch: dispatchUser } = useUserState();
  const { token } = state;

  const history = useHistory();

  const onDeleteButton = (event): void => {
    onDelete(id);
    event.stopPropagation();
  };

  const changeWorkspace = async (workspaceId: string): Promise<any> => {
    try {
      const user = await User.updateCurrentWorkspace({ workspaceId }, token);
      dispatchUser({ type: "UPDATE_USER", user: user.item });
      history.push("activities");
    } catch (e) {
      console.log(e);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <TableContentStyled index={index} onClick={() => changeWorkspace(id)}>
      <RowStyled>{data.name}</RowStyled>
      <RowStyled>{data.code}</RowStyled>
      {windowWidth < 500 ? (
        <></>
      ) : (
        <>
          <RowStyled>{data.description}</RowStyled>
          <RowStyled>{t(formatStatus(data.status))}</RowStyled>
        </>
      )}

      <RowStyled>
        <Link
          to={`settings/${id}`}
          onClick={(event): void => event.stopPropagation()}
        >
          <ButtonStyled
            type="image"
            src={ModifyIcon}
            alt={t("Wavelop.application.table.altEdit")}
          />
        </Link>
        <ButtonStyled
          onClick={onDeleteButton}
          type="image"
          src={DeleteIcon}
          alt={t("Wavelop.application.table.altDelete")}
        />
      </RowStyled>
    </TableContentStyled>
  );
};

export default WorkspaceRow;
