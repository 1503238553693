import { CrudType } from "Models";
import { Crud as crudService } from "Services";
import { config } from "Config";
import { Utils as utilsService } from "Utils";
import { IWorkspace } from "src/models/Services/IWorkspace";

const create = async (body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.workspace.create,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readOne = async (
  id: string,
  queryparams: any,
  token: any
): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.workspace.readOne, { id }),
      null,
      queryparams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readToken = async (
  id: string,
  queryparams: any,
  token: any
): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.workspace.readToken, {
        id
      }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readByUser = async (token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.workspace.readByUser,
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const read = async (queryparams: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.workspace.read,
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const update = async (id: string, body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      utilsService.apiPlaceholderReplace(config.api.workspace.update, { id }),
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const remove = async (id: string, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Delete,
      utilsService.apiPlaceholderReplace(config.api.workspace.remove, { id }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const invite = async (body: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.workspace.invite,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const WorkspaceService: IWorkspace = {
  create,
  readOne,
  readToken,
  readByUser,
  read,
  update,
  delete: remove,
  invite
};
