import styled from "styled-components";
import { DatePicker } from "Components";

export const DatePickerStyled = styled.div`
  color: ${({ theme }): string => theme.colors.dropdownTextColor};
  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};
  background-color: ${({ theme }): string => theme.colors.datePickerBackground};
  border-radius: ${({ theme }): string => theme.borderRadiusQuickInsertion};
  width: 30%;
  padding: ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(2)};
  flex: 0.25;
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }): string => theme.dateSelectorMaxWidth};
  // min-width: ${({ theme }): string => theme.dateSelectorMinWidth};
  @media (max-width: 768px) {
    flex-direction: row;
    width: -webkit-fill-available;
    width: -moz-available;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
  }
`;

export const DatePickerBlock = styled.div`
  padding: ${({ theme }): string => theme.spacing.unit(2)} 0
    ${({ theme }): string => theme.spacing.unit(1)} 0;
  flex: 1;
  &:nth-child(1) {
    border-bottom: 2px solid #000;
  }
`;

export const LabelDiv = styled.div`
  margin-bottom: ${({ theme }): string => theme.spacing.unit(0.25)};
`;

export const Label = styled.p`
  vertical-align: middle;
  display: inline;
`;

export const Input = styled.input`
  color: ${({ theme }): string => theme.colors.dropdownTextColor};
  text-align: right;
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
  font-weight: ${({ theme }): string => theme.fonts.default.bold.fontWeight};
  margin-left: -${({ theme }): string => theme.spacing.unit(0.5)};
  background-color: ${({ theme }): string =>
    theme.colors.datePickerHourInputBackground};
  border: none;
  outline: none;
  width: ${({ theme }): string => theme.spacing.unit(2.5)};
  font-weight: bold;
  font-size: ${({ theme }): string => theme.fontSize.unit(2.5)};
  transition: 0.5s;
  border-bottom: 2px solid
    ${({ theme }): string =>
    theme.colors.darken(theme.colors.datePickerHourInputBackground)};

  &:focus,
  &:hover {
    border-bottom: 2px solid
      ${({ theme }): string => theme.colors.darken(theme.colors.primary)};
  }

  &:focus {
    box-shadow: 0px 8px 4px -4px ${({
    theme,
    error,
    readOnly,
    disabled
  }): string => {
    let result = theme.colors.alpha(theme.colors.primary, 0.2);
    result = readOnly ? theme.colors.readOnly : result;
    result = disabled ? theme.colors.disabled : result;
    result =
          error?.length > 0 || error === true
            ? theme.colors.alpha(theme.colors.status01, 0.2)
            : result;
    return result;
  }};
  }
`;

export const DatePickerSelector = styled(DatePicker)`
  input {
    font-weight: bold;
    font-size: ${({ theme }): string => theme.fontSize.unit(2.5)};
  }
`;

export const CalendarIcon = styled.img`
  margin-right: ${({ theme }): string => theme.spacing.unit(0.25)};
  vertical-align: middle;
`;

export const ClockIcon = styled.img`
  margin-right: ${({ theme }): string => theme.spacing.unit(0.25)};
  vertical-align: middle;
`;

export const Hour = styled.h2`
  display: inline;
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
  margin: 0 ${({ theme }): string => theme.spacing.unit(0.5)};
`;

export const Minutes = styled.h2`
  display: inline;
  margin-left: ${({ theme }): string => theme.spacing.unit(0.25)};
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
`;

export const SelectContainerStyled = styled.section`
  display: inline-flex;
  flex: 1;
`;
