import moment from "moment";

export const getCurrentRange = (
  index: number,
  date: Date,
  locale: string
): Date[] => {
  console.log("sda");

  const result: Date[] = [];
  if (index === 0) {
    result[0] = new Date(
      moment(date)
        .startOf("day")
        .format("YYYY-MM-DDTHH:MM:SSZ")
    );
    result[1] = new Date(
      moment(date)
        .utc()
        .locale(locale)
        .endOf("day")
        .toDate()
    );
  }
  if (index === 1) {
    result[0] = new Date(
      moment(date)
        .utc()
        .locale(locale)
        .startOf("week")
        .format("YYYY-MM-DDTHH:MM:SSZ")
    );
    result[1] = new Date(
      moment(date)
        .utc()
        .locale(locale)
        .endOf("week")
        .format("YYYY-MM-DD")
    );
  }
  if (index === 2) {
    result[0] = new Date(
      moment(date)
        .utc()
        .locale(locale)
        .startOf("month")
        .format("YYYY-MM-DDTHH:MM:SSZ")
    );
    result[1] = new Date(
      moment(date)
        .utc()
        .locale(locale)
        .endOf("month")
        .format("YYYY-MM-DDTHH:MM")
    );
  }
  return result;
};

export const getContextView = (index: number): "day" | "week" | "month" => {
  if (index === 0) {
    return "day";
  }
  if (index === 1) {
    return "week";
  }
  if (index === 2) {
    return "month";
  }
  return "day";
};

export const getActualTimeComponentLabels = (
  view?: "day" | "week" | "month",
  from?: Date,
  to?: Date
): string[] => {
  const labels: string[] = [];
  if (view === "day") {
    labels[0] = moment(from)
      .subtract(1, "days")
      .format("D")
      .toString();
    labels[1] = moment(from)
      .format("D MMMM YYYY")
      .toString();
    labels[2] = moment(from)
      .add(1, "days")
      .format("D")
      .toString();
  }
  if (view === "week") {
    labels[0] =
      moment(from)
        .utc()
        .subtract(1, "weeks")
        .startOf("week")
        .format("D")
        .toString() +
      "-" +
      moment(to)
        .utc()
        .subtract(1, "weeks")
        .endOf("week")
        .format("D")
        .toString();
    labels[1] =
      moment(from)
        .utc()
        .startOf("week")
        .format("D")
        .toString() +
      "-" +
      moment(to)
        .utc()
        .endOf("week")
        .format("D")
        .toString() +
      " " +
      moment(from)
        .format("MMMM")
        .toString();
    labels[2] =
      moment(from)
        .utc()
        .add(1, "weeks")
        .startOf("week")
        .format("D")
        .toString() +
      "-" +
      moment(to)
        .utc()
        .add(1, "weeks")
        .endOf("week")
        .format("D")
        .toString();
  }
  if (view === "month") {
    labels[0] = moment(from)
      .subtract(1, "months")
      .format("MMM")
      .toString();
    labels[1] = moment(from)
      .format("MMMM YYYY")
      .toString();
    labels[2] = moment(from)
      .add(1, "months")
      .format("MMM")
      .toString();
  }
  return labels;
};

export const setActualTime = (
  button: string,
  view?: "day" | "week" | "month",
  from?: Date,
  to?: Date
): Date[] => {
  const fromTo: Date[] = [];
  if (view === "day" && button === "specific") {
    fromTo[0] = new Date(moment(from).format("YYYY-MM-DDTHH:MM:SSZ"));
    fromTo[1] = new Date(
      moment(from)
        .endOf("day")
        .format("YYYY-MM-DD")
    );
  }
  if (view === "day" && button === "prev") {
    fromTo[0] = moment(from)
      .subtract(1, "days")
      .toDate();
    fromTo[1] = moment(to)
      .subtract(1, "days")
      .toDate();
  }
  if (view === "day" && button === "next") {
    fromTo[0] = moment(from)
      .add(1, "days")
      .toDate();
    fromTo[1] = moment(to)
      .add(1, "days")
      .toDate();
  }
  if (view === "week" && button === "prev") {
    fromTo[0] = moment(from)
      .subtract(1, "weeks")
      .toDate();
    fromTo[1] = moment(to)
      .subtract(1, "weeks")
      .toDate();
  }
  if (view === "week" && button === "next") {
    fromTo[0] = moment(from)
      .add(1, "weeks")
      .toDate();
    fromTo[1] = moment(to)
      .add(1, "weeks")
      .toDate();
  }
  if (view === "month" && button === "prev") {
    fromTo[0] = moment(from)
      .subtract(1, "months")
      .toDate();
    fromTo[1] = moment(to)
      .subtract(1, "months")
      .toDate();
  }
  if (view === "month" && button === "next") {
    fromTo[0] = moment(from)
      .add(1, "months")
      .toDate();
    fromTo[1] = moment(to)
      .add(1, "months")
      .toDate();
  }

  return fromTo;
};
