import React from "react";
import { FilterStyled } from "./Filter.style";
import DateRangeSelector from "./DateRangeSelector/DateRangeSelector";
// import LayoutSelector from "./LayoutSelector/LayoutSelector";
import ActualTimeSelector from "./ActualTimeSelector/ActualTimeSelector";
import SortingSelector from "./SortingSelector/SortingSelector";

export interface IProps {
  type: string;
}

const Filter: React.FC<IProps> = ({ type }: IProps) => {
  const switchFilter = (type: string): JSX.Element => {
    switch (type) {
      case "/activities":
        return (
          <FilterStyled>
            <DateRangeSelector />
            <ActualTimeSelector />
            {/* <LayoutSelector /> */}
            <SortingSelector />
          </FilterStyled>
        );
      default:
        return <React.Fragment />;
    }
  };

  return navigator.onLine ? switchFilter(type) : <React.Fragment />;
};

export default Filter;
