import { useTranslate } from "i18n";
import React from "react";
import { tableHeaderTitles } from "Utils";
import {
  TableStyled,
  UlTableContent
} from "../TableAndTitle/Table/Table.style";
import { ReportHeadStyled, ReportHeadTitle } from "./ReportTable.style";
import ReportRow from "./ReportTableRows/ReportRow";
import ReportRowGrouped from "./ReportTableRows/ReportRowGrouped";

const ReportTable: React.FC<any> = ({ data }) => {
  const { t } = useTranslate();
  const tableHeaders = (data): JSX.Element[] => {
    if (!data[0]._id) {
      return tableHeaderTitles("reports").map((item, index) => (
        <ReportHeadTitle key={index} type={"clients"}>
          {t(item)}
        </ReportHeadTitle>
      ));
    } else {
      return [
        <ReportHeadTitle key={0} type={"grouped"}>
          {t("Wavelop.application.reportsTable.name")}
        </ReportHeadTitle>,
        <ReportHeadTitle key={1} type={"grouped"}>
          {t("Wavelop.application.reportsTable.hours")}
        </ReportHeadTitle>
      ];
    }
  };

  const tableContent = (data): JSX.Element[] | null => {
    if (!data[0]._id) {
      return data.map((item, index) => {
        const data = {
          id: item.id,
          user: item.user.fullName,
          task: item.task,
          client: item.project.client.name,
          project: item.project.name,
          time: item.work,
          day: item.day
        };
        return <ReportRow key={index} data={data} />;
      });
    } else {
      return data.map((item, index) => {
        const data = {
          name: item._id.name,
          time: item.hours,
          restOfData: item.groupTwo
        };
        return (
          <ReportRowGrouped
            key={"groupOne" + index}
            data={data}
            generalIndex={index}
          />
        );
      });
    }
  };

  return (
    <TableStyled>
      <ReportHeadStyled>{tableHeaders(data)}</ReportHeadStyled>
      <UlTableContent>{tableContent(data)}</UlTableContent>
    </TableStyled>
  );
};

export default ReportTable;
