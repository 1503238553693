import styled from "styled-components";

export const ActivitiesStyled = styled.div`
  text-align: center;
  background-color: ${({ theme }): string => theme.colors.accent};
`;

export const EmptyActivities = styled.div`
  text-align: center;
  padding-top: ${({ theme }): string => theme.spacing.unit(6)};
  color: ${({ theme }): string => theme.colors.noActivityTitle};
`;

export const SwitchColorContainer = styled.div`
  padding-top: ${({ theme }): string => theme.spacing.unit(3)};
`;
