import React, { useMemo, memo } from "react";
import { deepEqual } from "Utils";

import { CalendarWeekWrapper } from "./CalendarWeek.style";
import { CalendarDay } from "../index";

interface IProps {
  days: {
    day: string;
    dayNumber: number;
  }[];
  daysStatus: string[];
  selectedDay: Date;
  daysOff: number[];
  first: boolean;
  last: boolean;
  monthSelected: boolean[];
}

const CalendarWeek: React.FC<any> = ({
  days,
  daysStatus,
  selectedDay,
  daysOff,
  first,
  last,
  monthSelected
}: IProps) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return useMemo(
    () => (
      <CalendarWeekWrapper>
        {days.map((day, index) => {
          const { day: dayString, dayNumber } = day;
          const dayNumberString = new Date(dayString);
          dayNumberString.setHours(0, 0, 0, 0);

          return (
            <CalendarDay
              key={`${day}_${index}`}
              day={dayNumberString}
              dayStatus={
                daysOff.indexOf(dayNumber) === -1 ? daysStatus[index] : ""
              }
              dayOff={daysOff.indexOf(dayNumber) !== -1}
              dayOffBottom={last}
              dayOffTop={first}
              isToday={dayNumberString.getTime() === today.getTime()}
              isSelected={dayNumberString.getTime() === selectedDay.getTime()}
              outOfMonth={monthSelected[index]}
            ></CalendarDay>
          );
        })}
      </CalendarWeekWrapper>
    ),
    [days, daysOff, daysStatus, first, last, monthSelected, selectedDay, today]
  );
};

const propsAreEqual = (prevProps: any, nextProps: any): boolean => {
  return deepEqual(prevProps, nextProps);
};

export default memo(CalendarWeek, propsAreEqual);
