import {
  ActivityRow,
  ClientRow,
  ProjectRow,
  WorkspaceRow,
  ProjectTeamRow,
  TaskRow,
  GroupRow,
  GroupMemberRow
} from "Components";
import { useTranslate } from "i18n";
import React, { useEffect, useState } from "react";
import { planningColors, tableHeaderTitles } from "Utils";
import TeamRow from "../TeamRow/TeamRow";
import {
  HeadStyled,
  HeadTitle,
  TableStyled,
  UlTableContent
} from "./Table.style";
import UserGroupRow from "../UserGroupRow/UserGroupRow";

export interface IProps {
  onDelete: (id: string) => void;
  onCopy: (id: string) => void;
  onSwitch?: (id: string) => void;
  day?: string;
  data: [any];
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
  wsRole?: string;
}

const Table: React.FC<IProps> = ({
  data,
  day,
  type,
  onDelete,
  onCopy,
  onSwitch,
  wsRole
}: IProps) => {
  const { t } = useTranslate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const headerTitles = (): JSX.Element[] => {
    if (windowWidth < 768) {
      switch (type) {
        case "activities":
          return tableHeaderTitles(type)
            ?.slice(0, 2)
            .concat(tableHeaderTitles(type)?.slice(-1))
            ?.map((item, index) => (
              <HeadTitle key={index} type={type}>
                {t(item)}
              </HeadTitle>
            ));
        case "projects":
          return tableHeaderTitles(type)
            ?.slice(0, 2)
            .concat(tableHeaderTitles(type)?.slice(-2))
            ?.map((item, index) => (
              <HeadTitle key={index} type={type}>
                {t(item)}
              </HeadTitle>
            ));

        case "clients":
          return tableHeaderTitles(type)
            ?.slice(0, 1)
            .concat(tableHeaderTitles(type)?.slice(-3))
            ?.map((item, index) => (
              <HeadTitle key={index} type={type}>
                {t(item)}
              </HeadTitle>
            ));
        case "workspaces": {
          if (windowWidth < 500)
            return tableHeaderTitles(type)
              ?.slice(0, 2)
              .concat(tableHeaderTitles(type)?.slice(-1))
              ?.map((item, index) => (
                <HeadTitle key={index} type={type}>
                  {t(item)}
                </HeadTitle>
              ));
          return tableHeaderTitles(type)?.map((item, index) => (
            <HeadTitle key={index} type={type}>
              {t(item)}
            </HeadTitle>
          ));
        }

        case "team":
          return tableHeaderTitles(type)
            ?.slice(1, 2)
            .concat(tableHeaderTitles(type)?.slice(3, 4))
            .concat(tableHeaderTitles(type)?.slice(-1))
            ?.map((item, index) => (
              <HeadTitle key={index} type={type}>
                {t(item)}
              </HeadTitle>
            ));
        case "projectTeam":
          return tableHeaderTitles(type)
            ?.slice(0, 1)
            .concat(tableHeaderTitles(type)?.slice(-2))
            ?.map((item, index) => (
              <HeadTitle key={index} type={type}>
                {t(item)}
              </HeadTitle>
            ));
        case "tasks":
          return tableHeaderTitles(type)?.map((item, index) => (
            <HeadTitle key={index} type={type}>
              {t(item)}
            </HeadTitle>
          ));
        case "groups":
          return tableHeaderTitles(type)
            ?.slice(0, 2)
            .concat(tableHeaderTitles(type)?.slice(-1))
            ?.map((item, index) => (
              <HeadTitle key={index} type={type}>
                {t(item)}
              </HeadTitle>
            ));
        case "groupMembers":
          return tableHeaderTitles(type)
            ?.slice(0, 2)
            .concat(tableHeaderTitles(type)?.slice(-1))
            ?.map((item, index) => (
              <HeadTitle key={index} type={type}>
                {t(item)}
              </HeadTitle>
            ));
        case "userGroups":
          return tableHeaderTitles(type)?.map((item, index) => (
            <HeadTitle key={index} type={type}>
              {t(item)}
            </HeadTitle>
          ));
      }
    } else
      return tableHeaderTitles(type)?.map((item, index) => (
        <HeadTitle key={index} type={type}>
          {t(item)}
        </HeadTitle>
      ));
  };

  const tableContent = (): JSX.Element[] | null => {
    return data?.map?.((item, index) => {
      const props = {
        type: type,
        index: index,
        key: item.id,
        id: item.id,
        onDelete: onDelete,
        onSwitch: onSwitch,
        data: data[index],
        wsRole: wsRole
      };
      if (type === "activities")
        return <ActivityRow {...props} onCopy={onCopy} />;
      if (type === "clients") return <ClientRow {...props} />;
      if (type === "projects") return <ProjectRow {...props} />;
      if (type === "workspaces") return <WorkspaceRow {...props} />;
      if (type === "team") return <TeamRow {...props} />;
      if (type === "projectTeam")
        return <ProjectTeamRow {...props} onSwitch={onSwitch} />;
      if (type === "tasks") return <TaskRow {...props} />;
      if (type === "groups") return <GroupRow {...props} />;
      if (type === "groupMembers")
        return <GroupMemberRow {...props} onSwitch={onSwitch} />;
      if (type === "userGroups") return <UserGroupRow {...props} />;
      return <React.Fragment />;
    });
  };

  return (
    <TableStyled>
      <HeadStyled>{headerTitles()}</HeadStyled>
      <UlTableContent
        type={type}
        marginColor={
          type === "activities" ? day && planningColors(day, data) : null
        }
      >
        {tableContent()}
      </UlTableContent>
    </TableStyled>
  );
};

export default Table;
