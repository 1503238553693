import styled from "styled-components";

export const CalendarWrapper = styled.section`
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  margin: ${({ theme }): string => theme.spacing.unit(1.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  padding: ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(2)};
  border-radius: ${({ theme }): string => theme.borderRadiusFilter};
`;

export const CalendarTitle = styled.h2`
  margin: 0 0 ${({ theme }): string => theme.spacing.unit(1)} 0;
`;

export const CalendarBody = styled.div``;

export const CalendarHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: 0;
  align-items: center;
  justify-content: space-between;
`;

export const CalendarHeaderDay = styled.div`
  font-weight: ${({ theme }): string => theme.fonts.default.medium.fontWeight};
  height: ${({ theme }): string => theme.spacing.unit(2)};
  width: ${({ theme }): string => theme.spacing.unit(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
`;

export const CalendarLegend = styled.div`
  /* WIP */
`;
