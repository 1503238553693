import styled from "styled-components";
import { Link } from "react-router-dom";

export const TableContentStyled = styled.li`
  border: 1px solid transparent;
  display: inline-block;
  list-style-type: none;
  display: flex;
  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};
  color: ${({ theme }): string => theme.colors.onSurface};
  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  border-radius: 0;
  &:nth-child(even) {
    background-color: ${({ theme }): string => theme.colors.evenRowTable};
    border: 1px solid ${({ theme }): string => theme.colors.evenRowTable};
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }): string => theme.colors.tableHoverOpacity};
    border: 1px solid ${({ theme }): string => theme.colors.tableHover};
  }

  &:nth-child(1) {
    border-radius: ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables} 0 0;
  }

  &:last-child {
    border-radius: 0 0 ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables};
  }

  &:nth-child(1):last-child:hover {
    border-radius: ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables};
  }
  &:a {
    text-decoration: none;
  }
`;

export const RowStyled = styled.span`
  padding-right: ${({ theme }): string => theme.spacing.unit(0.5)};
  word-break: break-all;
  max-width: ${({ theme }): string => theme.spacing.unit(10)};
  flex: 1;
  &:nth-child(3) {
    max-width: initial;
    flex: 1.2;
  }
  &:nth-child(6),
  &:nth-child(4) {
    flex: 0;
    min-width: ${({ theme }): string => theme.spacing.unit(5)};
  }
  &:last-child {
    flex: 0;
    min-width: ${({ theme }): string => theme.spacing.unit(4.4)};
    text-align: left;
  }
`;

export const LinkStyled = styled(Link)`
  color: ${({ theme }): string => theme.colors.primary};
`;

export const ButtonStyled = styled.input`
  margin: 0 ${({ theme }): string => theme.spacing.unit(0.5)} 0 0;
`;
