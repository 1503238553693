import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import { useTranslate } from "i18n";
import React from "react";
import { Filter } from "src/pages/Reports/Reports";
import { useStyles } from "./useStyles";

export interface FilterProps {
  type: any;
  filters: Filter;
  setCurrentPage: any;
  selectedFilters: string[];
  setSelectedFilters: any;
}

const ReportsFilter: React.FC<any> = (props: FilterProps) => {
  const { t } = useTranslate();
  const classes = useStyles();

  const isAllSelected =
    props.filters.filter.length > 0 &&
    props.selectedFilters.length === props.filters.filter.length;

  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      const filters: string[] = [];
      props.filters.filter.forEach(element => filters.push(element.id));
      props.setSelectedFilters(
        props.selectedFilters.length === props.filters.filter.length
          ? []
          : filters
      );
      return;
    }
    props.setSelectedFilters(value);
  };

  const filterTitle = () => {
    switch (props.type) {
      case "clients":
        return t("Wavelop.application.reportsFilters.clients");
      case "projects":
        return t("Wavelop.application.reportsFilters.projects");
      case "tasks":
        return t("Wavelop.application.reportsFilters.tasks");
      case "users":
        return t("Wavelop.application.reportsFilters.users");
      default:
        return "";
    }
  };

  const filterMenu = items => {
    switch (props.type) {
      case "users":
        return items.fullName ? items.fullName:items.name;
      case "tasks":
        return items.id;
      default:
        return items.name;
    }
  };

  return (
    <FormControl
      style={{ minWidth: "100px" }}
      variant={"outlined"}
      size="small"
    >
      <InputLabel>{filterTitle()}</InputLabel>
      <Select
        label={filterTitle()}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        multiple
        value={props.selectedFilters}
        onChange={event => handleChange(event)}
        input={
          <OutlinedInput
            style={{ borderRadius: "12px", backgroundColor: "#eff3fb" }}
            label={filterTitle()}
          />
        }
        renderValue={(selected: any) => (
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              alignContent: "center"
            }}
          >
            <Chip
              className={classes.chip}
              size="small"
              label={selected.length}
            />
          </Box>
        )}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ""
          }}
        >
          <ListItemIcon>
            <Checkbox
              color="primary"
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                props.selectedFilters.length > 0 &&
                props.selectedFilters.length < props.filters.filter.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary={t("Wavelop.application.reportsFilters.all")}
          />
        </MenuItem>
        {props.filters.filter?.map((items, index) => (
          <MenuItem key={index} value={items.id}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={props.selectedFilters.indexOf(items.id) > -1}
              />
            </ListItemIcon>
            <ListItemText primary={filterMenu(items)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReportsFilter;
