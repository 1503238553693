export const formatClientsOptions = (data: any): any => {
  return data && data.map(a => ({ value: a.id, name: a.name }));
};

export const formatUsersOptions = (data: any): any => {
  return data && data.map((a: any) => ({ value: a.id, name: a.fullName }));
};

export const formatGroupsOptions = (data: any): any => {
  return data && data.map((a: any) => ({ value:a.id, name: a.name }));
};

export const formatTeamOptions =(data: any,groupsData: any): any => {
  data = data.concat(groupsData);
  return data && data.map((a: any) => ({ value:a.id, name: a.optionGroup === "User"? a.fullName:a.name , optionGroup:a.optionGroup}));
};

export const formatProjectRequest = (data: any): any => {
  return {
    ...data,
    description: data.description || "",
    estimateRevenue: data.estimateRevenue || 0,
    estimateTime: data.estimateTime || 0
  };
};

export const formatUserRequest = (data: any): any => {
  return {
    user: data.user || ""
  };
};

export const formatTimeCell = (
  h: number,
  m: number,
  extTime: string
): string => {
  return `${h}h ${m > 0 ? `${m}m` : ""}  ${extTime ? `/ ${extTime}h` : ""}`;
};
