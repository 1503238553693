import { UserRole, Pages } from "../../models/Enums/Enums";

export const checkAuthorization = async (
  componentName: Pages,
  userRole: UserRole | undefined
): Promise<boolean> => {
  const grants = getGrants(componentName);
  const authorized = userRole ? grants[userRole] : false;

  return !!authorized;
};

export const getGrants = (componentName: Pages): { [key: string]: boolean } => {
  const grants: { [key: string]: boolean } = {
    [UserRole.TEAM]: false,
    [UserRole.ADMIN]: true
  };

  switch (componentName) {
    case Pages.NEW_PROJECT:
      grants[UserRole.ADMIN] = true;
      grants[UserRole.TEAM] = false;
      break;
    case Pages.ATTIVITA:
    case Pages.REPORTS:
    case Pages.PROGETTI:
      grants[UserRole.ADMIN] = true;
      grants[UserRole.TEAM] = true;
      break;
    case Pages.CLIENTI:
    case Pages.TEAM:
    case Pages.WORKSPACE_SETTING:
      grants[UserRole.ADMIN] = true;
      grants[UserRole.TEAM] = false;
      break;
  }

  return grants;
};
