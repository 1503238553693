import styled from "styled-components";
import { Button } from "Components";

export const SidebarStyled = styled.div`
  background-color: ${({ theme }): string => theme.colors.sidebarBackgorund};
  flex: 2;
  min-width: 200px;
  max-width: 418px;
  margin-left: -${({ theme }): string => theme.borderRadiusContent};
  padding-left: ${({ theme }): string => theme.borderRadiusContent};
  overflow: hidden;
`;

export const ButtonStyled = styled(Button)`
  margin: ${({ theme }): string => theme.spacing.unit(1.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  width: calc(100% - ${({ theme }): string => theme.spacing.unit(4)});
  padding: ${({ theme }): string => theme.spacing.unit(1.25)} 0;
  font-weight: ${({ theme }): string => theme.fonts.default.medium.fontWeight};
  font-size: ${({ theme }): string => theme.fontSize.unit(2.5)};
`;
