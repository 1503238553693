import { addDays } from "date-fns";
import { useTranslate } from "i18n";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ButtonStyled } from "../../Filter/DateRangeSelector/DateRangeSelector.style";
import {
  ButtonContainer,
  Main,
  Overlay
} from "../../ModalConfirm/ModalConfirm.style";
import "./quick.css";
import { InsertionModal } from "./QuickInsertionModal.style";

interface Dialog {
  selectedData: any;
  date: Date;
  closeModal: any;
  show: boolean;
  confirm: any;
}

const QuickInsertionModal: React.FC<Dialog> = ({
  selectedData,
  date,
  closeModal,
  show,
  confirm
}: Dialog) => {
  const [dates, setDates] = useState<any>([
    {
      startDate: date,
      endDate: date,
      key: "key1",
      autoFocus: true,
      color: "#3d91ff"
    }
  ]);
  const [currentKey, setCurrentKey] = useState(1);
  const { t } = useTranslate();
  const processDates = () => {
    const processedDates: Date[] = [];
    dates.forEach(range => {
      if (range.startDate === range.endDate) {
        processedDates.push(range.startDate);
      } else {
        let date = range.startDate;
        while (date <= range.endDate) {
          processedDates.push(date);
          date = addDays(date, 1);
        }
      }
    });
    return [...new Set(processedDates.map(date => date.toString()))];
  };
  return show ? (
    <React.Fragment>
      <Overlay onClick={closeModal} />
      <Main>
        <InsertionModal>
          <div>
            <h2>
              {t("Wavelop.application.activityForm.projectLabel")}:{" "}
              {selectedData.project}
            </h2>
            <h2>
              {t("Wavelop.application.activityForm.hoursLabel")}:{" "}
              {selectedData.duration}
            </h2>
            <h2>
              {t("Wavelop.application.activityForm.activityLabel")}:{" "}
              {selectedData.task}
            </h2>
            {selectedData.reference && (
              <h2 style={{ wordBreak: "break-all" }}>
                {t("Wavelop.application.activityForm.linkLabel")}:{" "}
                {selectedData.reference}
              </h2>
            )}
            {selectedData.description && (
              <h2>
                {t("Wavelop.application.activityForm.descriptionLabel")}:{" "}
                {selectedData.description}
              </h2>
            )}
            {selectedData.workplace && (
              <h2>
                {t("Wavelop.application.activityForm.workplaceLabel")}:{" "}
                {selectedData.workplace}
              </h2>
            )}
          </div>
          <div
            className={"quickInsertionCalendar"}
            style={{ border: "1px solid #ddd", borderRadius: "4px" }}
          >
            <DateRangePicker
              color="#6672fb"
              showDateDisplay={false}
              fixedHeight={true}
              weekStartsOn={1}
              locale={locales[t("Wavelop.application.reportsCalendar.locale")]}
              showPreview={true}
              onRangeFocusChange={() => {
                const oldArray = dates;
                oldArray[oldArray.length - 1].autoFocus = false;
              }}
              staticRanges={[]}
              inputRanges={[]}
              onChange={item => {
                const oldArray = dates;
                const keys = Object.keys(item);
                oldArray.push({
                  startDate: item[`${keys[0]}`].startDate,
                  endDate: item[`${keys[0]}`].endDate,
                  key: `key${currentKey + 1}`,
                  autoFocus: true,
                  color: "#3d91ff"
                });
                setCurrentKey(currentKey + 1);
                setDates(oldArray);
              }}
              months={1}
              direction="horizontal"
              ranges={dates}
            />
          </div>
        </InsertionModal>
        <ButtonContainer>
          <ButtonStyled
            style={{ flex: "0 0 15%" }}
            index={0}
            btn={"secondary"}
            text={t("Wavelop.application.modalConfirm.cancelButton")}
            type="button"
            onClick={() => {
              closeModal();
            }}
          />
          <ButtonStyled
            style={{ flex: "0 0 15%" }}
            index={0}
            btn={"primary"}
            text={t("Wavelop.application.modalConfirm.confirmButton")}
            type="button"
            onClick={() => {
              confirm(processDates());
              closeModal();
            }}
          />
        </ButtonContainer>
      </Main>
    </React.Fragment>
  ) : null;
};

export default QuickInsertionModal;
