import { config } from "Config";
import { CrudType } from "Models";
import QueryString from "query-string";

const create = async (
  api: string,
  body: any,
  defaultOptions: any
): Promise<any> => {
  try {
    const res = await fetch(api, {
      method: "POST",
      ...defaultOptions,
      body: JSON.stringify(body)
    });
    if (res.status === 404) {
      window.location.replace("/workspaces");
    }
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

const read = async (
  api: string,
  queryparams: any,
  defaultOptions: any
): Promise<any> => {
  try {
    const res = await fetch(
      `${api}${queryparams ? `?${QueryString.stringify(queryparams)}` : ""}`,
      {
        method: "GET",
        ...defaultOptions
      }
    );
    if (res.status === 404) {
      window.location.replace("/workspaces");
    }
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

const update = async (
  api: string,
  body: any,
  defaultOptions: any
): Promise<any> => {
  try {
    const res = await fetch(api, {
      method: "PUT",
      ...defaultOptions,
      body: JSON.stringify(body)
    });
    if (res.status === 404) {
      window.location.replace("/workspaces");
    }
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

const remove = async (api: string, defaultOptions: any): Promise<any> => {
  try {
    const res = await fetch(api, {
      method: "DELETE",
      ...defaultOptions
    });
    if (res.status === 404) {
      window.location.replace("/workspaces");
    }
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const CrudService = async (
  operation: CrudType,
  api: string,
  body: any = null,
  queryParams: any = null,
  token?: string
): Promise<any> => {
  let defaultOptions: { headers: { [key: string]: any } } = {
    headers: {
      "Content-Type": "application/json",
      "X-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  };
  if (token) {
    defaultOptions = {
      headers: {
        ...defaultOptions.headers,
        Authorization: token ? `Bearer ${token}` : ""
      }
    };
  }
  api = config.api.prefix + api;
  if (window.location.href.includes("https://timesheet.wavelop.com/"))
    api = "https://api.test.timesheet.wavelop.com" + api;
  const crud = {
    [CrudType.Post]: (): Promise<any> => create(api, body, defaultOptions),
    [CrudType.Get]: (): Promise<any> => read(api, queryParams, defaultOptions),
    [CrudType.Put]: (): Promise<any> => update(api, body, defaultOptions),
    [CrudType.Delete]: (): Promise<any> => remove(api, defaultOptions)
  };

  return await crud[operation]();
};
