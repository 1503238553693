import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from "@material-ui/core";
import { useTranslate } from "i18n";
import React from "react";
import { ButtonStyled } from "../../Filter/DateRangeSelector/DateRangeSelector.style";
import {
  ButtonContainer,
  Main,
  Overlay
} from "../../ModalConfirm/ModalConfirm.style";

interface Dialog {
  selectedColumns: any;
  setSelectedColumns: any;
  exportFormat: string;
  closeColumnsModal: any;
  show: boolean;
  confirm: any;
}
const ReportColumnsModal: React.FC<Dialog> = ({
  selectedColumns,
  setSelectedColumns,
  exportFormat,
  closeColumnsModal,
  show,
  confirm
}: Dialog) => {
  const handleChange = event => {
    setSelectedColumns({
      ...selectedColumns,
      [event.target.name]: event.target.checked
    });
  };
  const error = () => {
    return exportFormat === "csv"
      ? Object.keys(selectedColumns).filter(key => selectedColumns[key])
        .length < 1
      : ["description", "user"].every(value => {
        return !Object.keys(selectedColumns)
          .filter(key => selectedColumns[key])
          .includes(value);
      });
  };
  const { t } = useTranslate();
  return show ? (
    <div>
      <Overlay onClick={closeColumnsModal} />
      <Main>
        <h1>{t("Wavelop.application.reportsModal.text")}</h1>
        <FormControl
          error={error()}
          required
          component="fieldset"
          variant="standard"
        >
          {error() && (
            <FormLabel component="legend">
              {exportFormat === "csv"
                ? t("Wavelop.application.reportsModal.errorCSV")
                : t("Wavelop.application.reportsModal.errorPDF")}
            </FormLabel>
          )}
          <FormGroup
            style={{ display: "flex", flexDirection: "row", gap: "10px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedColumns.user}
                  onChange={handleChange}
                  name="user"
                />
              }
              label={t("Wavelop.application.reportsTable.user")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedColumns.client}
                  onChange={handleChange}
                  name="client"
                />
              }
              label={t("Wavelop.application.reportsTable.client")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedColumns.project}
                  onChange={handleChange}
                  name="project"
                />
              }
              label={t("Wavelop.application.reportsTable.project")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedColumns.task}
                  onChange={handleChange}
                  name="task"
                />
              }
              label={t("Wavelop.application.reportsTable.task")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedColumns.day}
                  onChange={handleChange}
                  name="day"
                />
              }
              label={t("Wavelop.application.reportsTable.day")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedColumns.description}
                  onChange={handleChange}
                  name="description"
                />
              }
              label={t("Wavelop.application.reportsTable.description")}
            />
          </FormGroup>
        </FormControl>
        <ButtonContainer>
          <ButtonStyled
            style={{ flex: "0 0 15%" }}
            index={0}
            btn={"secondary"}
            text={t("Wavelop.application.modalConfirm.cancelButton")}
            type="button"
            onClick={() => {
              closeColumnsModal();
            }}
          />
          <ButtonStyled
            style={{ flex: "0 0 15%" }}
            index={0}
            btn={error() ? "disabled" : "primary"}
            text={t("Wavelop.application.modalConfirm.confirmButton")}
            type="button"
            onClick={() => {
              confirm(exportFormat);
              closeColumnsModal();
            }}
          />
        </ButtonContainer>
      </Main>
    </div>
  ) : null;
};

export default ReportColumnsModal;
