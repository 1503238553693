import styled from "styled-components";

export const Buttons = styled.div`
  span:nth-child(1) {
    float: left;
  }
  text-align: right;
  margin-top: ${({ theme }): string => theme.spacing.unit(1.5)};
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    display: flex;
    float: left;
    text-align: left;
  }
`;
export const ButtonsActivities = styled.div`
  width: 100%;
  gap: 1rem;
  text-align: right;
  margin-top: ${({ theme }): string => theme.spacing.unit(1.5)};
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    float: left;
    text-align: left;
  }
`;
export const ButtonRowMobile = styled.div`
  @media (max-width: 768px) {
    display: flex;
    float: right;
    text-align: left;
    gap: 1rem;
  }
`;
export const ButtonsActivitiesEdit = styled.div`
  width: 100%;
  gap: 1rem;
  text-align: right;
  margin-top: ${({ theme }): string => theme.spacing.unit(1.5)};
  @media (max-width: 768px) {
    display: flex;
    float: left;
    text-align: left;
    justify-content: space-between;
  }
`;
