import React, { useEffect } from "react";
import { ButtonContainer, Main, Overlay } from "./RateModal.style";
import { form } from "./RateModal.config";
import {
  DynamicForm,
  withDynamicForm,
  useDynamicForm
} from "@wavelop/dynamic-form";
import { useTranslate } from "i18n";
import {
  ClientIcon,
  StatusIcon,
  DescriptionIcon,
  CodeIcon,
  PriceIcon,
  CalendarIcon
} from "Assets";
import { Button } from "Components";
import {
  useUserState,
  User as userService,
  Project as projectService
} from "Services";
import { successToast } from "Utils";
import { useHistory, useParams } from "react-router-dom";
interface IProps {
  show: boolean;
  value: any;
  closeModal: any;
  handleSubmit: (event: any) => void;
  minDate: Date;
  setRate: any;
  destinationPath: string;
}

const RateModal: React.FC<IProps> = ({
  handleSubmit,
  show,
  value,
  closeModal,
  minDate,
  setRate,
  destinationPath
}: IProps) => {
  const { t } = useTranslate();
  const dispatchModel = useDynamicForm("dispatch", "model");
  const dynamicForm = useDynamicForm();
  const userState = useUserState().state;
  const { token } = userState;
  const history = useHistory();
  const params: any = useParams();
  const onSubmit = async (event: any): Promise<any> => {
    try {
      const { state } = dynamicForm.submit();
      if (destinationPath.includes("/user")) {
        await userService.updateInfoCurrentWorkspace(params.id, state, token);
        successToast(t("Wavelop.application.toast.updateUser.success"));
        history.push("/team/edit/" + params.id);
      } else if (history.location.pathname.includes("new")) {
        console.log(state);
        setRate(state.billableRate, state.startBillableRate);
      } else {
        delete state.minDate;
        await projectService.update(params.id, state, token);
        successToast(t("Wavelop.application.toast.updateProject.success"));
        history.push("/projects/edit/" + params.id);
      }
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    dispatchModel({
      type: "UPDATE_MODEL",
      newState: {
        billableRate: value,
        startBillableRate: new Date(Date.now()),
        minDate: minDate
      }
    });
  }, [dispatchModel, minDate, value]);
  const icons = {
    ClientIcon,
    StatusIcon,
    DescriptionIcon,
    CodeIcon,
    PriceIcon,
    CalendarIcon
  };
  return show ? (
    <div>
      <Overlay onClick={closeModal} />
      <Main>
        <h2>Billable Rate</h2>
        <form>
          <DynamicForm
            config={form(t, icons, minDate)}
            updateErrorAtBlur={false}
            debug={true}
          />
          <ButtonContainer>
            <Button
              btn="primary"
              name="submit"
              text={t("Wavelop.application.userForm.saveButtonText")}
              type="submit"
              onClick={onSubmit}
            />
          </ButtonContainer>
        </form>
      </Main>
    </div>
  ) : null;
};

export default withDynamicForm()(RateModal);
