import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { HelmetProvider } from "react-helmet-async";
import { TranslateProvider } from "i18n";
import { config } from "Config";
import { ThemeProvider } from "Theme";
import {
  AppProvider,
  LoadingProvider,
  useLoadingState,
  useLoading,
  UserProvider
} from "Services";
import {
  Head,
  PrivateLayout,
  PublicLayout,
  Toast
} from "Components";
import { SWWrapper } from "Components";

const App: React.FC = () => {
  const dispatchLoadingState = useLoadingState().dispatch;
  const { startLoading } = useLoading();

  useEffect(() => {
    startLoading(dispatchLoadingState);
  }, [dispatchLoadingState, startLoading]);

  return (
    <HelmetProvider>
      <AppProvider>
        <ThemeProvider>
          <TranslateProvider>
            <LoadingProvider>
              <Head />
              <Toast />
              <SWWrapper />
              <Router>
                <Auth0Provider
                  domain={config.auth0.domain}
                  clientId={config.auth0.clientId}
                  redirectUri={config.auth0.redirectUri}
                  audience={config.auth0.audience}
                  useRefreshTokens={false}
                  cacheLocation={"localstorage"}
                  scope="openid email profile"
                >
                  <UserProvider>
                    <Switch>
                      <Route path="/login" component={PublicLayout} />
                      <Route path="/" component={PrivateLayout} />
                    </Switch>
                  </UserProvider>
                </Auth0Provider>
              </Router>
            </LoadingProvider>
          </TranslateProvider>
        </ThemeProvider>
      </AppProvider>
    </HelmetProvider>
  );
};

export default App;
