import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip
} from "chart.js";
import { useTranslate } from "i18n";
import moment from "moment";
import React from "react";
import { Bar } from "react-chartjs-2";

export interface ReportChartProps {
  chartData: any;
}

const ReportChart: React.FC<ReportChartProps> = props => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);
  const { t } = useTranslate();
  const options = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: Math.floor(Math.max(...props.chartData.map(row => row.hours)) + 1)
      }
    }
  };

  const formatDate = (date: string, locale: string): string => {
    if (moment(date).isValid()) {
      return moment(date)
        .locale(locale)
        .format("D MMM, YYYY");
    } else {
      return date;
    }
  };

  const getLabels = () => {
    const labels: string[] = [];
    props.chartData.forEach((row: any) =>
      labels.push(formatDate(row._id.name, t("Wavelop.application.locale")))
    );
    return labels;
  };

  const getData = () => {
    const data: number[] = [];
    props.chartData.forEach((row: any) => data.push(row.hours));
    return data;
  };

  const data = {
    labels: getLabels(),
    datasets: [
      {
        label: t("Wavelop.application.reportsChart.tooltip"),
        data: getData(),
        backgroundColor: "rgba(102, 114, 251, 0.7)",
        hoverBorderColor: "rgb(16, 0, 240)",
        hoverBorderWidth: 1
      }
    ]
  };

  return <Bar style={{ marginBottom: "30px" }} options={options} data={data} />;
};

export default ReportChart;
