import { config } from "Config";
import { CrudType } from "Models";
import { Crud as crudService } from "Services";
import { IProject } from "src/models/Services/IProject";
import { Utils as utilsService } from "Utils";

const create = async (body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.projects.create,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const read = async (queryparams: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.projects.read,
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readAll = async (queryparams: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.projects.readAll,
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readAllTasks = async (queryparams: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.projects.readAllTasks,
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readOne = async (
  id: string,
  queryparams: any,
  token: any
): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.projects.readOne, { id }),
      null,
      queryparams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const update = async (id: string, body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      utilsService.apiPlaceholderReplace(config.api.projects.update, { id }),
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const remove = async (id: string, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Delete,
      utilsService.apiPlaceholderReplace(config.api.projects.remove, { id }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ProjectService: IProject = {
  create,
  read,
  readAll,
  readAllTasks,
  readOne,
  update,
  delete: remove
};
