import React from "react";
import { useTranslate } from "i18n";
import { AttachedIcon } from "Assets";

import { SmallString, Icon } from "./GroupLink.style";

export interface IProps {
  data: any;
}

export const GroupLink: React.FC<IProps> = ({
  data,
}: IProps) => {
  const { t } = useTranslate();
  return (
    <>
      <Icon src={AttachedIcon} />
      <a href={"../../groups/edit/"+data}>
        <SmallString>
          {t("Wavelop.application.projectTeam.groupLink")}
        </SmallString>
      </a>
    </>
  );
};