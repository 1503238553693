import React from "react";
import { useTranslate } from "i18n";
import { AttachedIcon, gitlabIcon } from "Assets";
import { GitlabIcon, Icon, SmallString } from "./ActivityReference.style";
import { metadataDomain, metadataType } from "Models";

export interface IProps {
  data: any;
  reference?: string;
}

export const ActivityReference: React.FC<IProps> = ({
  data,
  reference
}: IProps) => {
  const { t } = useTranslate();

  const getGitlabReferenceText = (type: string, id?: string): string => {
    switch (type) {
      case metadataType.ISSUE:
        return (
          t("Wavelop.application.activityTable.gitlabReference.issue") + id
        );
      case metadataType.MERGE_REQ:
        return (
          t("Wavelop.application.activityTable.gitlabReference.mergeRequest") +
          id
        );
      default:
        return t("Wavelop.application.activityTable.defaultReference");
    }
  };

  if (Object.keys(data).length > 0) {
    switch (data.domain) {
      case metadataDomain.GITLAB:
        return (
          <>
            <GitlabIcon src={gitlabIcon} />
            <a href={data.URL}>
              <SmallString>
                {getGitlabReferenceText(data.type, data.id)}
              </SmallString>
            </a>
          </>
        );
      default:
        return (
          <>
            <Icon src={AttachedIcon} />
            <a href={reference}>
              <SmallString>
                {t("Wavelop.application.activityTable.defaultReference")}
              </SmallString>
            </a>
          </>
        );
    }
  } else {
    return (
      <>
        <Icon src={AttachedIcon} />
        <a href={reference}>
          <SmallString>
            {t("Wavelop.application.activityTable.defaultReference")}
          </SmallString>
        </a>
      </>
    );
  }
};
