import React, { createContext, useContext, useReducer } from "react";
import { ThemeProvider as ThemeProviderStyledComponent } from "styled-components";

import {
  ActionType,
  ThemeContextInterface,
  ThemeStateInterface
} from "./Models";
import "./global.css";
import DefaultTheme from "./DefaultTheme";
import DarkTheme from "./DarkTheme";

const themes = {
  default: DefaultTheme,
  dark: DarkTheme
};

const ThemeContext = createContext<ThemeContextInterface>({
  state: { theme: DefaultTheme },
  dispatch: () => {}
});

// Reducers
function translateReducer(
  state: ThemeStateInterface = { theme: DefaultTheme },
  action: ActionType
): ThemeStateInterface {
  const { type, theme } = action;
  switch (type) {
    case "CHANGE_THEME": {
      const { theme: currentTheme } = state;
      let newTheme = currentTheme;
      if (theme && themes[theme]) {
        newTheme = themes[theme];
      } else {
        throw new Error("Missing theme");
      }
      return { ...state, theme: newTheme };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

export const ThemeProvider: React.FC<{
  children: React.ReactNode;
}> = (
  props: Partial<{
    children: React.ReactNode;
  }>
) => {
  const [state, dispatch] = useReducer(translateReducer, {
    theme: DefaultTheme
  });
  const values = { state, dispatch };
  const { theme } = state;

  return (
    <ThemeContext.Provider value={values}>
      <ThemeProviderStyledComponent theme={theme}>
        {props.children}
      </ThemeProviderStyledComponent>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): {
  state: ThemeStateInterface;
  dispatch: (action: ActionType) => void;
} => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error(
      "useTranslateState must be used within a TranslateProvider"
    );
  }
  return context;
};

export const defaultTheme = DefaultTheme;
export const darkTheme = DarkTheme;
