export const tableHeaderTitles = (
  type:
    | "clients"
    | "activities"
    | "projects"
    | "reports"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups"
): Array<string> => {
  if (type === "activities")
    return [
      "Wavelop.application.activityTable.task",
      "Wavelop.application.activityTable.project",
      "Wavelop.application.activityTable.description",
      "Wavelop.application.activityTable.workplace",
      "Wavelop.application.activityTable.hour",
      "Wavelop.application.table.action"
    ];
  if (type === "clients")
    return [
      "Wavelop.application.clientTable.name",
      "Wavelop.application.clientTable.code",
      "Wavelop.application.clientTable.description",
      "Wavelop.application.clientTable.activeProjects",
      "Wavelop.application.clientTable.status",
      "Wavelop.application.table.action"
    ];
  if (type === "projects")
    return [
      "Wavelop.application.projectTable.name",
      "Wavelop.application.projectTable.client",
      "Wavelop.application.projectTable.workPercentage",
      "Wavelop.application.projectTable.status",
      "Wavelop.application.table.action"
    ];
  if (type === "reports")
    return [
      "Wavelop.application.reportsTable.name",
      "Wavelop.application.reportsTable.client",
      "Wavelop.application.reportsTable.project",
      "Wavelop.application.reportsTable.task",
      "Wavelop.application.reportsTable.hours",
      "Wavelop.application.reportsTable.date"
    ];
  if (type === "workspaces")
    return [
      "Wavelop.application.workspacesTable.name",
      "Wavelop.application.workspacesTable.code",
      "Wavelop.application.workspacesTable.description",
      "Wavelop.application.workspacesTable.status",
      "Wavelop.application.table.action"
    ];
  if (type === "team")
    return [
      "Wavelop.application.teamTable.picture",
      "Wavelop.application.teamTable.fullName",
      "Wavelop.application.teamTable.email",
      "Wavelop.application.teamTable.role",
      "Wavelop.application.teamTable.lastAccess",
      "Wavelop.application.table.action"
    ];
  if (type === "projectTeam")
    return [
      "Wavelop.application.teamTable.fullName",
      "Wavelop.application.teamTable.email",
      "Wavelop.application.teamTable.projectManager",
      "Wavelop.application.table.action"
    ];
  if (type === "tasks")
    return [
      "Wavelop.application.taskTable.task",
      "Wavelop.application.table.action"
    ];
  if (type === "groups")
    return [
      "Wavelop.application.groupsTable.groupName",
      "Wavelop.application.groupsTable.numberComponents",
      "Wavelop.application.table.action"
    ];
  if (type === "groupMembers")
    return [
      "Wavelop.application.groupMembersTable.fullName",
      "Wavelop.application.groupMembersTable.email",
      "Wavelop.application.groupMembersTable.manager",
      "Wavelop.application.table.action"
    ];
  if (type === "userGroups")
    return [
      "Wavelop.application.groupsTable.groupName",
      "Wavelop.application.groupsTable.numberComponents",
      "Wavelop.application.table.action"
    ];
  else
    return [
      "Wavelop.application.clientTable.name",
      "Wavelop.application.clientTable.code",
      "Wavelop.application.clientTable.description",
      "Wavelop.application.clientTable.activeProjects",
      "Wavelop.application.clientTable.hours",
      "Wavelop.application.clientTable.status"
    ];
};
