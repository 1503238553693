import { DeleteIcon } from "Assets";
import { useTranslate } from "i18n";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  TableContentStyled,
  RowStyled,
  ButtonStyled
} from "./UserGroupRow.style";

export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
  wsRole?: string;
}

const UserGroupRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  data,
  type,
  wsRole
}: IProps) => {
  const history = useHistory();
  const { t } = useTranslate();

  const onDeleteButton = (event): void => {
    onDelete(id);
    event.stopPropagation();
  };

  return (
    <TableContentStyled
      index={index}
      onClick={(): void | null =>
        wsRole === "admin" ? history.push(`groups/edit/${id}`) : null
      }
    >
      <RowStyled>{data.name}</RowStyled>
      <RowStyled>{data.users.length}</RowStyled>
      <RowStyled>
        <ButtonStyled
          onClick={onDeleteButton}
          type="image"
          src={DeleteIcon}
          alt={t("Wavelop.application.table.altDelete")}
        />
      </RowStyled>
    </TableContentStyled>
  );
};

export default UserGroupRow;
