// NPM dependencies
import React from "react";
import { CustomRowStyled } from "./CustomRow.style";

interface IProps {
  classes: any;
  children: any;
}

const CustomRow: React.FC<any> = ({ children }: IProps) => {
  return <CustomRowStyled>{children}</CustomRowStyled>;
};

export default CustomRow;
