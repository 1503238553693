import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { useAppState } from "Services";
import { useTranslate } from "i18n";
import { Alert } from "Components";

interface IProps {
  component: any;
  exact?: boolean;
  restricted?: any;
  name: string;
  token?: string;
  path: string | string[];
}

const CustomRoute: React.FC<IProps> = ({
  component,
  name,
  token,
  ...props
}: any) => {
  const dispatchAppState = useAppState().dispatch;
  const { pathname } = useLocation();
  const { t } = useTranslate();

  useEffect(() => {
    dispatchAppState({
      type: "CHANGE_PAGE",
      page: pathname,
      title: t(`Wavelop.application.headTitle.${name}`)
    });
  }, [dispatchAppState, pathname, name, t]);

  const routeComponent: React.FC = (props: any) =>
    React.createElement(component, { ...props, token });

  return navigator.onLine 
    ? <Route {...props} render={routeComponent} />
    : <Alert
      type={"fail"}
      messageType={"short"}
      text={
        "Non sei connesso alla rete! Riattiva la connessione per avere accesso a tutte le funzionalità."
      }
    />;
};

export default CustomRoute;
