// store.set(key, data);              // === store(key, data);
// store.get(key);                    // === store(key);
// store.clear();                     // === store(false);
// store.has(key);                    // returns true or false
// store.remove(key);                 // removes key and its data, then returns the data or alt, if none
// store.add(key, data);              // concats, merges, or adds new value into existing one
// store.keys();                      // returns array of keys
// store.size();                      // number of keys, not length of data
// store.clearAll();                  // clears *ALL* areas (but still namespace sensitive)

export const setLocalStorageInfo = (user: { [key: string]: any }): void => {
  const { id: userId, workspaces } = user || {};
  const currentWorkspace = workspaces && workspaces.length > 0 && workspaces[0];
  const { id: workspaceId } = currentWorkspace || { id: "" };

  LocalStorageService.setStoreUserId(userId);
  LocalStorageService.setWorkspaceId(workspaceId);
};

const storageAvailable = (type: string): boolean => {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

const isStorageAvalable = (): boolean => {
  try {
    return storageAvailable("localStorage");
  } catch {
    return false;
  }
};

const storeInit = (): { [key: string]: any } => {
  const storageAvaliabily = isStorageAvalable();
  let userId = "";
  let workspaceId = "";

  const setStoreUserId = (id: string): void => {
    userId = id;
  };

  const setWorkspaceId = (id: string): void => {
    workspaceId = id;
  };

  const checkAbilityToWrite = (data?: any): boolean => {
    try {
      JSON.stringify(data); // In case of error it generate an exception
      if (userId === "") {
        throw new Error("userId must be set");
      }

      if (workspaceId === "") {
        throw new Error("workspaceId must be set");
      }

      return storageAvaliabily && userId !== "" && workspaceId !== "";
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const set = (key: string, data: any): any => {
    if (checkAbilityToWrite(data)) {
      const keyCompiled = `${userId}_${workspaceId}_${key}`;
      const dataStringify = JSON.stringify(data);
      window && window.localStorage.setItem(keyCompiled, dataStringify);
      return true;
    } else {
      return null;
    }
  };

  const get = (key: string): any => {
    if (checkAbilityToWrite()) {
      const keyCompiled = `${userId}_${workspaceId}_${key}`;
      const value = window && window.localStorage.getItem(keyCompiled);
      return value && JSON.parse(value);
    } else {
      return null;
    }
  };

  const clear = (): any => {
    window && window.localStorage.clear();
  };

  const has = (key: string): any => {
    return !!get(key);
  };

  const remove = (key: string): any => {
    if (checkAbilityToWrite()) {
      const keyCompiled = `${userId}_${workspaceId}_${key}`;
      window.localStorage.removeItem(keyCompiled);
    } else {
      return null;
    }
  };

  return {
    set,
    get,
    clear,
    has,
    remove,

    setStoreUserId,
    setWorkspaceId
  };
};

let store: any = null;

const storeSingleton = (): { [key: string]: any } => {
  if (!store) {
    store = storeInit();
  }
  return store;
};

export const LocalStorageService = storeSingleton();
