import React, { createContext, useContext, useReducer } from "react";
import { ActionType, UserContextInterface, UserInterface } from "./Models";

import { initialState } from "./Utils";

export const UserContext = createContext<UserContextInterface>({
  state: initialState,
  dispatch: () => {}
});

function userReducer(
  state: UserInterface = initialState,
  action: ActionType
): any {
  const { type, user, token } = action;
  switch (type) {
    case "UPDATE_TOKEN": {
      return { ...state, token };
    }
    case "UPDATE_USER": {
      return { ...state, user };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

export const UserProvider: React.FC<{ children: React.ReactNode }> = (
  props: Partial<{ children: React.ReactNode }>
) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const values = { state, dispatch };

  return (
    <UserContext.Provider value={values}>{props.children}</UserContext.Provider>
  );
};

export const useUserState = (): {
  state: UserInterface;
  dispatch: (action: ActionType) => void;
} => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within an UserProvider");
  }
  return context;
};
