export function deepEqual(first: any, second: any): boolean {
  if (
    first === null ||
    first === undefined ||
    second === null ||
    second === undefined
  ) {
    return first === second;
  }
  if (first.constructor !== second.constructor) {
    return false;
  }
  if (first instanceof Function || first instanceof RegExp) {
    return first === second;
  }
  if (first === second || first.valueOf() === second.valueOf()) {
    return true;
  }
  if (first instanceof Date) return false;
  if (Array.isArray(first) && first.length !== second.length) {
    return false;
  }
  if (!(first instanceof Object) || !(second instanceof Object)) {
    return false;
  }
  const firstKeys = Object.keys(first);
  const allKeysExist = Object.keys(second).every(
    i => firstKeys.indexOf(i) !== -1
  );
  const allKeyValuesMatch = firstKeys.every(i =>
    deepEqual(first[i], second[i])
  );
  return allKeysExist && allKeyValuesMatch;
}
