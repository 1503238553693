import { useAuth0 } from "@auth0/auth0-react";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";

import { Filter, MenuProfile, Navbar, PageTitle, Sidebar } from "Components";
import { IWorkspace, RouteType } from "Models";
import {
  ActivityProvider,
  useLoading,
  useLoadingState,
  User as userService,
  Workspace as workspaceService,
  useUserState,
  useAppState
} from "Services";

import { Redirect, Switch } from "react-router-dom";
import Reports from "src/pages/Reports/Reports";
import { CustomRoute } from "../App";
import { LayoutStyled, Main, MobileHeaderStyle } from "./PrivateLayout.style";
import { IconButton, SwipeableDrawer } from "@material-ui/core";
import { MenuIcon } from "Assets";
import RoleBasedRoute from "../App/Route/RoleBasedRoute";

const Activities: React.FC = lazy(() =>
  import("../../pages/Activities/Activities")
);

const Clients: React.FC = lazy(() => import("../../pages/Clients/Clients"));
const Projects: React.FC = lazy(() => import("../../pages/Projects/Projects"));
const Workspaces: React.FC = lazy(() =>
  import("../../pages/Workspaces/Workspaces")
);
const Team: React.FC = lazy(() => import("../../pages/Team/Team"));
const Invite: React.FC = lazy(() => import("../../pages/Invite/Invite"));

const Groups: React.FC = lazy(() => import("../../pages/Groups/Groups"));

const ActivitiesEdit: React.FC<{ token: string }> = lazy(() =>
  import("../../pages/Activities/Edit/Edit")
);

const ClientsEdit: React.FC<{ token: string }> = lazy(() =>
  import("../../pages/Clients/Edit/Edit")
);

const ProjectsEdit: React.FC<{ token: string }> = lazy(() =>
  import("../../pages/Projects/Edit/Edit")
);

const WorkspaceEdit: React.FC<{ token: string }> = lazy(() =>
  import("../../pages/Workspaces/Edit/Edit")
);

const GroupsEdit: React.FC<{ token: string }> = lazy(() =>
  import("../../pages/Groups/Edit/Edit")
);

const UserEdit: React.FC<{ token: string }> = lazy(() =>
  import("../../pages/Team/Edit/Edit")
);

const Profile: React.FC = lazy(() => import("../../pages/Profile/Profile"));

const PrivateLayout: React.FC = (props: any) => {
  const { state, dispatch: dispatchUser } = useUserState();
  const { user: userToCheck, token: tokenToPass } = state;
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
  const { startLoading, endLoading, incrementLoading } = useLoading();
  const { dispatch: dispatchLoadingState } = useLoadingState();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const { state: appState } = useAppState();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const fetchData = useCallback(async (): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      const { item: user } = await userService.read("", "", token);
      if (user.workspaces.length > 0) {
        const resultWorkspaces = await workspaceService.readByUser(token);
        resultWorkspaces && setWorkspaces(resultWorkspaces.items);
      }
      dispatchUser({ type: "UPDATE_USER", user });
      dispatchUser({ type: "UPDATE_TOKEN", token });
    } catch (error) {
      console.error(error);
    }
    endLoading(dispatchLoadingState);
  }, [dispatchLoadingState, dispatchUser, endLoading, getAccessTokenSilently]);

  useEffect(() => {
    startLoading(dispatchLoadingState);
    incrementLoading(dispatchLoadingState);
    !navigator.onLine && endLoading(dispatchLoadingState);
    !userToCheck && fetchData();
  }, [
    dispatchLoadingState,
    endLoading,
    fetchData,
    incrementLoading,
    startLoading,
    userToCheck
  ]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (appState.workspaces) {
      setWorkspaces([...appState.workspaces] as IWorkspace[]);
      if (appState.fetchUser) {
        fetchData();
      }
    }
  }, [appState.workspaces, appState.fetchUser, fetchData]);

  if (isLoading) {
    return null;
  }
  const redirectTo = (): string => {
    if (
      userToCheck &&
      userToCheck.workspaces.length > 0 &&
      userToCheck.currentWorkspace !== ""
    )
      return "/activities";
    return "/workspace/new";
  };
  return isAuthenticated ? (
    <ActivityProvider>
      <LayoutStyled>
        {windowWidth < 1024 ? (
          <>
            <SwipeableDrawer
              open={mobileOpen}
              onClose={handleDrawerToggle}
              onOpen={handleDrawerToggle}
            >
              <Navbar routeType={RouteType.PRIVATE} data={workspaces} />
            </SwipeableDrawer>
          </>
        ) : (
          <Navbar routeType={RouteType.PRIVATE} data={workspaces} />
        )}

        <Main>
          {windowWidth < 1024 ? (
            <MobileHeaderStyle>
              <IconButton edge="start" onClick={handleDrawerToggle}>
                <img alt="hamburger menu" src={MenuIcon} />
              </IconButton>{" "}
              <MenuProfile user={state.user}></MenuProfile>
            </MobileHeaderStyle>
          ) : (
            <></>
          )}
          <PageTitle routeType={RouteType.PRIVATE} />
          {tokenToPass && (
            <Suspense fallback={<span>Loading</span>}>
              <Switch>
                <Redirect from="/" exact to={redirectTo()} />
                <CustomRoute
                  path={"/activities"}
                  exact={true}
                  name="activities"
                  token={tokenToPass}
                  component={Activities}
                />
                <CustomRoute
                  path={"/reports"}
                  exact={true}
                  name="reports"
                  token={tokenToPass}
                  component={Reports}
                />
                <CustomRoute
                  exact={true}
                  path="/profile"
                  name="profile"
                  token={tokenToPass}
                  component={Profile}
                />
                <CustomRoute
                  exact={true}
                  path="/projects"
                  name="projects"
                  token={tokenToPass}
                  component={Projects}
                />
                <RoleBasedRoute
                  exact={true}
                  path="/clients"
                  name="clients"
                  token={tokenToPass}
                  component={Clients}
                  rolesAllowed={["admin"]}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/team"]}
                  name="team"
                  token={tokenToPass}
                  component={Team}
                  rolesAllowed={["admin"]}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/groups"]}
                  name="groups"
                  token={tokenToPass}
                  component={Groups}
                  rolesAllowed={["admin"]}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/projects/new/"]}
                  name="newProjects"
                  token={tokenToPass}
                  component={ProjectsEdit}
                  rolesAllowed={["admin"]}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/clients/new/"]}
                  name="newClients"
                  token={tokenToPass}
                  component={ClientsEdit}
                  rolesAllowed={["admin"]}
                />
                <CustomRoute
                  exact={true}
                  path={["/workspace/new/"]}
                  name="newWorkspace"
                  token={tokenToPass}
                  component={WorkspaceEdit}
                />
                <CustomRoute
                  exact={true}
                  path={["/activities/edit/", "/activities/edit/:id"]}
                  name="editActivities"
                  component={ActivitiesEdit}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/projects/edit/", "/projects/edit/:id"]}
                  name="editProjects"
                  token={tokenToPass}
                  component={ProjectsEdit}
                  rolesAllowed={["admin"]}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/clients/edit/", "/clients/edit/:id"]}
                  name="editClients"
                  token={tokenToPass}
                  component={ClientsEdit}
                  rolesAllowed={["admin"]}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/groups/edit/", "/groups/edit/:id"]}
                  name="editGroups"
                  token={tokenToPass}
                  component={GroupsEdit}
                  rolesAllowed={["admin"]}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/team/edit/", "/team/edit/:id"]}
                  name="editUser"
                  token={tokenToPass}
                  component={UserEdit}
                  rolesAllowed={["admin"]}
                />
                <CustomRoute
                  exact={true}
                  path={["/workspaces/"]}
                  name="workspaces"
                  token={tokenToPass}
                  component={Workspaces}
                />
                <RoleBasedRoute
                  exact={true}
                  path={["/settings/:id"]}
                  name="settings"
                  token={tokenToPass}
                  component={WorkspaceEdit}
                  rolesAllowed={["admin"]}
                />
                <CustomRoute
                  exact={true}
                  path={["/invite/:inviteToken"]}
                  name="invite"
                  token={tokenToPass}
                  component={Invite}
                />
              </Switch>
            </Suspense>
          )}
          {windowWidth < 1024 ? (
            <>
              <Filter type={props?.location?.pathname || ""} />
            </>
          ) : (
            <></>
          )}
        </Main>
        {windowWidth < 1024 ? <></> : <Sidebar />}
      </LayoutStyled>
    </ActivityProvider>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props && props.location ? props.location : "" }
      }}
    />
  );
};

export default PrivateLayout;
