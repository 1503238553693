import styled from "styled-components";
import { Button } from "Components";

export const ButtonStyled = styled(Button)`
  flex: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > span {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  & > span > svg {
    display: inline-flex;
    vertical-align: text-top;
    transform: ${({ active }): string => active && "rotate(180deg)"};
  }
`;

export const SortingSelectorStyled = styled.div`
  background-color: ${({ theme }): string => theme.colors.clockBackground};
`;
