const apiPlaceholderReplace = (api: string, replacements: any): string => {
  return api.replace(/{\w+}/g, replacements.id);
};

const apiPlaceholderReplaceMultiple = (
  api: string,
  replacements: any,
  i: number
): string => {
  if (api.search("{") !== -1) {
    api = api.replace(/{\w+}/, replacements[i]);
    i = i + 1;
    return apiPlaceholderReplaceMultiple(api, replacements, i);
  } else {
    return api;
  }
};

export const utilsService = {
  apiPlaceholderReplace: (api: string, replacements: any): string =>
    apiPlaceholderReplace(api, replacements),
  apiPlaceholderReplaceMultiple: (
    api: string,
    replacements: any,
    i: number
  ): string => apiPlaceholderReplaceMultiple(api, replacements, i)
};
