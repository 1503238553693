import { Select, Input, CustomRow } from "Components";

export const form: any = (t: any, data: any, icons: any) => {
  const { projectOptions, taskOptions, workplaceOptions } = data;
  const readOnlyActivities =
    !taskOptions || (taskOptions && taskOptions.length === 0);

  const result = [
    {
      name: "row1",
      tag: "row",
      customRow: CustomRow,
      fields: [
        {
          name: "cell2",
          tag: "row",
          customRow: CustomRow,
          fields: [
            {
              name: "project",
              label: t("Wavelop.application.activityForm.projectLabel"),
              helperText: t(
                "Wavelop.application.activityForm.projectPlaceholder"
              ),
              type: ["primary"],
              attributes: {
                class: "large",
                icon: icons.AlertIcon
              },
              tag: Select,
              validations: [
                {
                  kind: "required",
                  message: t("Wavelop.error.message.projectField.required")
                }
              ],
              options: projectOptions
            }
          ]
        },
        {
          name: "cell2",
          tag: "row",
          customRow: CustomRow,
          fields: [
            {
              name: "task",
              label: t("Wavelop.application.activityForm.activityLabel"),
              tag: Select,
              helperText: t(
                "Wavelop.application.activityForm.activityPlaceholder"
              ),
              type: ["primary"],
              attributes: {
                class: "small"
              },
              validations: [
                {
                  kind: "required",
                  message: t("Wavelop.error.message.activityField.required")
                }
              ],
              options: taskOptions,
              readOnly: readOnlyActivities
            },
            {
              name: "workplace",
              label: t("Wavelop.application.activityForm.workplaceLabel"),
              tag: Select,
              helperText: t(
                "Wavelop.application.activityForm.workplacePlaceholder"
              ),
              type: ["primary"],
              attributes: {
                class: "small"
              },
              /*validations: [
                {
                  kind: "required",
                  message: t("Wavelop.error.message.workplaceField.required")
                }
              ],*/
              options: workplaceOptions
            }
          ]
        }
      ]
    },
    {
      name: "row2",
      tag: "row",
      customRow: CustomRow,
      fields: [
        {
          name: "cell3",
          tag: "row",
          customRow: CustomRow,
          fields: [
            {
              name: "description",
              label: t("Wavelop.application.activityForm.descriptionLabel"),
              helperText: t(
                "Wavelop.application.activityForm.descriptionPlaceholder"
              ),
              tag: Input
            }
          ]
        },
        {
          name: "cell4",
          tag: "row",
          customRow: CustomRow,
          fields: [
            {
              name: "reference",
              label: t("Wavelop.application.activityForm.linkLabel"),
              helperText: t("Wavelop.application.activityForm.linkPlaceholder"),
              tag: Input,
              // type: ["text","icon"],
              type: ["text"],
              attributes: {
                icon: icons.AlertIcon
              },
              validations: [
                {
                  kind: "pattern",
                  negate: true,
                  reg: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                  message: t("Wavelop.error.message.link.pattern")
                }
              ]
            }
          ]
        }
      ]
    }
  ];

  return result;
};
