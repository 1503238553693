import React, { useCallback, useEffect, useState } from "react";
import {
  TableContentStyled,
  RowStyled,
  ButtonStyled,
  DefaultImageProfileTableRow,
  RowStyledMobile
} from "./TeamRow.style";
import {
  DynamicForm,
  withDynamicForm,
  useDynamicForm
} from "@wavelop/dynamic-form";
import { DeleteIcon, ModifyIcon, StatusIcon } from "Assets";
import { useTranslate } from "i18n";
import { ImageProfile } from "src/components/MenuProfile/MenuProfile.style";
import { formatDateTime } from "Utils";
import { form } from "./TeamRow.config";
import { useUserState } from "Services";
import { Link, useHistory } from "react-router-dom";

export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers";
  wsRole: string;
}

const TeamRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  data,
  type,
  wsRole
}: IProps) => {
  const { t } = useTranslate();
  const dispatchModel = useDynamicForm("dispatch", "model");
  const { state } = useUserState();
  const { user } = state;
  const [role, setRole] = useState<string>();

  const getUserRole = useCallback(() => {
    try {
      setRole(
        data.workspaces.find(item => item.id === user?.currentWorkspace).role
      );
      dispatchModel({
        type: "UPDATE_MODEL",
        newState: {
          role: role
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, [data.workspaces, dispatchModel, role, user]);

  useEffect(() => {
    getUserRole();
  }, [getUserRole, t, state]);

  const onDeleteButton = (event): void => {
    onDelete(id);
    event.stopPropagation();
  };

  const hourToDisplay: string = formatDateTime(data.lastAccess)[3];
  const dateToDisplay: string =
    formatDateTime(data.lastAccess)[0] +
    " " +
    formatDateTime(data.lastAccess)[1];

  const icons = {
    StatusIcon
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const history = useHistory();
  return (
    <TableContentStyled
      index={index}
      onClick={(): void | null =>
        wsRole === "admin" ? history.push(`team/edit/${id}`) : null
      }
    >
      {windowWidth < 768 ? (
        <RowStyledMobile>{data.fullName}</RowStyledMobile>
      ) : (
        <>
          <RowStyled>
            {data?.picture ? (
              <ImageProfile src={data?.picture} referrerPolicy="no-referrer" />
            ) : (
              <DefaultImageProfileTableRow>
                {data?.firstName.charAt(0)}
                {data?.lastName.charAt(0)}
              </DefaultImageProfileTableRow>
            )}
          </RowStyled>
          <RowStyled>{data.fullName}</RowStyled>
        </>
      )}

      {windowWidth < 768 ? (
        <RowStyledMobile>
          <form
            onClick={(event): void =>
              wsRole === "admin"
                ? event.stopPropagation()
                : event.preventDefault()
            }
          >
            <DynamicForm
              config={
                wsRole === "admin"
                  ? form(
                    t,
                    { codeDisabled: data.id === user?.id ? true : false },
                    icons,
                    data.id
                  )
                  : form(t, { codeDisabled: true }, icons, data.id)
              }
              updateErrorAtBlur={true}
              debug={false}
            />
          </form>
        </RowStyledMobile>
      ) : (
        <>
          <RowStyled>{data.email}</RowStyled>
          <RowStyled>
            <form
              onClick={(event): void =>
                wsRole === "admin"
                  ? event.stopPropagation()
                  : event.preventDefault()
              }
            >
              <DynamicForm
                config={
                  wsRole === "admin"
                    ? form(
                      t,
                      { codeDisabled: data.id === user?.id ? true : false },
                      icons,
                      data.id
                    )
                    : form(t, { codeDisabled: true }, icons, data.id)
                }
                updateErrorAtBlur={true}
                debug={false}
              />
            </form>
          </RowStyled>
        </>
      )}

      {windowWidth < 768 ? (
        data.id === user?.id ? (
          <RowStyledMobile />
        ) : (
          <RowStyledMobile>
            <ButtonStyled
              onClick={wsRole === "admin" ? onDeleteButton : null}
              type="image"
              src={DeleteIcon}
              alt={t("Wavelop.application.table.altDelete")}
              disabled={wsRole === "admin" ? false : true}
            />
          </RowStyledMobile>
        )
      ) : (
        <>
          <RowStyled>
            {hourToDisplay} - {dateToDisplay}
          </RowStyled>
          {data.id === user?.id ? (
            <RowStyled />
          ) : (
            <RowStyled>
              <Link
                to={`team/edit/${id}`}
                onClick={(event): void =>
                  wsRole === "admin"
                    ? event.stopPropagation()
                    : event.preventDefault()
                }
              >
                <ButtonStyled
                  type="image"
                  src={ModifyIcon}
                  alt={t("Wavelop.application.table.altEdit")}
                  disabled={wsRole === "admin" ? false : true}
                />
              </Link>
              <ButtonStyled
                onClick={wsRole === "admin" ? onDeleteButton : null}
                type="image"
                src={DeleteIcon}
                alt={t("Wavelop.application.table.altDelete")}
                disabled={wsRole === "admin" ? false : true}
              />
            </RowStyled>
          )}
        </>
      )}
    </TableContentStyled>
  );
};

export default withDynamicForm()(TeamRow);
