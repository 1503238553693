import styled from "styled-components";
import { DownIcon2 } from "Assets";

export const SelectSectionStyled = styled.section`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Dropdown = styled.div`
  position: relative;

  font-size: ${({ theme }): string => theme.fontSize.unit(2)};

  color: ${({ theme }): string => theme.colors.dropdownTextColor};

  margin: 0;

  width: 100%;

  &.large {
    margin-top: 0;
    flex: 2;
  }

  &.small {
    margin-top: 0;
    width: 100%;
    flex: 1;
  }
`;

export const DropdownHeader = styled.div`
  transition: 0.3s;

  position: relative;

  border-radius: ${({ theme }): string =>
    theme.borderRadiusQuickInsertionSelect};

  background-color: ${({ theme, readOnly, disabled }): string => {
    let result = theme.colors.surface;
    result = readOnly ? theme.colors.readOnly : result;
    result = disabled ? theme.colors.disabled : result;
    return result;
  }};

  padding: ${({ theme, type }): string => {
    if (type === "icon")
      return `${theme.spacing.unit(0.75)} ${theme.spacing.unit(2)} 
      ${theme.spacing.unit(0.75)} ${theme.spacing.unit(3.5)}`;
    if (type === "primary")
      return `${theme.spacing.unit(0.75)} ${theme.spacing.unit(2)} 
      ${theme.spacing.unit(0.75)} ${theme.spacing.unit(1)}`;
    return `${theme.spacing.unit(0.1)} ${theme.spacing.unit(2)} 
      ${theme.spacing.unit(0.1)} ${theme.spacing.unit(1)}`;
  }};

  border: ${({ theme, error, readOnly, disabled, isOpen, type }): string => {
    let result = "2px solid " + theme.colors.surface;
    result =
      type === "icon" || type === "team"
        ? "2px solid " + theme.colors.inputIconBackground + "29"
        : result;
    result = isOpen ? "2px solid " + theme.colors.primary : result;
    result = readOnly ? "2px solid " + theme.colors.readOnly : result;
    result = disabled ? "2px solid " + theme.colors.disabled : result;
    result =
      error?.length > 0 || error === true
        ? "2px solid " + theme.colors.status01
        : result;
    return result;
  }};

  box-shadow: ${({ theme, isOpen, error }): string => {
    let result = "none";
    result = isOpen
      ? "0px 0px 4px 3px " + theme.colors.alpha(theme.colors.primary, 0.2)
      : result;
    result =
      isOpen && (error?.length > 0 || error === true)
        ? "0px 0px 4px 3px " + theme.colors.alpha(theme.colors.status01, 0.2)
        : result;
    return result;
  }};

  cursor: ${({ readOnly, disabled }): string => {
    let result = "pointer";
    result = readOnly ? "initial" : result;
    result = disabled ? "not-allowed" : result;
    return result;
  }};

  margin: ${({ theme }): string => theme.spacing.unit(0.25)}
    ${({ theme }): string => theme.spacing.unit(0.25)};

  color: ${({ theme, disabled }): string => {
    let result = "inherit";
    result = disabled ? theme.colors.textDisabled : result;
    return result;
  }};

  &:focus,
  &:hover {
    outline: none;
    border: ${({ theme, error, readOnly, disabled }): string => {
    let result = "2px solid " + theme.colors.primary;
    result = readOnly || disabled ? "inherith" : result;
    result =
        error?.length > 0 || error === true
          ? "2px solid " + theme.colors.status01
          : result;
    return result;
  }};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 4px 3px
      ${({ theme, error, readOnly, disabled }): string => {
    let result = theme.colors.alpha(theme.colors.primary, 0.2);
    result = readOnly ? theme.colors.readOnly : result;
    result = disabled ? theme.colors.disabled : result;
    result =
          error?.length > 0 || error === true
            ? theme.colors.alpha(theme.colors.status01, 0.2)
            : result;
    return result;
  }};
  }

  &:active {
    outline: none;
    border: ${({ theme, error, readOnly, disabled }): string => {
    let result = "2px solid " + theme.colors.primary;
    result = readOnly || disabled ? "inherith" : result;
    result =
        error?.length > 0 || error === true
          ? "2px solid " + theme.colors.status01
          : result;
    return result;
  }};
  }

  &:after {
    content: url(${DownIcon2});
    position: absolute;
    right: ${({ theme }): string => theme.spacing.unit(0.75)};
    opacity: ${({ readOnly, disabled }): string => {
    let result = "100%";
    result = readOnly ? "15%" : result;
    result = disabled ? "15%" : result;
    return result;
  }};
  }
`;

export const SpanSelect = styled.span`
  ${({ type, theme }): string =>
    type === "icon"
      ? `
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    border-radius: ${theme.borderRadiusQuickInsertionSelectIcon};
    background-color: ${theme.colors.inputIconBackground}29;
    height: 100%;
    width: ${theme.spacing.unit(3)};
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: ${theme.spacing.unit(1)};
      max-height: ${theme.spacing.unit(1)};
    }
  `
      : ""}
`;

export const SelectLabelStyled = styled.label`
  font-size: ${({ theme }): string => theme.fontSize.unit(1.5)};
  margin: 0px 4px;
  padding: 4px 2px 0px;
  color: gray;
`;

export const DropdownHeaderSecondary = styled.div`
  transition: 0.3s;
  position: relative;
  border-radius: 0;
  font-size: ${({ theme }): string => theme.fontSize.unit(2.5)};
  margin: 0 ${({ theme }): string => theme.spacing.unit(0.5)} 0
    ${({ theme }): string => theme.spacing.unit(1.5)};
  background-color: ${({ theme }): string =>
    theme.colors.datePickerHourInputBackground};
  cursor: pointer;
  padding: 0 ${({ theme }): string => theme.spacing.unit(0.5)};
  margin: 0;
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
  font-weight: bold;

  border-bottom: ${({ theme, isOpen }): string => {
    let result = "2px solid transparent";
    result = isOpen ? "2px solid " + theme.colors.primary : result;
    return result;
  }};

  box-shadow: ${({ theme, isOpen, error }): string => {
    let result = "none";
    result = isOpen
      ? "0px 8px 4px -4px  " + theme.colors.alpha(theme.colors.primary, 0.2)
      : result;
    result =
      isOpen && (error?.length > 0 || error === true)
        ? "0px 8px 4px -4px  " + theme.colors.alpha(theme.colors.status01, 0.2)
        : result;
    return result;
  }};

  &:focus {
    outline: none;
    box-shadow: 0px 8px 4px -4px ${({ theme }): string => theme.colors.alpha(theme.colors.primary, 0.2)};
  }

  &:hover,
  &:focus {
    outline: none;
    border-bottom: 2px solid
      ${({ theme }): string => theme.colors.darken(theme.colors.primary)};
  }
`;
