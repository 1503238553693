import styled from "styled-components";

export const AlertStyled = styled.div`
  width: 100;
  padding: ${({ theme }): string => theme.spacing.unit(0.75)}
    ${({ theme }): string => theme.spacing.unit(1.125)};
  background-color: ${({ type, theme }): string => {
    return type === "success"
      ? theme.colors.status02 + "2a"
      : theme.colors.status01 + "2a";
  }};
  font-weight: bold;
  border-radius: ${({ theme }): string => theme.borderRadiusAlert};
  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};
  margin: ${({ theme }): string => theme.spacing.unit(1)} 0;
  img {
    margin-right: ${({ theme }): string => theme.spacing.unit(1)};
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
`;

export const AlertLi = styled.li`
  font-weight: normal;
`;

export const AlertUl = styled.ul`
  color: ${({ theme }): string => theme.colors.alertListColor};
  margin-bottom: 0;
`;
