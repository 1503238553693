import React, { lazy, Suspense, useEffect, useState } from "react";
import { LayoutStyled, Main } from "./PublicLayout.style";
import { Navbar, PageTitle } from "Components";
import { RouteType } from "Models";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CustomRoute } from "../App";

const Login: React.FC = lazy(() => import("../../pages/Login/Login"));

const PublicLayout: React.FC = props => {
  const { isAuthenticated } = useAuth0();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return isAuthenticated ? (
    <Redirect to="/activities" />
  ) : (
    <LayoutStyled>
      {windowWidth < 768 ? <></> : <Navbar routeType={RouteType.PUBLIC} />}
      <Main>
        <PageTitle routeType={RouteType.PUBLIC} />
        <Suspense fallback={<span>Loading</span>}>
          <CustomRoute
            exact={true}
            restricted={true}
            path="/login"
            name="login"
            component={Login}
          />
        </Suspense>
      </Main>
    </LayoutStyled>
  );
};

export default PublicLayout;
