import React, { memo, useMemo, useCallback } from "react";
import { deepEqual } from "Utils";

import { setActualTime } from "Utils";
import { useActivityState } from "Services";

import { CalendarDayWrapper } from "./CalendarDay.style";

interface IProps {
  day: Date;
  dayStatus: "ok" | "ko" | "future-ko" | "future-ok";
  isToday: boolean;
  isSelected: boolean;
  dayOff: boolean;
  dayOffBottom: boolean;
  dayOffTop: boolean;
  outOfMonth: boolean;
}

const CalendarDay: React.FC<any> = ({
  day,
  dayStatus,
  isToday,
  isSelected,
  dayOff,
  dayOffBottom,
  dayOffTop,
  outOfMonth
}: IProps) => {
  const { state, dispatch } = useActivityState();

  const handleClick = useCallback((): void => {
    const { view } = state;
    const range = setActualTime("specific", view, day);

    dispatch({
      type: "CHANGE_RANGE",
      from: range[0],
      to: range[1]
    });
  }, [day, dispatch, state]);

  return useMemo(
    () => (
      <CalendarDayWrapper
        onClick={handleClick}
        dayStatus={dayStatus}
        isToday={isToday}
        isSelected={isSelected}
        dayOff={dayOff}
        dayOffBottom={dayOffBottom}
        dayOffTop={dayOffTop}
        outOfMonth={outOfMonth}
      >
        {day.getDate()}
      </CalendarDayWrapper>
    ),
    [
      day,
      dayOff,
      dayOffBottom,
      dayOffTop,
      dayStatus,
      handleClick,
      isSelected,
      isToday,
      outOfMonth
    ]
  );
};

const propsAreEqual = (prevProps: any, nextProps: any): boolean => {
  return deepEqual(prevProps, nextProps);
};

export default memo(CalendarDay, propsAreEqual);
