import styled from "styled-components";

export const ActivityQuickInsert = styled.div`
  background-color: ${({ theme }): string =>
    theme.colors.quickInsertionBackground};
  border-radius: ${({ theme }): string => theme.borderRadiusQuickInsertion};
  margin: ${({ theme }): string => theme.spacing.unit(1)} 0;
  display: flex;
  animation: fadeIn ease 0.4s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
