import styled from "styled-components";

export const Main = styled.div`
  position: fixed;
  background: white;
  padding: 40px;
  max-height: 80%;
  min-width: 60%;
  width: fit-content;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  z-index: 2;
  @media (max-width: 768px) {
    overflow: scroll;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

export const Button = styled.button`
  background-color: ${({ theme }): string =>
    theme.colors.buttonFilterBackgroundActive};
  color: ${({ theme }): string => theme.colors.surface};
  width: fit-content;
  span:nth-child(1) {
    float: left;
  }
  text-align: center;
  margin-top: ${({ theme }): string => theme.spacing.unit(1.5)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 12px;
`;
