import { config } from "Config";
import { CrudType } from "Models";
import { Crud as crudService } from "Services";
import { IGroup } from "src/models/Services/IGroup";
import { utilsService } from "src/utils/Services/Services";

const create = async (body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.groups.create,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const read = async (id: string, queryparams, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.groups.read, { id }),
      null,
      queryparams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const update = async (id: string, body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      utilsService.apiPlaceholderReplace(config.api.groups.update, { id }),
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const remove = async (id: string, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Delete,
      utilsService.apiPlaceholderReplace(config.api.groups.remove, { id }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readByWorkspace = async (
  queryParams: any,
  token?: string
): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.groups.readByWorkspace,
      null,
      queryParams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readAll = async (queryparams: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.groups.readAll,
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readByUser = async (id: string, token?: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.groups.readByUser, { id }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const removeUser = async (queryParams: any, token?: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      config.api.groups.removeUser,
      null,
      queryParams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GroupService: IGroup = {
  create,
  read,
  update,
  delete: remove,
  readByWorkspace,
  readAll,
  readByUser,
  removeUser
};
