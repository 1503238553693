import {
  DynamicForm,
  useDynamicForm,
  withDynamicForm
} from "@wavelop/dynamic-form";
import React, { useEffect, useState } from "react";

import { ButtonsRow } from "Components";
import { useTranslate } from "i18n";
import { Activity, useLoading, useLoadingState, useUserState } from "Services";
import {
  appendError,
  errorToast,
  formatFormErrors,
  formatProjectOptions,
  formatTaskOptions,
  formatWorkplaceOptions,
  fromHoursToTime,
  successToast
} from "Utils";

import { AlertIcon } from "Assets";
import QuickInsertionModal from "../Modal/QuickInsertionModal";
import { form } from "./Form.config";
import { FormStyled } from "./Form.style";

export interface IProps {
  data: any[];
  date: Date;
  duration: number;
  handleDynamicResults: (result: any) => void;
  resetDatePicker: () => void;
}

const Form: React.FC<IProps> = ({
  data,
  date,
  duration,
  handleDynamicResults,
  resetDatePicker,
  ...props
}: IProps) => {
  const { t } = useTranslate();
  const dynamicForm = useDynamicForm();
  const stateFromService = useDynamicForm("state", "model");
  const dispatchModel = useDynamicForm("dispatch", "model");
  const { project, task } = stateFromService;
  const dispatchLoadingState = useLoadingState().dispatch;
  const { startLoading, incrementLoading } = useLoading();
  const { state } = useUserState();
  const { token } = state;
  const [showModal, setShowModal] = useState(false);
  const [dataForModal, setDataForModal] = useState();
  const handleReset = (): void => {
    resetDatePicker();
    dispatchModel({
      type: "UPDATE_MODEL",
      newState: {
        description: "",
        project: null,
        task: null,
        reference: "",
        workplace: null
      }
    });
  };
  const missingInfo = {
    day: date,
    work: duration,
    workspace: data && data[0].workspace
  };

  const onSubmit = async (event: any): Promise<any> => {
    event.preventDefault();
    startLoading(dispatchLoadingState);
    try {
      const { state } = dynamicForm.submit();
      if (duration === 0) {
        throw appendError(
          {},
          "work",
          "required",
          t("Wavelop.error.message.durationField.required")
        );
      }
      incrementLoading(dispatchLoadingState);
      const result = await Activity.create({ ...state, ...missingInfo }, token);
      if (result.error)
        handleDynamicResults(
          formatFormErrors("server", 1, result.error.errorMessage)
        );
      else {
        handleDynamicResults(formatFormErrors("", 0, {}));
        handleReset();
        successToast(t("Wavelop.application.toast.activity.success"));
      }
    } catch (e) {
      let newErr;
      if (duration === 0)
        newErr = appendError(
          e,
          "work",
          "required",
          t("Wavelop.error.message.durationField.required")
        );
      handleDynamicResults(
        formatFormErrors(
          "validation",
          newErr ? newErr.numberOfErrors : (e as any).numberOfErrors || 0,
          newErr ? newErr.errors : (e as any).errors
        )
      );
      errorToast(t("Wavelop.application.toast.activity.error"));
    }
  };

  const copy = async (event: any) => {
    try {
      const { state } = dynamicForm.submit();
      console.log(state);
      if (duration !== 0 && project !== null && task !== null) {
        let projectName = "";
        data.forEach(row => {
          if (row.id === project) {
            projectName = row.name;
          }
        });
        state.project = projectName;
        state.duration = duration;
        setShowModal(true);
        setDataForModal(state);
      } else {
        throw appendError(
          {},
          "work",
          "required",
          t("Wavelop.error.message.durationField.required")
        );
      }
    } catch (e) {
      let newErr;
      if (duration === 0)
        newErr = appendError(
          e,
          "work",
          "required",
          t("Wavelop.error.message.durationField.required")
        );
      handleDynamicResults(
        formatFormErrors(
          "validation",
          newErr ? newErr.numberOfErrors : (e as any).numberOfErrors || 0,
          newErr ? newErr.errors : (e as any).errors
        )
      );
      errorToast(t("Wavelop.application.toast.activity.error"));
    }
  };
  const closeModal = (): void => {
    setShowModal(false);
  };
  const confirmModal = async (dates: string[]): Promise<any> => {
    setShowModal(false);
    startLoading(dispatchLoadingState);
    try {
      const { state } = dynamicForm.submit();

      const finalState: any = [];
      dates.forEach(date => {
        const entity = { ...state, ...missingInfo };
        entity.day = new Date(date);
        finalState.push(entity);
      });
      incrementLoading(dispatchLoadingState);
      console.log(finalState);
      const result = await Activity.createBulk({ finalState }, token);
      if (result.error)
        handleDynamicResults(
          formatFormErrors("server", 1, result.error.errorMessage)
        );
      else {
        handleDynamicResults(formatFormErrors("", 0, {}));
        handleReset();
        successToast(t("Wavelop.application.toast.activity.success"));
      }
    } catch (e) {
      console.log(e);
      errorToast(t("Wavelop.application.toast.activity.error"));
    }
  };

  const formatData = (data: any): any => {
    const projectOptions = formatProjectOptions(data);
    const taskOptions = formatTaskOptions(data, project);
    const workplaceOptions = formatWorkplaceOptions(
      t("Wavelop.application.workplace.office"),
      t("Wavelop.application.workplace.remote"),
      t("Wavelop.application.workplace.client")
    );
    return {
      projectOptions,
      taskOptions,
      workplaceOptions
    };
  };

  const dataFormatted = data && formatData(data);
  const { taskOptions } = dataFormatted || {};
  const icons = { AlertIcon };

  useEffect(() => {
    if (
      task &&
      taskOptions &&
      !taskOptions?.map(item => item.value).includes(task)
    ) {
      dispatchModel({
        type: "UPDATE_MODEL",
        newState: {
          task: null
        }
      });
    }
  }, [dispatchModel, task, taskOptions]);

  const actualState = {
    ...stateFromService,
    ...{
      day: date.toString(),
      hours: fromHoursToTime(missingInfo.work)[0] || null,
      minutes: missingInfo.work
        ? fromHoursToTime(missingInfo.work)[1].toString()
        : null
    }
  };
  delete actualState._metadata;
  delete actualState.work;

  return !data ? (
    <span></span>
  ) : (
    <React.Fragment>
      <FormStyled>
        <DynamicForm
          config={form(t, dataFormatted, icons)}
          updateErrorAtBlur={true}
          debug={false}
        />
        <ButtonsRow
          handleReset={handleReset}
          copy={copy}
          onSubmit={onSubmit}
          redirect={true}
          destinationPath={"activities/edit/"}
          state={actualState}
          destinationTitle={t(
            "Wavelop.application.activityForm.editButtonText"
          )}
          saveCopy={true}
        />
      </FormStyled>
      {showModal && (
        <QuickInsertionModal
          selectedData={dataForModal}
          date={new Date(date.toString())}
          closeModal={closeModal}
          show={showModal}
          confirm={confirmModal}
        />
      )}
    </React.Fragment>
  );
};

export default withDynamicForm()(Form);
