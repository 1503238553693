import styled from "styled-components";

export const CalendarLegendWrapper = styled.section``;

export const CalendarLegendLine = styled.ul`
  margin: 0px;
  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(1)} 0
    ${({ theme }): string => theme.spacing.unit(2.5)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  list-style: none;
`;

export const CalendarLegendElement = styled.li`
  margin: 0px;
  padding: 0px;
  flex: 1;
  position: relative;
  max-width: 50%;
  word-break: break-word;
  font-size: ${({ theme }): string => theme.fontSize.unit(1.5)};

  padding: 0 ${({ theme }): string => theme.spacing.unit(0.5)};

  &:before {
    content: " ";
    position: absolute;
    height: ${({ theme }): string => theme.spacing.unit(0.5)};
    width: ${({ theme }): string => theme.spacing.unit(0.5)};
    background-color: ${({ theme, type }): string => {
    if (type === "ok") return theme.colors.status02;
    if (type === "ko") return theme.colors.status01;
    if (type === "future-ok") return theme.colors.status04;
    if (type === "future-ko") return theme.colors.status03;
    return "";
  }};
    left: -${({ theme }): string => theme.spacing.unit(0.5)};
    top: ${({ theme }): string => theme.spacing.unit(0.25)};
    border-radius: 50%;
    border: 1px solid
      ${({ theme, type }): string => {
    if (
      type === "ok" ||
          type === "ko" ||
          type === "future-ok" ||
          type === "future-ko"
    )
      return theme.colors.surface;
    return "transparent";
  }};
  }

  &:after {
    content: " ";
    position: absolute;
    height: ${({ theme }): string => theme.spacing.unit(0.625)};
    width: ${({ theme }): string => theme.spacing.unit(0.625)};
    background-color: transparent;
    left: -${({ theme }): string => theme.spacing.unit(0.5 + (0.625 - 0.5) / 2)};
    top: ${({ theme }): string => theme.spacing.unit(0.25 - (0.625 - 0.5) / 2)};
    border-radius: 50%;
    border: 1px solid
      ${({ theme, type }): string => {
    if (type === "ok") return theme.colors.status02;
    if (type === "ko") return theme.colors.status01;
    if (type === "future-ok") return theme.colors.status04;
    if (type === "future-ko") return theme.colors.status03;
    return "";
  }};
  }
`;

//   height: ${({ theme }): string => theme.spacing.unit(2)};
//   width: ${({ theme }): string => theme.spacing.unit(2)};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: ${({ theme }): string => theme.borderRadiusTables};
//   border: 2px solid transparent;
//   margin: ${({ theme }): string => theme.spacing.unit(0.25)};
//   position: relative;
//   background-color: transparent;
//   outline: 0;
//   cursor: ${({ isSelected }): string => {
//     if (!isSelected) return "pointer";
//     return "";
//   }};
//   background-color: ${({ theme, isToday, isSelected }): string => {
//     if (isSelected) return theme.colors.tableHover;
//     if (isToday) return theme.colors.primaryLigth;
//     return "transparent";
//   }};

//   color: ${({ theme, isToday, isSelected }): string => {
//     if (isSelected) return theme.colors.surface;
//     if (isToday) return theme.colors.surface;
//     return theme.colors.onSurface;
//   }};

//   &:focus,
//   &:hover {
//     border: 2px solid ${({ theme }): string => theme.colors.tableHover};
//   }
// `;
