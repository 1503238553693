import React from "react";
import { Button } from "src/components/Form";

export interface NavigationProps {
  maxRows: number;
  totalRows: number;
  currentPage: number;
  setCurrentPage: any;
}
const ReportTableNavigation: React.FC<NavigationProps> = (
  props: NavigationProps
) => {
  const pages = Math.ceil(props.totalRows / props.maxRows);

  const goToFirstPage: () => void = () => {
    props.setCurrentPage(1);
  };

  const goToLastPage: () => void = () => {
    props.setCurrentPage(pages);
  };

  const goToPrevPage: () => void = () => {
    props.setCurrentPage(props.currentPage - 1);
  };

  const goToNextPage: () => void = () => {
    props.setCurrentPage(props.currentPage + 1);
  };

  const goToPage: (pageNumber: number) => void = (pageNumber: number) => {
    props.setCurrentPage(pageNumber);
  };

  return (
    <React.Fragment>
      <Button
        text="<<"
        btn={props.currentPage === 1 ? "disabled" : "secondary"}
        onClick={goToFirstPage}
        type="button"
        name="goToFirstPage"
      />
      <Button
        text="<"
        btn={props.currentPage === 1 ? "disabled" : "secondary"}
        onClick={goToPrevPage}
        type="button"
        name="back"
      />
      {[...Array(pages)].map((e, i) =>
        (props.currentPage > i - 2 && props.currentPage < i + 4) ||
        (props.currentPage <= 2 && i < 5) ||
        (props.currentPage >= pages - 1 && i > pages - 6) ? (
            <Button
              text={i + 1}
              btn={props.currentPage === i + 1 ? "primary" : "secondary"}
              onClick={() => goToPage(i + 1)}
              type="button"
              name={`goToPage ${i + 1}`}
              key={i}
            />
          ) : null
      )}
      <Button
        text=">"
        btn={props.currentPage === pages ? "disabled" : "secondary"}
        onClick={goToNextPage}
        type="button"
        name="next"
      />
      <Button
        text=">>"
        btn={props.currentPage === pages ? "disabled" : "secondary"}
        onClick={goToLastPage}
        type="button"
        name="goTolastPage"
      />
    </React.Fragment>
  );
};

export default ReportTableNavigation;
