import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const NavbarIcon = styled.img`
  padding-bottom: ${({ theme }): string => theme.spacing.unit(0.5)};
  display: block;
  margin: 0 auto;
  opacity: ${({ theme }): string => theme.colors.menuImageOpacity};
`;

export const NavbarLiStyled = styled.li`
  list-style-type: none;
  padding: ${({ theme }): string => theme.spacing.unit(1)};
  letter-spacing: 0.54px;
  line-height: 24px;
  position: relative;
  max-height: ${({ theme }): string => theme.spacing.unit(4)};

  &:hover {
    background-color: ${({ theme }): string => {
    return theme.colors.alpha(theme.colors.activeMenuLink, 0.2);
  }};
    border-radius: ${({ theme }): string => theme.borderRadiusTables};

  & ${NavbarIcon} {
      opacity: 100%;
    }
`;

const activeClassName = "nav-item-active";
export const NavbarLinkStyled = styled(NavLink).attrs({ activeClassName })`
  text-decoration: none;
  color: ${({ theme }): string => theme.colors.menuLink};
  &.${activeClassName} {
    color: ${({ theme }): string => theme.colors.activeMenuLink};
    font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
    font-weight: ${({ theme }): string => theme.fonts.default.bold.fontWeight};
  }
  &.${activeClassName} ${NavbarIcon} {
    opacity: 100%;
  }
  &.${activeClassName} ${NavbarLiStyled}:before {
    content: "";
    position: absolute;
    left: 0;
    background-color: ${({ theme }): string => theme.colors.surface};
    border-radius: 0 10px 10px 0;
    width: 5px;
    height: 100%;
    margin-top: -1rem;
  }
`;
