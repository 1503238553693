import React, { memo, useMemo } from "react";
import { useTranslate } from "i18n";
import { deepEqual } from "Utils";

import { monthDays, mapHoursSpent, currentMonth } from "Utils";

import { CalendarWrapper, CalendarBody, CalendarTitle } from "./Calendar.style";
import { CalendarDaysHeader, CalendarWeek, CalendarLegend } from "./index";

interface IProps {
  day: number;
  month: number;
  year: number;
  daysStatus: { day: Date; hoursSpent: number }[];
  daysOff: number[];
}

const Calendar: React.FC<any> = ({
  year,
  month,
  day,
  daysStatus,
  daysOff = [6, 0]
}: IProps) => {
  const { t } = useTranslate();
  const weeks = monthDays(year, month);
  const currentDaySelected = new Date(year, month - 1, day);
  currentDaySelected.setHours(0, 0, 0, 0);
  
  return useMemo(
    () =>
      navigator.onLine ? (
        <CalendarWrapper>
          <CalendarBody>
            <CalendarTitle>{t("Wavelop.calendar.title")}</CalendarTitle>
            <CalendarDaysHeader />
            {weeks.map((week, index, array) => {
              return (
                <CalendarWeek
                  key={index}
                  first={index === 0}
                  last={index === array.length - 1}
                  days={week}
                  daysStatus={mapHoursSpent(week, daysStatus)}
                  daysOff={daysOff}
                  selectedDay={currentDaySelected}
                  monthSelected={currentMonth(week, year, month)}
                ></CalendarWeek>
              );
              
            })}
          </CalendarBody>
          <CalendarLegend
            states={[
              { type: "ok", description: t("Wavelop.calendar.legend.ok") },
              { type: "ko", description: t("Wavelop.calendar.legend.ko") },
              {
                type: "future-ok",
                description: t("Wavelop.calendar.legend.futureOk")
              },
              {
                type: "future-ko",
                description: t("Wavelop.calendar.legend.futureKo")
              }
            ]}
          />
        </CalendarWrapper>
      ) : (
        <React.Fragment />
      ),
    [currentDaySelected, daysOff, daysStatus, month, t, weeks, year]
  );
};

const propsAreEqual = (prevProps: any, nextProps: any): boolean => {
  return deepEqual(prevProps, nextProps);
};

export default memo(Calendar, propsAreEqual);
