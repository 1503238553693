import "moment/locale/en-gb";
import "moment/locale/it";
import React from "react";
import { useHistory } from "react-router-dom";
import { fromHoursToTimeString } from "Utils";
import { RowStyled, TableContentStyled } from "./ReportRow.style";

const ReportRow: React.FC<any> = ({ key, data }) => {
  const history = useHistory();

  const getDate = (day: string): string => {
    const myDate = new Date(day);
    return myDate.toLocaleDateString();
  };

  return (
    <React.Fragment>
      <TableContentStyled
        index={key}
        onClick={(): void => history.push(`activities/edit/${data.id}`)}
      >
        <RowStyled>{data.user}</RowStyled>
        <RowStyled>{data.client}</RowStyled>
        <RowStyled>{data.project}</RowStyled>
        <RowStyled>{data.task}</RowStyled>
        <RowStyled>{`${fromHoursToTimeString(data.time)}`}</RowStyled>
        <RowStyled>{getDate(data.day)}</RowStyled>
      </TableContentStyled>
    </React.Fragment>
  );
};

export default ReportRow;
