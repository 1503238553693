export enum Pages {
  NEW_PROJECT = "newProject",
  ATTIVITA = "Attività", // Assuming "Attività" is the intended value
  REPORTS = "Reports",
  PROGETTI = "Progetti",
  CLIENTI = "Clienti",
  TEAM = "Team",
  WORKSPACE_SETTING = "WorkspaceSetting"
}

export enum UserRole {
  ADMIN = "admin",
  TEAM = "team"
}
