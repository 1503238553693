import { config } from "Config";
import { CrudType, IActivity } from "Models";
import { Crud as crudService } from "Services";
import { Utils as utilsService } from "Utils";

const create = async (body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.activities.create,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createBulk = async (body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.activities.createBulk,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const count = async (queryparams: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.activities.count,
      null,
      queryparams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const read = async (id: string, queryparams, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.activities.read, { id }),
      null,
      queryparams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readByClient = async (token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.activities.readByClient,
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCSV = async (body: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.activities.csv,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getPDF = async (body: any, token: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.activities.pdf,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const update = async (id: string, body: any, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      utilsService.apiPlaceholderReplace(config.api.activities.update, { id }),
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const remove = async (id: string, token?: any): Promise<any> => {
  try {
    return await crudService(
      CrudType.Delete,
      utilsService.apiPlaceholderReplace(config.api.activities.remove, { id }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getHoursSummary = async (queryparams: any, token?: any): Promise<any> => {
  try {
    const result = await crudService(
      CrudType.Get,
      config.api.activities.getHoursSummary,
      null,
      queryparams,
      token
    );
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ActivityService: IActivity = {
  create,
  createBulk,
  count,
  read,
  getCSV,
  getPDF,
  update,
  delete: remove,
  readByClient,
  getHoursSummary
};
