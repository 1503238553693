import React from "react";
import { DateSelectorStyled, ButtonStyled } from "./DateRangeSelector.style";
import { useTranslate } from "i18n";
import { useActivityState } from "Services";
import { getCurrentRange, getContextView } from "Utils";
import moment from "moment";

const DateRangeSelector: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { t } = useTranslate();
  const { dispatch } = useActivityState();

  const handleClick = (i: number): void => {
    setActiveIndex(i);
    const dates: Date[] = getCurrentRange(
      i,
      new Date(),
      moment.locale(t("Wavelop.application.locale"))
    );
    dispatch({
      type: "CHANGE_VIEW",
      from: dates[0],
      to: dates[1],
      view: getContextView(i)
    });
  };

  return (
    <DateSelectorStyled>
      <ButtonStyled
        index={0}
        btn={activeIndex === 0 ? "primary" : ""}
        name="day"
        text={t("Wavelop.application.filter.dayButton")}
        type="button"
        onClick={(): void => handleClick(0)}
        activeIndex={activeIndex}
      />
      <ButtonStyled
        index={1}
        btn={activeIndex === 1 ? "primary" : ""}
        name="week"
        text={t("Wavelop.application.filter.weekButton")}
        type="button"
        onClick={(): void => handleClick(1)}
        activeIndex={activeIndex}
      />
      <ButtonStyled
        index={2}
        btn={activeIndex === 2 ? "primary" : ""}
        name="month"
        text={t("Wavelop.application.filter.monthButton")}
        type="button"
        onClick={(): void => handleClick(2)}
        activeIndex={activeIndex}
      />
    </DateSelectorStyled>
  );
};

export default DateRangeSelector;
