import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

import { IUser } from "Models";
import { LogoutImage } from "Assets";

import {
  MenuProfileStyled,
  ImageProfile,
  ProfileInformation,
  DefaultImageProfile,
  FullName,
  Logout,
  ImageLogout
} from "./MenuProfile.style";

interface IProps {
  user: IUser | { [key: string]: any } | null | undefined;
}

const MenuProfile: React.FC<IProps> = ({ user }: IProps) => {
  const { logout } = useAuth0();
  const history = useHistory();
  const [on, setOn] = useState(navigator.onLine);

  return (
    <MenuProfileStyled>
      <ProfileInformation onClick={(): any => history.push("/profile")}>
        {user?.picture ? (
          <ImageProfile src={user?.picture} referrerPolicy="no-referrer" />
        ) : (
          <DefaultImageProfile>
            {user?.firstName.charAt(0)}
            {user?.lastName.charAt(0)}
          </DefaultImageProfile>
        )}
        <FullName>{user?.fullName}</FullName>
      </ProfileInformation>
      <Logout>
        <ImageLogout
          src={LogoutImage}
          onMouseEnter={(): any =>
            navigator.onLine ? setOn(true) : setOn(false)
          }
          online={on}
          onClick={(): any =>
            navigator.onLine && logout({ returnTo: window.location.origin })
          }
        />
      </Logout>
    </MenuProfileStyled>
  );
};

export default MenuProfile;
