import React from "react";
import {
  Label,
  Input,
  Switch,
  SwitchSectionStyled,
  SwitchLabelStyled,
  SwitchText,
  SpanInput
} from "./Switch.style";
import { useTranslate } from "i18n";
interface IProps {
  htmlFor: string;
  inputLabel: string;
  onChange: (event: any) => void;
  showErrorOnInput: boolean;
  type: string[];
  value?: boolean;
}
const ToggleSwitch: React.FC<any> = ({
  htmlFor,
  inputLabel,
  onChange,
  type,
  value
}: IProps) => {
  const { t } = useTranslate();
  return (
    <SwitchSectionStyled>
      {inputLabel && (
        <SwitchLabelStyled htmlFor={htmlFor}>{inputLabel}</SwitchLabelStyled>
      )}
      <Label>
        {type && type[0] === "icon" && (
          <SpanInput type={type[0]}>
            <img src={type[1]} alt="" />
          </SpanInput>
        )}
        <SwitchText>
          {value
            ? t("Wavelop.application.userForm.active")
            : t("Wavelop.application.userForm.inactive")}
        </SwitchText>
        <Input
          checked={value || false}
          type="checkbox"
          onChange={(event: any): void => {
            const target = event.target.checked;
            onChange({ target: { value: target } });
          }}
        />
        <Switch />
      </Label>
    </SwitchSectionStyled>
  );
};

export default ToggleSwitch;
