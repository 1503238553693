import React, { useEffect, useRef, useState } from "react";
import SelectListItem from "../SelectListItem/SelectListItem";
import { DropdownBody, DropdownList } from "./SelectList.style";

interface IProps {
  closeCallback: () => void;
  options: Array<{
    value: string;
    name: string;
    fatherId: string;
  }>;
  onOptionClicked: (value: string, text: string) => void;
  selectedValue: string | boolean;
  type: string;
}

const SelectList: React.FC<IProps> = ({
  closeCallback,
  options,
  onOptionClicked,
  selectedValue,
  type
}: IProps) => {
  const inputEl = useRef({ focus: () => {} });
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = (e: any): void => {
    if (e.keyCode === 38) {
      focusIndex !== 0 && setFocusIndex(focusIndex - 1);
    }
    if (e.keyCode === 40) {
      focusIndex !== options.length - 1 && setFocusIndex(focusIndex + 1);
    }
    if (e.keyCode === 13) {
      const { value, name } = options[focusIndex];
      onOptionClicked(value, name);
    }

    if (e.keyCode === 9) {
      closeCallback();
    }
  };

  useEffect(() => {
    inputEl.current.focus();
  });

  return (
    <DropdownBody type={type}>
      <DropdownList onKeyDown={onKeyDown} ref={inputEl} tabIndex="0">
        {options !== undefined &&
          options.map((option, index) => (
            <SelectListItem
              type={type}
              onOptionClicked={onOptionClicked}
              option={option}
              key={index}
              selectedValue={selectedValue === option.value}
              focus={
                selectedValue === option.value ||
                (!selectedValue && index === 0 && focusIndex === 0) ||
                index === focusIndex
              }
            />
          ))}
      </DropdownList>
    </DropdownBody>
  );
};

export default SelectList;
