import React, { useCallback, useEffect, useState } from "react";
import { useLoadingState, useLoading } from "Services";
import { Button } from "Components";
import { successToast, ServiceWorker } from "Utils";
import { useTranslate } from "i18n";
import { ToastTitle } from "./SWWrapper.style";

const SWWrapper: React.FC = () => {
  const dispatchLoadingState = useLoadingState().dispatch;
  const { endLoading } = useLoading();
  const { t } = useTranslate();
  const [newVersionAvailable, setnewVersionAvailable] = useState(false);
  const [waitingWorker, setwaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  const onServiceWorkerUpdate = (registration): void => {
    registration && setwaitingWorker(registration.waiting);
    setnewVersionAvailable(true);
  };

  const updateServiceWorker = useCallback((): void => {
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setnewVersionAvailable(false);
    window.location.reload();
  }, [waitingWorker]);

  const refreshAction = useCallback((): JSX.Element => {
    return (
      <React.Fragment>
        <ToastTitle>{t("Wavelop.application.toast.newVersion")}</ToastTitle>
        <Button
          onClick={updateServiceWorker}
          text={t("Wavelop.application.toast.reloadBtn")}
          type={"button"}
          name={"reloadBtn"}
          btn={"primary"}
        />
      </React.Fragment>
    );
  }, [t, updateServiceWorker]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ServiceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
    endLoading(dispatchLoadingState);
    if (newVersionAvailable) {
      successToast(refreshAction, { autoClose: false });
    }
  }, [dispatchLoadingState, endLoading, newVersionAvailable, refreshAction]);

  return <React.Fragment />;
};

export default SWWrapper;
