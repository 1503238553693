import styled from "styled-components";

export const LayoutStyled = styled.div`
  font-family: ${({ theme }): string => theme.fonts.default.regular.fontFamily};
  font-weight: ${({ theme }): string => theme.fonts.default.regular.fontWeight};
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.sidebarBackgorund};
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Main = styled.main`
  background-color: ${({ theme }): string => theme.colors.surface};
  padding: ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.borderRadiusContent};
  flex: 4;
  border-radius: ${({ theme }): string => theme.borderRadiusContent};
  margin-left: -${({ theme }): string => theme.borderRadiusContent};
  z-index: 1;
  overflow: auto;
  @media (max-width: 1024px) {
    margin-left: 0px;
    padding: 0;
  }
`;

export const MobileHeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;
