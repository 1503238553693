import { DeleteIcon } from "Assets";
import { useTranslate } from "i18n";
import React from "react";

import {
  TableContentStyled,
  RowStyled,
  ButtonStyled
} from "./GroupMemberRow.style";
import { Switch } from "@material-ui/core";

export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  onSwitch?: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
}

const GroupMemberRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  onSwitch,
  data,
  type
}: IProps) => {
  const { t } = useTranslate();

  const onDeleteButton = (event): void => {
    onDelete(id);
    event.stopPropagation();
  };

  const onSwitchButton = (): void => {
    if (onSwitch) onSwitch(id);
  };
  console.log(data);
  return (
    <TableContentStyled index={index}>
      <RowStyled>{data.name}</RowStyled>
      <RowStyled>{data.email}</RowStyled>
      <RowStyled>
        <Switch
          checked={data.isManager}
          onClick={onSwitchButton}
          color="primary"
        ></Switch>
      </RowStyled>
      <RowStyled>
        <ButtonStyled
          onClick={onDeleteButton}
          type="image"
          src={DeleteIcon}
          alt={t("Wavelop.application.table.altDelete")}
        />
      </RowStyled>
    </TableContentStyled>
  );
};

export default GroupMemberRow;
