import React, { useEffect, useContext, useState } from "react";
import {
  ActualTimeSelectorStyled,
  ButtonStyled,
  ActualTimeSpan
} from "./ActualTimeSelector.style";
import { NextIcon, PrevIcon } from "Assets";
import { useTranslate } from "i18n";
import { ActivityContext, useActivityState } from "Services";
import { getActualTimeComponentLabels, setActualTime } from "Utils";

const ActualTimeSelector: React.FC = () => {
  const [labels, setLabels] = useState<string[]>([]);
  const { t } = useTranslate();
  const activityContext = useContext(ActivityContext);
  const { dispatch } = useActivityState();

  useEffect(() => {
    setLabels(
      getActualTimeComponentLabels(
        activityContext.state.view,
        activityContext.state.from,
        activityContext.state.to
      )
    );
  }, [
    activityContext.state.from,
    activityContext.state.to,
    activityContext.state.view
  ]);

  const handleClick = (type: string): void => {
    const { from, to, view } = activityContext.state;
    const range = setActualTime(type, view, from, to);
    dispatch({
      type: "CHANGE_RANGE",
      from: range[0],
      to: range[1]
    });
  };

  return (
    <ActualTimeSelectorStyled>
      <ButtonStyled
        btn="secondary"
        ImageLeft={PrevIcon}
        alt={t("Wavelop.application.filter.prevIconAlt")}
        name="prev"
        text={labels[0]}
        type="button"
        onClick={(): void => handleClick("prev")}
      />
      <ActualTimeSpan>{labels[1]}</ActualTimeSpan>
      <ButtonStyled
        btn="secondary"
        ImageRight={NextIcon}
        alt={t("Wavelop.application.filter.nextIconAlt")}
        name="next"
        text={labels[2]}
        type="button"
        onClick={(): void => handleClick("next")}
      />
    </ActualTimeSelectorStyled>
  );
};

export default ActualTimeSelector;
