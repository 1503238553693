import styled from "styled-components";

export const TableStyled = styled.div`
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.tableBackground};
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  color: ${({ theme }): string => theme.colors.headerColor};
  border-radius: ${({ theme }): string => theme.borderRadiusTables};
  text-align: left;
  padding-top: ${({ theme }): string => theme.spacing.unit(0.25)};
  display: flex;
  flex-flow: column nowrap;
  @media (max-width: 768px) {
    flex-flow: column wrap;
    width: -moz-available;
    width: -webkit-fill-available;
  }
`;

export const HeadStyled = styled.div`
  padding: 0 ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(0.25)}
    ${({ theme }): string => theme.spacing.unit(2)};
  font-weight: bold;
  display: flex;
  font-size: ${({ theme }): string => theme.fontSize.unit(1.5)};
  @media (max-width: 768px) {
  }
`;

export const HeadTitle = styled.span`
  padding-right: ${({ theme }): string => theme.spacing.unit(0.5)};
  max-width: ${({ theme }): string => theme.spacing.unit(10)};
  flex: 1;
  &:nth-child(3) {
    max-width: initial;
    flex: 1.2;
  }
  ${({ theme, type }): string => {
    if (type === "activities") return "&:nth-child(5),";
    if (type === "clients") return "&:nth-child(6),";
    if (type === "team")
      return `
      &:nth-child(4){margin-right: ${theme.spacing.unit(4.0)};
    } 
      &:nth-child(5){margin-right: ${theme.spacing.unit(-2.8)};} 
      &:nth-child(6){margin-right: ${theme.spacing.unit(
    -0.6
  )}; text-align: center;}

      @media (max-width: 1024px) {
        &:nth-child(3){max-width: 220px;}
        &:nth-child(4){
          padding-right: ${theme.spacing.unit(4.0)} !important;
        }
        &:last-child{
          margin-right: ${theme.spacing.unit(-0.6)};
        }
    }
      @media (max-width: 768px) {
        &:last-child{
          margin-left: auto;      
         min-width:auto !important; 
        }
        }
      `;
    if (type === "projectTeam")
      return `&:nth-child(1) { max-width: ${theme.spacing.unit(15)};}
        &:nth-child(2) { max-width: none;}
        &:nth-child(3) { 
          flex: 0;
          margin-left: auto;
          min-width: ${theme.spacing.unit(7)};
          max-width: none;
        }
        @media (max-width: 1024px) {
          &:last-child{
            margin-right: ${theme.spacing.unit(-0.6)};
            min-width:auto !important; 
        }
      }
        `;

    if (type === "tasks")
      return `&:nth-child(0) { max-width: ${theme.spacing.unit(10)};}
        &:nth-child(1) { max-width: none;}`;
    if (type === "groups" || type === "userGroups")
      return `&:nth-child(1) { max-width: ${theme.spacing.unit(15)};}
      &:nth-child(2) { 
        max-width: none;
        padding-right:4rem;
      }
      
      `;
    if (type === "groupMembers")
      return `&:nth-child(1) { max-width: ${theme.spacing.unit(10)};}
      &:nth-child(3) { 
        flex: 0;
        margin-left: auto;
        min-width: ${theme.spacing.unit(4.4)};
        max-width: none;}`;
    if (type === "projects")
      return `@media (max-width: 768px) {
        &:last-child{
          margin-right: ${theme.spacing.unit(-0.6)};
          min-width:auto !important; 
      }
    }`;
    return "";
  }}
  &:nth-child(4) {
    flex: 0;
    padding-right: ${({ theme }): string => theme.spacing.unit(1)};
    min-width: ${({ theme }): string => theme.spacing.unit(5.2)};
  }
  &:last-child {
    flex: 0;
    min-width: ${({ theme }): string => theme.spacing.unit(4.4)};
  }
`;

export const UlTableContent = styled.ul`
@media (max-width: 768px) {
  flex-flow: column wrap;
}
  margin: 0;
  padding: 0;
  background-color: ${({ theme }): string => theme.colors.surface};
  border-radius: ${({ theme }): string => theme.borderRadiusTables};
  font-size: ${({ theme }): string => theme.fontSize.unit(2)};
  position: relative;
  &:before {
  ${(props): string => {
    return props.type === "activities"
      ? `
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-color: 
        ${props.marginColor === "red" ? props.theme.colors.status01 : ""}
        ${props.marginColor === "green" ? props.theme.colors.status02 : ""}
        ${props.marginColor === "yellow" ? props.theme.colors.status03 : ""}
        ${props.marginColor === "blue" ? props.theme.colors.status04 : ""};
      border-radius: ${props.theme.borderRadiusTables} 0 0
        ${props.theme.borderRadiusTables};
      width: ${props.theme.borderRadiusTables};
      height: 100%;
      `
      : "";
  }}
`;
