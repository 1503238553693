import { ModifyIcon, DeleteIcon } from "Assets";
import { useTranslate } from "i18n";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { TableContentStyled, RowStyled, ButtonStyled } from "./GroupRow.style";

export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
  wsRole?: string;
}

const GroupRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  data,
  type,
  wsRole
}: IProps) => {
  const history = useHistory();
  const { t } = useTranslate();

  const onDeleteButton = (event): void => {
    onDelete(id);
    event.stopPropagation();
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <TableContentStyled
      index={index}
      onClick={(): void | null =>
        wsRole === "admin" ? history.push(`groups/edit/${id}`) : null
      }
    >
      <RowStyled>{data.name}</RowStyled>
      <RowStyled>{data.users.length}</RowStyled>
      <RowStyled>
        <Link
          to={`groups/edit/${id}`}
          onClick={(event): void =>
            wsRole === "admin"
              ? event.stopPropagation()
              : event.preventDefault()
          }
        >
          {windowWidth < 768 ? (
            <></>
          ) : (
            <ButtonStyled
              type="image"
              src={ModifyIcon}
              alt={t("Wavelop.application.table.altEdit")}
              disabled={wsRole === "admin" ? false : true}
            />
          )}
        </Link>
        <ButtonStyled
          onClick={onDeleteButton}
          type="image"
          src={DeleteIcon}
          alt={t("Wavelop.application.table.altDelete")}
          disabled={wsRole === "admin" ? false : true}
        />
      </RowStyled>
    </TableContentStyled>
  );
};

export default GroupRow;
