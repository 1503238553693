import { CustomRow, DatePicker, Input } from "Components";

export const form: any = (t: any, icons: any, minDate: any) => {
  return [
    {
      name: "row2",
      tag: "row",
      customRow: CustomRow,
      fields: [
        {
          name: "cell3",
          tag: "row",
          customRow: CustomRow,
          fields: [
            {
              name: "billableRate",
              label: t("Wavelop.application.rateModal.label"),
              type: ["number", "icon"],
              attributes: {
                icon: icons.PriceIcon
              },
              validations: [
                {
                  kind: "required",
                  message: t("Wavelop.error.message.billable.required")
                }
              ],
              tag: Input
            }
          ]
        }
      ]
    },
    {
      name: "row1",
      tag: "row",
      customRow: CustomRow,
      fields: [
        {
          name: "cell1",
          tag: "row",
          customRow: CustomRow,
          fields: [
            {
              name: "startBillableRate",
              dataManipulatorIn: (date: Date): Date => {
                return date ? new Date(date) : new Date();
              },
              label: t("Wavelop.application.rateModal.dateLabel"),
              type: "rate",
              attributes: {
                icon: icons.CalendarIcon,
                minDate: minDate
              },
              tag: DatePicker
            }
          ]
        }
      ]
    }
  ];
};
