export const weekCount = (year: number, month: number): number => {
  const firstOfMonth = new Date(year, month - 1, 1);
  const lastOfMonth = new Date(year, month, 0);

  const used = firstOfMonth.getDay() + 6 + lastOfMonth.getDate();

  return Math.ceil(used / 7);
};

export const monthDays = (year: number, month: number): any => {
  const result: any = [];
  const firstOfMonth = new Date(year, month - 1, 1);
  const lastOfMonth = new Date(year, month, 0);

  let startDate = new Date(year, month - 1, 1);
  let weekCounter = 0;

  firstOfMonth.setHours(0, 0, 0, 0);
  lastOfMonth.setHours(0, 0, 0, 0);
  startDate.setHours(0, 0, 0, 0);
  startDate.setUTCHours(0);
  for (let index = 0; index < lastOfMonth.getDate(); index++) {
    if (startDate.getDay() % 6 === 1 && index !== 0) {
      weekCounter++;
    }
    if (!result[weekCounter]) {
      result[weekCounter] = [];
    }

    result[weekCounter].push({
      dayNumber: startDate.getDay(),
      day: startDate
    });
    
    startDate = new Date(startDate);
    startDate.setDate(startDate.getDate() + 1);
    startDate.setUTCHours(0);
  }
  const firstWeekDimension = result && result[0] && result[0].length;

  if (result[0]?.length !== 7) {
    const oldMonth = new Date(year, month - 1, 0);
    let beforeStartDate = new Date(oldMonth);
    beforeStartDate.setHours(0, 0, 0, 0);
    beforeStartDate.setUTCHours(0);
    for (let index = 0; index < 7 - firstWeekDimension; index++) {
      result[0].unshift({
        dayNumber: new Date(beforeStartDate).getDay(),
        day: beforeStartDate
      });
      beforeStartDate = new Date(beforeStartDate);
      beforeStartDate.setDate(beforeStartDate.getDate() - 1);
    }
  }
  const lastWeekDimension = result[result?.length - 1]?.length;

  if (result[result?.length - 1]?.length !== 7) {
    const newMonth = new Date(lastOfMonth);
    newMonth.setDate(newMonth.getDate() + 1);
    let afterStartDate = new Date(newMonth);
    afterStartDate.setUTCHours(0, 0, 0, 0);

    for (let index = 0; index < 7 - lastWeekDimension; index++) {
      result[weekCounter].push({
        dayNumber: new Date(afterStartDate).getDay(),
        day: afterStartDate
      });
      afterStartDate = new Date(afterStartDate);
      afterStartDate.setDate(afterStartDate.getDate() + 1);
    }
  }
  return result;
};

export const firstDayAndLastDay = (year: number, month: number): any => {
  if (!month || !year) return;
  const borders = monthDays(year, month);
  const firstDay = borders[0][0];
  const lastDayNumber = borders.length - 1;
  const lastDay = borders[lastDayNumber][6];

  return {
    from: firstDay.day,
    to: lastDay.day
  };
};

export const mapHoursSpent = (
  week: { day: Date; dayNumber: number }[],
  tracking: { day: Date; hoursSpent: number }[]
): string[] => {
  const result: string[] = [];
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setMinutes(today.getMinutes() - today.getTimezoneOffset());

  week.forEach(({ day }) => {
    day.setMinutes(day.getMinutes());
    const dayOfWeek = new Date(day.toISOString().slice(0, 10));
    const hoursFound = tracking.find((hs: any) => {
      return dayOfWeek.getTime() === new Date(hs.day).getTime();
    });
    if (hoursFound) {
      const { hoursSpent } = hoursFound;
      if (dayOfWeek.getTime() > today.getTime()) {
        result.push(hoursSpent >= 8 ? "future-ok" : "future-ko");
      } else {
        result.push(hoursSpent >= 8 ? "ok" : "ko");
      }
    } else {
      if (
        new Date(day.toISOString().slice(0, 10)).getTime() <= today.getTime()
      ) {
        result.push("ko");
      } else {
        result.push("");
      }
    }
  });

  return result;
};

export const currentMonth = (
  week: { day: Date; dayNumber: number }[],
  year: number,
  month: number
): boolean[] => {
  const result: boolean[] = [];

  week.forEach(({ day }) => {
    day.setMinutes(day.getMinutes());
    const monthDay = day.getMonth();
    const yearDay = day.getFullYear();

    result.push(!(month === monthDay + 1 && year === yearDay));
  });

  return result;
};
