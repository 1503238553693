import color from "color";
import {
  black,
  white,
  pinkA400,
  green700,
  navbar,
  gray01,
  gray02,
  gray03,
  gray04,
  gray05,
  gray06,
  gray07,
  gray08,
  gray09,
  brand,
  status01,
  status02,
  status03,
  status04
} from "../Utils/colors";
import { configureFonts } from "../Utils/fonts";
import { up, down, only, between, values } from "../Utils/brackpoints";
import { space, unit } from "../Utils/units";

const DefaultTheme = {
  dark: false,
  roundness: space,
  colors: {
    primary: "#6200ee",
    primaryLigth: "#A4ABFF",
    accent: "#03dac4",
    background: "#343534",
    hover: "#d20808",
    border: "#d2d2d2",
    surface: white,
    error: "#d20808",
    text: black,
    onBackground: black,
    onSurface: black,
    resultMessageOK: green700,
    navbarBackgorund: navbar,
    sidebarBackgorund: gray01,
    menuLink: gray05,
    menuImageOpacity: "68.3%",
    activeMenuLink: white,
    navbarText: white,
    clockBackground: gray02,
    clockColor: gray06,
    tableBackground: gray02,
    headerColor: gray03,
    tableHover: brand,
    evenRowTable: gray02,
    tableHoverOpacity: "rgb(102,114,251,0.2)",
    buttonFilterBackground: gray02,
    buttonFilterBackgroundActive: brand,
    buttonResetShadow:
      "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    buttonPrimaryShadow: `0px 0px 1px 0px ${brand}, 0px 1px 1px 0px ${brand}, 0px 1px 3px 0px ${brand}`,
    buttonResetShadowDarker:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    buttonPrimaryShadowDarker: `0px 0px 1px 0px ${color(brand)
      .darken(0.1)
      .string()}, 0px 1px 1px 0px ${color(brand)
      .darken(0.1)
      .string()}, 0px 1px 5px 0px ${color(brand)
      .darken(0.1)
      .string()}`,
    buttonSecondaryShadowDarker: `0px 0px 0px 0px ${color(brand)
      .darken(0.1)
      .string()}, 0px 0px 0px 0px ${color(brand)
      .darken(0.1)
      .string()}, 0px 0px 0px 1px ${color(brand)
      .darken(0.1)
      .string()}`,
    actualTimeFilterBorder: "1px solid #4D4D4D",
    quickInsertionBackground: gray02,
    datePickerBackground: "rgb(59,114,226,0.15)",
    datePickerHourInputBackground: "rgb(59,114,226,0.0)",
    dropdownHover: gray03 + "29",
    dropdownSelected: gray03 + "40",
    dropdownTextColor: gray08,
    inputPlaceholderColor: gray03,
    secondaryButtonBorder: "#6200ee",
    secondaryButtonText: brand,
    disabledButtonText: gray03,
    resetButtonBackground: gray09,
    alertListColor: gray03,
    toastTitleColor: gray04,
    toastBodyColor: gray03,
    status01Transparence: "#FBE2DF",
    status02Transparence: "#DBF6EC",
    noActivityTitle: "#8b8e91",
    inputIconBackground: gray07,
    status01,
    status02,
    status03,
    status04,
    disabled: color(black)
      .alpha(0.05)
      .rgb()
      .string(),
    textDisabled: color(black)
      .alpha(0.15)
      .rgb()
      .string(),
    readOnly: color(black)
      .alpha(0.05)
      .rgb()
      .string(),
    placeholder: color(black)
      .alpha(0.54)
      .rgb()
      .string(),
    backdrop: color(black)
      .alpha(0.5)
      .rgb()
      .string(),
    notification: pinkA400,
    darken: (c: string, d = 0.1): string => {
      return color(c)
        .darken(d)
        .string();
    },
    alpha: (c: string, a = 0.1, m = ""): string => {
      let cl = color(c);
      if (m !== "") {
        cl = cl.mix(color(m));
      }

      return color(cl.alpha(a)).string();
    }
  },
  fonts: configureFonts(),
  animation: {
    scale: 1.0
  },
  spacing: {
    unit: (molt = 1): string => `${space * 4 * molt}${unit}`
  },
  fontSize: {
    unit: (molt = 1): string => `${space * 2 * molt}${unit}`
  },
  breakpoints: {
    values,
    up,
    down,
    only,
    between
  },
  borderRadiusContent: "40px",
  borderRadiusTables: "12px",
  borderRadiusFilter: "12px",
  borderRadiusQuickInsertion: "20px",
  borderRadiusAlert: "4px",
  borderRadiusQuickInsertionSelect: "15px",
  borderRadiusQuickInsertionSelectIcon: "13px",
  dateSelectorMaxWidth: "200px",
  dateSelectorMinWidth: "170px",
  toastShadow: "0px 4px 8px #8D8D9447",
  selectListShadow: "0px 2px 24px #0000001a"
};

export default DefaultTheme;
