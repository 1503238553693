import styled from "styled-components";

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }): string => theme.colors.primary};
  &.display-block {
    display: block;
  }
  &.display-none {
    display: none;
  }
`;

export const Main = styled.div`
  position: fixed;
  background: white;
  padding: 40px;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
