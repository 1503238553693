import React, { useEffect, useState } from "react";
import {
  TableContentStyled,
  RowStyled,
  DivStyled,
  SmallSpanStyled,
  ButtonStyled,
  SmallString,
  Icon
} from "./ActivityRow.style";
import { formatDateRow } from "Utils";
import {
  DeleteIcon,
  ModifyIcon,
  CopyIcon,
  MiniClockIcon,
  MiniClientIcon
} from "Assets";
import { useTranslate } from "i18n";
import { Link, useHistory } from "react-router-dom";
import "moment/locale/it";
import "moment/locale/en-gb";
import { fromHoursToTime, formatWorkplace } from "Utils";
import { ActivityReference } from "./ActivityReference/ActivityReference";

export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  onCopy: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
}

const ActivityRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  onCopy,
  data,
  type
}: IProps) => {
  const { t } = useTranslate();
  const history = useHistory();

  const onDeleteButton = (event): void => {
    onDelete(id);
    event.stopPropagation();
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <TableContentStyled
      index={index}
      onClick={(): void => history.push(`activities/edit/${id}`)}
    >
      <RowStyled>
        <DivStyled>{data.task}</DivStyled>
        <SmallSpanStyled>
          <Icon
            src={MiniClockIcon}
            alt={t("Wavelop.application.table.altClock")}
          />
          <SmallString>
            {formatDateRow(data.day, t("Wavelop.application.locale"))}
          </SmallString>
        </SmallSpanStyled>
      </RowStyled>
      <RowStyled>
        <DivStyled>{data.project.name}</DivStyled>
        <SmallSpanStyled>
          <Icon
            src={MiniClientIcon}
            alt={t("Wavelop.application.table.altClient")}
          />
          <SmallString>{data.project.client.name}</SmallString>
        </SmallSpanStyled>
      </RowStyled>
      {windowWidth < 768 ? (
        <RowStyled>
          <ButtonStyled
            onClick={onDeleteButton}
            type="image"
            src={DeleteIcon}
            alt={t("Wavelop.application.table.altDelete")}
          />
        </RowStyled>
      ) : (
        <>
          <RowStyled>
            <DivStyled>{data.description}</DivStyled>
            {data.reference && (
              <SmallSpanStyled>
                <ActivityReference
                  data={data.metadata || {}}
                  reference={data.reference}
                />
              </SmallSpanStyled>
            )}
          </RowStyled>
          <RowStyled>{t(formatWorkplace(data.workplace))}</RowStyled>
          <RowStyled>
            {fromHoursToTime(data.work)[0] > 0
              ? fromHoursToTime(data.work)[0].toString() + "h "
              : ""}
            {fromHoursToTime(data.work)[1]
              ? fromHoursToTime(data.work)[1].toString() + "m"
              : ""}
          </RowStyled>
          <RowStyled>
            <Link
              to={`activities/edit/${id}`}
              onClick={(event): void => event.stopPropagation()}
            >
              <ButtonStyled
                type="image"
                src={ModifyIcon}
                alt={t("Wavelop.application.table.altEdit")}
              />
            </Link>
            {type === "activities" && (
              <ButtonStyled
                onClick={(): void => onCopy(id)}
                type="image"
                src={CopyIcon}
                alt={t("Wavelop.application.table.altCopy")}
              />
            )}
            <ButtonStyled
              onClick={onDeleteButton}
              type="image"
              src={DeleteIcon}
              alt={t("Wavelop.application.table.altDelete")}
            />
          </RowStyled>
        </>
      )}
    </TableContentStyled>
  );
};

export default ActivityRow;
