import styled from "styled-components";
import { Button } from "Components";

export const ButtonStyled = styled(Button)`
  background-color: ${({ theme }): string => theme.colors.surface};
  color: ${({ theme }): string => theme.colors.onSurface};
  border: ${({ theme }): string => theme.colors.actualTimeFilterBorder};
  &:focus {
    border: ${({ theme }): string => theme.colors.actualTimeFilterBorder};
  }
  width: auto;
  margin: 0;
`;

export const ActualTimeSelectorStyled = styled.div`
  background-color: ${({ theme }): string => theme.colors.sidebarBackgorund};
`;

export const ActualTimeSpan = styled.span`
  background-color: ${({ theme }): string => theme.colors.sidebarBackgorund};
  flex: 2;
  text-align: center;
  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(0.5)};
`;
