import styled from "styled-components";

export const TextAreaSectionStyled = styled.section`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TextAreaContainer = styled.div`
  position: relative;
  display: flex;
`;

export const SpanTextArea = styled.span`
  ${({ styleType, theme }): string =>
    styleType === "icon"
      ? `
  position: absolute;
  left: ${theme.spacing.unit(0.375)};
  top: ${theme.spacing.unit(0.375)};
  border-radius: ${theme.borderRadiusQuickInsertionSelectIcon};
  background-color: ${theme.colors.inputIconBackground}29;
  height: calc(100% - ${theme.spacing.unit(0.75)});
  width: ${theme.spacing.unit(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: ${theme.spacing.unit(1)};
    max-height: ${theme.spacing.unit(1)};
  }
  `
      : ""}

  img {
    max-width: ${({ theme }): string => theme.spacing.unit(1.25)};
  }
`;

export const TextAreaStyled = styled.textarea`
  transition: 0.3s;

  flex: 1;

  font-family: ${({ theme }): string => theme.fonts.default.regular.fontFamily};

  font-size: ${({ theme }): string => theme.fontSize.unit(2)};

  border-radius: ${({ theme }): string =>
    theme.borderRadiusQuickInsertionSelect};

  background-color: ${({ theme, readOnly, disabled }): string => {
    let result = theme.colors.surface;
    result = readOnly ? theme.colors.readOnly : result;
    result = disabled ? theme.colors.disabled : result;
    return result;
  }};

  padding: ${({ theme, styleType }): string =>
    styleType !== "icon"
      ? `${theme.spacing.unit(0.75)} ${theme.spacing.unit(1)}`
      : `${theme.spacing.unit(0.75)} ${theme.spacing.unit(
        1
      )} ${theme.spacing.unit(0.75)} ${theme.spacing.unit(3.5)}`};

  margin: ${({ theme }): string => theme.spacing.unit(0.25)}
    ${({ theme }): string => theme.spacing.unit(0.25)};

  border: ${({ theme, error, readOnly, disabled, styleType }): string => {
    let result = "2px solid " + theme.colors.surface;
    result =
      styleType === "icon"
        ? "2px solid " + theme.colors.inputIconBackground + "29"
        : result;
    result = readOnly ? "2px solid " + theme.colors.readOnly : result;
    result = disabled ? "2px solid " + theme.colors.disabled : result;
    result =
      error?.length > 0 || error === true
        ? "2px solid " + theme.colors.status01
        : result;
    return result;
  }};

  cursor: ${({ readOnly, disabled }): string => {
    let result = "pointer";
    result = readOnly ? "initial" : result;
    result = disabled ? "not-allowed" : result;
    return result;
  }};

  &:focus,
  &:hover {
    outline: none;
    border: ${({ theme, error, readOnly, disabled }): string => {
    let result = "2px solid " + theme.colors.primary;
    result = readOnly || disabled ? "inherith" : result;
    result =
        error?.length > 0 || error === true
          ? "2px solid " + theme.colors.status01
          : result;
    return result;
  }};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 4px 3px
      ${({ theme, error, readOnly, disabled }): string => {
    let result = theme.colors.alpha(theme.colors.primary, 0.2);
    result = readOnly ? theme.colors.readOnly : result;
    result = disabled ? theme.colors.disabled : result;
    result =
          error?.length > 0 || error === true
            ? theme.colors.alpha(theme.colors.status01, 0.2)
            : result;
    return result;
  }};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }): string => theme.colors.inputPlaceholderColor};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }): string => theme.colors.inputPlaceholderColor};
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }): string => theme.colors.inputPlaceholderColor};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: ${({ theme }): string => theme.colors.onSurface};
  }
`;

export const TextAreaLabelStyled = styled.label`
  font-size: ${({ theme }): string => theme.fontSize.unit(1.5)};
  margin: 0px 4px;
  padding: 4px 2px 0px;
  color: gray;
`;
