import React, {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useCallback
} from "react";
import {
  DropdownHeader,
  DropdownHeaderSecondary,
  Dropdown,
  SelectLabelStyled,
  SelectSectionStyled,
  SpanSelect
} from "./Select.style";
import SelectList from "../SelectList/SelectList";

interface IProps {
  disabled?: boolean;
  error: boolean;
  htmlFor: string;
  id: string;
  inputLabel: string;
  onBlur: (event: any) => void;
  onChange: (event: any) => void;
  options: Array<{
    value: string;
    name: string;
    fatherId: string;
  }>;
  placeholder: string;
  readOnly?: boolean;
  required?: boolean;
  selectContainer?: any;
  type: string[];
  value: string;
}

const Select: React.FC<any> = ({
  disabled,
  error,
  htmlFor,
  id,
  inputLabel,
  onBlur,
  onChange,
  options,
  placeholder,
  readOnly,
  required,
  selectContainer,
  type,
  value
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const listEl = useRef() as MutableRefObject<HTMLDivElement>;
  const listHeader = useRef() as MutableRefObject<HTMLDivElement>;

  const SelectWrapper = selectContainer || SelectSectionStyled;

  const handleClick = useCallback(
    (e: any): any => {
      if (listEl.current.contains(e.target)) {
        return;
      }
      setIsOpen(false);
    },
    [listEl]
  );

  useEffect(() => {
    if (value === undefined && value !== 0) {
      if (htmlFor === "status")
        onChange({ target: { value: options[0].value } });
      if (options && options.length === 1 && value !== options[0].value)
        onChange({ target: { value: options[0].value } });
      else setSelectedValue(placeholder);
    } else setSelectedValue(value);
    document.addEventListener("click", handleClick);
    return (): any => document.removeEventListener("click", handleClick);
  }, [value, placeholder, handleClick, options, onChange, htmlFor]);

  const toggling = (): void => {
    !disabled && !readOnly && setIsOpen(!isOpen);
  };

  const onOptionClicked = (value: string): void => {
    onChange({ target: { value } });
    onBlur({ target: { value } });
    setSelectedValue(value);
    setIsOpen(false);
    listHeader.current.focus();
  };

  const defaultValue = (): string => {
    if (
      selectedValue === undefined ||
      selectedValue === "" ||
      selectedValue === placeholder
    ) {
      if (options && options.length === 1 && value !== options[0].value)
        return options[0].name;
      else return placeholder;
    } else {
      return (
        (options &&
          options.find(option => option.value === selectedValue)?.name) ||
        placeholder
      );
    }
  };

  const attributes = {
    onClick: toggling,
    onKeyDown: (e: any): void => {
      if (
        e.keyCode === 32 ||
        e.keyCode === 13 ||
        e.keyCode === 38 ||
        e.keyCode === 40
      ) {
        toggling();
      }
      if (e.keyCode === 9) {
        setIsOpen(false);
      }
    },
    id,
    error,
    className: "dropdown-header",
    readOnly,
    disabled,
    tabIndex: !readOnly && !disabled ? "0" : "-1",
    isOpen
  };

  if (required === true) {
    attributes["required"] = "required";
  }

  return (
    <SelectWrapper>
      {inputLabel && (
        <SelectLabelStyled htmlFor={htmlFor}>{inputLabel}</SelectLabelStyled>
      )}

      <Dropdown ref={listEl}>
        {(type[0] === "primary" ||
          type[0] === "icon" ||
          type[0] === "team") && (
          <DropdownHeader type={type[0]} ref={listHeader} {...attributes}>
            {type[0] === "icon" && (
              <SpanSelect type={type[0]}>
                <img src={type[1]} alt="" />
              </SpanSelect>
            )}
            {defaultValue()}
          </DropdownHeader>
        )}
        {type[0] === "secondary" && (
          <DropdownHeaderSecondary ref={listHeader} {...attributes}>
            {defaultValue()}
          </DropdownHeaderSecondary>
        )}
        {isOpen && (
          <div>
            <SelectList
              type={type[0]}
              closeCallback={(): void => {
                setIsOpen(false);
              }}
              options={options}
              onOptionClicked={onOptionClicked}
              selectedValue={
                selectedValue === placeholder ? false : selectedValue
              }
            />
          </div>
        )}
      </Dropdown>
    </SelectWrapper>
  );
};

export default Select;
