import { i18nConfig } from "../Config";
import {
  setCurrentLanguage,
  setFallbackLanguage,
  setLanguages
} from "../Translate";
import { LanguageInterface } from "../Models";

const {
  languages: languagesConfig,
  fallBacklanguage: fallBacklanguageConfig
} = i18nConfig;

export const detectBrowserLanguage = (): string =>
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

export const init = (
  languages: string[] = languagesConfig,
  fallBacklanguage: string = fallBacklanguageConfig
): any => {
  setCurrentLanguage(
    languages.indexOf(detectBrowserLanguage().split("-")[0]) !== -1
      ? detectBrowserLanguage().split("-")[0]
      : fallBacklanguage
  );
  setFallbackLanguage(fallBacklanguage);
  setLanguages(languages);
};

export const initialState: LanguageInterface = {
  language:
    languagesConfig.indexOf(detectBrowserLanguage().split("-")[0]) !== -1
      ? detectBrowserLanguage().split("-")[0]
      : fallBacklanguageConfig
};
