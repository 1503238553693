import styled from "styled-components";

export const DropdownRow = styled.li`
  position: relative;
  padding: ${({ theme, type }): string =>
    type !== "secondary"
      ? theme.spacing.unit(0.75)
      : theme.spacing.unit(0.25)}
    ${({ theme, type }): string =>
    type !== "secondary" ? theme.spacing.unit(1) : theme.spacing.unit(0)};
  list-style: none;
  cursor: pointer;

  background-color: ${({ theme, type, selectedValue, focus }): string => {
    let result = "inherith";
    result = focus && type !== "team" ? theme.colors.dropdownHover : result;
    result = selectedValue ? theme.colors.dropdownSelected : result;
    return result;
  }};

  pointer-events: ${({ type, selectedValue }): string => {
    return selectedValue && type === "team" ? "none" : "inherith";
  }};

  &:hover {
    background-color: ${({ theme, selectedValue }): string => {
    const result = selectedValue
      ? theme.colors.dropdownSelected
      : theme.colors.dropdownHover;
    return result;
  }};

    &:focus {
      border: 1px solid red;
    }
  }
`;
