import React, { useMemo, useCallback, useEffect, useState } from "react";
import { SidebarStyled, ButtonStyled } from "./Sidebar.style";
import {
  useAppState,
  useActivityState,
  Activity as activityService,
  useUserState
} from "Services";
import { Filter, Calendar, MenuProfile } from "Components";
import {
  newButtonPath,
  backButtonPath,
  switchTabButtonPath,
  firstDayAndLastDay,
  splitEditTitle,
  splitTitle
} from "Utils";
import { useHistory } from "react-router";
import { useTranslate } from "i18n";
import Guard from "../Guard/Guard";
import { Pages } from "src/models/Enums/Enums";

const useFetchRenderLess = (): any => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });
  const { state: appState } = useAppState();
  const { page, title } = appState;
  const { state: userState } = useUserState();
  const { user, token } = userState;
  const { state: activityState } = useActivityState();
  const { from } = activityState;

  const firstDayAndLastDayCallback = useCallback(
    () =>
      firstDayAndLastDay(
        (from || new Date()).getFullYear(),
        ((from && from.getMonth()) || 0) + 1
      ),
    [from]
  );

  const fetchData = useCallback(async () => {
    if (!token) return;
    const result = firstDayAndLastDayCallback();
    const { from: fromFirstDay, to: toLastDay } = result;
    try {
      const result = await activityService.getHoursSummary(
        {
          from: fromFirstDay.toISOString().slice(0, 10),
          to: toLastDay.toISOString().slice(0, 10),
          sort: 1
        },
        token
      );
      if (result && result.items) {
        setState({
          loading: false,
          error: false,
          data: {
            hoursSummary: result.items || [],
            from,
            page,
            title,
            user
          }
        });
      } else {
        setState({ loading: false, error: true, data: { user } });
      }
    } catch (error) {
      setState({ loading: false, error: !!error, data: { user } });
    }
  }, [token, firstDayAndLastDayCallback, from, page, title, user]);

  useEffect(() => {
    fetchData();
  }, [activityState, fetchData]);

  return state;
};

const Sidebar: React.FC = () => {
  const { loading, data } = useFetchRenderLess();
  const { from, hoursSummary, page, title, user } = data;
  const history = useHistory();
  const { t } = useTranslate();

  return useMemo(() => {
    const renderBackButton = (): JSX.Element => {
      return page &&
        (page.includes("/edit") || page.includes("/new")) &&
        !page.includes("/workspace") ? (
          <ButtonStyled
            btn="primary"
            name="backButton"
            text={`${t("Wavelop.application.navbar.backButton")}"${splitEditTitle(
              title
            )}"`}
            onClick={(): void => history.push(backButtonPath(page))}
          />
        ) : (
          <React.Fragment />
        );
    };

    const renderNewButton = (): JSX.Element => {
      let checkTitle = title;
      if (page && page.includes("/workspaces")) {
        checkTitle = t("Wavelop.application.navbar.newButtonWorkspace");
        if (
          page.includes("/workspaces") &&
          !(page.includes("/new") || page.includes("/edit"))
        ) {
          return (
            <ButtonStyled
              btn="primary"
              name="newButton"
              text={`${t("Wavelop.application.navbar.newButton")}"${splitTitle(
                checkTitle
              )}"`}
              onClick={(): void => history.push(newButtonPath(page))}
            />
          );
        }
      }
      return page &&
        (page.includes("/clients") || page.includes("/projects")) &&
        !(page.includes("/new") || page.includes("/edit")) ? (
          <Guard name={Pages.NEW_PROJECT}>
            <ButtonStyled
              btn="primary"
              name="newButton"
              text={`${t("Wavelop.application.navbar.newButton")}"${splitTitle(
                checkTitle
              )}"`}
              onClick={(): void => history.push(newButtonPath(page))}
            />
          </Guard>
        ) : (
          <React.Fragment />
        );
    };

    const renderSwitchTabButton = (): JSX.Element => {
      let temp = "";
      if (page) {
        if (page.includes("/team"))
          temp = t("Wavelop.application.navbar.switchTabButtonGroups");
        if (page.includes("/groups"))
          temp = t("Wavelop.application.navbar.switchTabButtonTeam");
      }

      return page &&
        (page.includes("/team") || page.includes("/groups")) &&
        !(page.includes("/new") || page.includes("/edit")) ? (
          <ButtonStyled
            btn="primary"
            name="switchTabButton"
            text={`${t(
              "Wavelop.application.navbar.switchTabButton"
            )}"${splitTitle(temp)}"`}
            onClick={(): void => history.push(switchTabButtonPath(page))}
          />
        ) : (
          <React.Fragment />
        );
    };

    const renderCalendar = (): JSX.Element => {
      return !loading &&
        page &&
        !page.includes("/workspace/new") &&
        !page.includes("/invite") ? (
          <Calendar
            year={from?.getFullYear()}
            month={((from && from.getMonth()) || 0) + 1}
            day={from?.getDate()}
            daysStatus={hoursSummary}
          />
        ) : (
          <React.Fragment />
        );
    };

    return (
      <SidebarStyled>
        <MenuProfile user={user} />
        {renderBackButton()}
        {renderNewButton()}
        {renderSwitchTabButton()}
        <Filter type={page || ""} />
        {renderCalendar()}
      </SidebarStyled>
    );
  }, [from, history, hoursSummary, loading, page, t, title, user]);
};

export default Sidebar;
