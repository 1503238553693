import { Divider, Menu, MenuItem } from "@material-ui/core";
import { DownIcon } from "Assets";
import { NavbarLink } from "Components";
import { useTranslate } from "i18n";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { User, useUserState } from "Services";
import { RouteType } from "src/models/Components/IComponents";
import { navbarConfig } from "./Navbar.config";
import {
  ButtonStyled,
  IconRight,
  Links,
  NavbarContent,
  NavbarFooter,
  NavbarStyled,
  Title,
  Ul
} from "./Navbar.style";
import Guard from "../Guard/Guard";
import { Pages } from "src/models/Enums/Enums";

interface IProps {
  routeType: RouteType;
  data?: any;
}

const Navbar: React.FC<IProps> = ({ routeType, data }: IProps) => {
  const { state, dispatch: dispatchUser } = useUserState();
  const { user, token } = state;
  let role = user?.workspaces.find(item => item.id === user?.currentWorkspace)
    ?.role;
  const { t } = useTranslate();
  const [links, setLinks] = useState(
    navbarConfig(routeType, t, user?.currentWorkspace, role)
  );
  useEffect(() => {
    role = user?.workspaces.find(item => item.id === user?.currentWorkspace)
      ?.role;
    setLinks(navbarConfig(routeType, t, user?.currentWorkspace, role));
  }, [role, data]);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeWorkspace = async (workspaceId: string): Promise<any> => {
    try {
      const user = await User.updateCurrentWorkspace({ workspaceId }, token);
      dispatchUser({ type: "UPDATE_USER", user: user.item });
      role = user?.item.workspaces.find(
        item => item.id === user?.item.currentWorkspace
      )?.role;
      setLinks(navbarConfig(routeType, t, user?.currentWorkspace, role));
      history.push("/activities");
    } catch (e) {
      console.log(e);
    }
    setAnchorEl(null);
  };

  const getCurrentWorkspace = (): string => {
    if (data && user) {
      const res = data?.find(i => {
        return i.id === user.currentWorkspace;
      });
      return res?.name || "";
    }
    return "";
  };

  const disableCurrentWorkspaceButton = (workspaceId: string): boolean => {
    if (user && user.currentWorkspace === workspaceId) {
      return true;
    }
    return false;
  };

  return (
    <NavbarStyled>
      <NavbarContent>
        <Title>{t("Wavelop.application.navbarName")}</Title>
        {routeType === "private" && (user?.workspaces || []).length > 0 ? (
          <React.Fragment>
            <ButtonStyled btn="secondary" onClick={handleClick}>
              {getCurrentWorkspace()}
              <IconRight>
                <DownIcon />
              </IconRight>
            </ButtonStyled>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              getContentAnchorEl={null}
            >
              <MenuItem href="/workspaces" component="a">
                {t("Wavelop.application.navbar.manageWorkspaces")}
              </MenuItem>
              <Divider />
              {data?.map((item, index) => (
                <MenuItem
                  onClick={() => changeWorkspace(item.id)}
                  key={index}
                  value={item.id}
                  disabled={disableCurrentWorkspaceButton(item.id)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        ) : (
          <span></span>
        )}
        {links?.mainLinks && (
          <Links>
            <Ul>
              {links.mainLinks.map((link, i) => (
                <Guard name={link.text as Pages}>
                  <NavbarLink
                    icon={link.icon}
                    alt={link.alt}
                    to={link.to}
                    text={link.text}
                    key={i}
                  />
                </Guard>
              ))}
            </Ul>
          </Links>
        )}
        {links?.footerLinks && (
          <NavbarFooter>
            <Ul>
              {links?.footerLinks.map((link, i) => (
                <Guard name={Pages.WORKSPACE_SETTING}>
                  <NavbarLink
                    icon={link.icon}
                    alt={link.alt}
                    to={link.to}
                    text={link.text}
                    key={i}
                  />
                </Guard>
              ))}
            </Ul>
          </NavbarFooter>
        )}
      </NavbarContent>
    </NavbarStyled>
  );
};

export default Navbar;
