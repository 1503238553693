import styled from "styled-components";

export const TableContentStyled = styled.li`
  border: 1px solid transparent;

  list-style-type: none;
  display: flex;
  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};
  color: ${({ theme }): string => theme.colors.onSurface};
  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  border-radius: 0;
  &:nth-child(even) {
    background-color: ${({ theme }): string => theme.colors.evenRowTable};
    border: 1px solid ${({ theme }): string => theme.colors.evenRowTable};
  }

  &:nth-child(1) {
    border-radius: ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables} 0 0;
  }

  &:last-child {
    border-radius: 0 0 ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables};
  }
  &:a {
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }): string => theme.colors.tableHoverOpacity};
    border: 1px solid ${({ theme }): string => theme.colors.tableHover};
  }
`;

export const TableContentGroupedOneStyled = styled.li.attrs(props => ({
  className: props.className
}))`
  border: 1px solid transparent;
  display: inline-block;
  list-style-type: none;
  display: flex;
  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};
  color: ${({ theme }): string => theme.colors.onSurface};
  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  border-radius: 0;

  background-color: #ffffff;

  :not(:first-child) {
    border-top: 1px solid #ccc;
  }

  &:nth-child(1) {
    border-radius: ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables} 0 0;
  }

  &:last-child {
    border-radius: 0 0 ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables};
  }
  &:a {
    text-decoration: none;
  }
`;

export const TableContentGroupedTwoStyled = styled.li`
  justify-content: space-between;
  border: 1px solid transparent;
  display: inline-block;
  list-style-type: none;
  display: flex;
  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};
  color: ${({ theme }): string => theme.colors.onSurface};
  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  border-radius: 0;
  background-color: #eff3fb;
  border-top: 1px solid #ccc;
  &:nth-child(1) {
    border-radius: ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables} 0 0;
  }

  &:last-child {
    border-radius: 0 0 ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables};
  }
  &:a {
    text-decoration: none;
  }
`;

export const TableContentGroupedThreeStyled = styled.li`
  justify-content: space-between;
  border: 1px solid transparent;
  display: inline-block;
  list-style-type: none;
  display: flex;
  font-size: ${({ theme }): string => theme.fontSize.unit(1.75)};
  color: ${({ theme }): string => theme.colors.onSurface};
  padding: ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(0.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  border-radius: 0;
  background-color: #dedeee;
  border-top: 1px solid #ccc;
  &:nth-child(1) {
    border-radius: ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables} 0 0;
  }

  &:last-child {
    border-radius: 0 0 ${({ theme }): string => theme.borderRadiusTables}
      ${({ theme }): string => theme.borderRadiusTables};
  }
  &:a {
    text-decoration: none;
  }
`;

export const RowStyled = styled.span`
  word-break: break-all;
  width: ${({ type }): string => (type === "grouped" ? "90%" : "22.5%")};
  &:last-child {
    width: 10%;
  }
`;
