import React, { createContext, useContext, useReducer } from "react";
import {
  LoadingStateInterface,
  LoadingContextInterface,
  LoadingContext,
  ActionType
} from "./Models";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const start = (dispatch): void => {
  dispatch({
    type: "CHANGE_STATE_LOADING",
    isLoading: true
  });
  NProgress.start();
};

const inc = (dispatch): void => {
  NProgress.inc();
};

const end = (dispatch): void => {
  dispatch({
    type: "CHANGE_STATE_LOADING",
    isLoading: false
  });
  NProgress.done();
};

export const LoadingFunContext = createContext<LoadingContext>({
  startLoading: start,
  incrementLoading: inc,
  endLoading: end
});

export const LoadingStateContext = createContext<LoadingContextInterface>({
  state: {
    isLoading: false
  },
  dispatch: () => {}
});

function loadingReducer(
  state: LoadingStateInterface,
  action: ActionType
): LoadingStateInterface {
  const { type, isLoading } = action;
  switch (type) {
    case "CHANGE_STATE_LOADING": {
      return { ...state, isLoading };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = (
  props: Partial<{ children: React.ReactNode }>
) => {
  const [state, dispatch] = useReducer(loadingReducer, {
    isLoading: false
  });

  const values = { state, dispatch };

  const value = {
    startLoading: start,
    incrementLoading: inc,
    endLoading: end
  };

  return (
    <LoadingFunContext.Provider value={value}>
      <LoadingStateContext.Provider value={values}>
        {props.children}
      </LoadingStateContext.Provider>
    </LoadingFunContext.Provider>
  );
};

export const useLoading = (): LoadingContext => {
  const context = useContext(LoadingFunContext);
  if (context === undefined) {
    throw new Error("useTranslate must be used within a TranslateProvider");
  }
  return context;
};

export const useLoadingState = (): {
  state: LoadingStateInterface;
  dispatch: (action: ActionType) => void;
} => {
  const context = useContext(LoadingStateContext);
  if (context === undefined) {
    throw new Error("useLoadingState must be used within an LoadingProvider");
  }
  return context;
};
