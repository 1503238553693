import { Chip } from "@material-ui/core";
import { DownArrowIcon, RightArrowIcon } from "Assets";
import { useTranslate } from "i18n";
import "moment/locale/en-gb";
import "moment/locale/it";
import React, { Fragment, useState } from "react";
import { checkDateIsValidAndFormat, fromHoursToTimeString } from "Utils";
import ReportRowGroupedTwo from "./ReportGroupedTwo";
import { RowStyled, TableContentGroupedOneStyled } from "./ReportRow.style";

const ReportRowGrouped: React.FC<any> = props => {
  const { t } = useTranslate();

  const [showGroupTwo, setShowGroupTwo] = useState(false);

  const groupTwoRows = (item, index, type) => {
    return (
      <ReportRowGroupedTwo
        data={item}
        generalIndex={props.generalIndex}
        key={`${type}${props.generalIndex}${index}`}
      />
    );
  };
  return (
    <Fragment key={`grouping${props.generalIndex}`}>
      <TableContentGroupedOneStyled
        className={"groupOne"}
        onClick={() => setShowGroupTwo(!showGroupTwo)}
      >
        <RowStyled type={"grouped"}>
          {props.data.restOfData[0]._id.name !== "undefined" &&
            (showGroupTwo ? (
              <DownArrowIcon style={{ verticalAlign: "middle" }} />
            ) : (
              <RightArrowIcon style={{ verticalAlign: "middle" }} />
            ))}

          {props.data.restOfData[0]._id.name !== "undefined" && (
            <Chip
              style={{ marginRight: "4px" }}
              size="small"
              label={props.data.restOfData.length}
            />
          )}
          {checkDateIsValidAndFormat(
            props.data.name,
            t("Wavelop.application.locale")
          )}
        </RowStyled>
        <RowStyled type={"grouped"}>{`${fromHoursToTimeString(
          props.data.time
        )}`}</RowStyled>
      </TableContentGroupedOneStyled>
      {showGroupTwo &&
        props.data.restOfData[0]._id.name !== "undefined" &&
        props.data.restOfData.map((item, index) => {
          return groupTwoRows(item, index, "groupTwo");
        })}
    </Fragment>
  );
};

export default ReportRowGrouped;
