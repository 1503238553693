import React from "react";
import { DeleteIcon } from "Assets";
import { useTranslate } from "i18n";
import "moment/locale/it";
import "moment/locale/en-gb";
import { ButtonStyled, RowStyled, TableContentStyled } from "./TaskRow.style";
export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
}

const TaskRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  data,
  type
}: IProps) => {
  const { t } = useTranslate();

  const onDeleteButton = (event): void => {
    onDelete(index.toString());
    event.stopPropagation();
  };
  return (
    <TableContentStyled index={index}>
      <RowStyled>{data}</RowStyled>
      <RowStyled>
        <ButtonStyled
          onClick={onDeleteButton}
          type="image"
          src={DeleteIcon}
          alt={t("Wavelop.application.table.altDelete")}
        />
      </RowStyled>
    </TableContentStyled>
  );
};

export default TaskRow;
