import React, { useContext } from "react";
import { AppContext } from "../../services/Provider/AppProvider/index";
import { Helmet } from "react-helmet-async";

const Layout: React.FC = () => {
  const appContext = useContext(AppContext);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{appContext.state.title}</title>
    </Helmet>
  );
};

export default Layout;
