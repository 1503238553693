import styled from "styled-components";

export const ActivityTableStyled = styled.div`
  margin: ${({ theme }): string => theme.spacing.unit(1)} 0;

  opacity: 0;
  animation-name: animateIn;
  animation-duration: ${({ index }): string => (index !== 0 ? "100ms" : "0ms")};
  animation-delay: ${({ index }): string => 24 * index + "ms"};
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  @keyframes animateIn {
    0% {
      opacity: 0;
      transform: scale(0.85) translateY(-12px);
    }

    100% {
      opacity: 1;
    }
  }
`;

export const TableTitleStyled = styled.h2`
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
  font-weight: ${({ theme }): string => theme.fonts.default.bold.fontWeight};
  margin: ${({ theme }): string => theme.spacing.unit(1.5)} 0
    ${({ theme }): string => theme.spacing.unit(1)} 0;
`;

export const TitleSpan = styled.span`
  float: right;
`;
