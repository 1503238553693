import React from "react";
import {
  ActivityTableStyled,
  TableTitleStyled,
  TitleSpan
} from "./TableAndTitle.style";
import { Table } from "Components";
import { formatDateTitle, formatTotalHours } from "Utils";
import { useTranslate } from "i18n";
import "moment/locale/it";
import "moment/locale/en-gb";

export interface IProps {
  onDelete: (id: string) => void;
  onCopy: (id: string) => void;
  onSwitch?: (id: string) => void;
  day?: string;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
  index?: number;
  wsRole?: string;
}

const ActivityTable: React.FC<IProps> = ({
  day,
  data,
  onDelete,
  onCopy,
  onSwitch,
  index,
  type,
  wsRole
}: IProps) => {
  const { t } = useTranslate();

  return (
    <ActivityTableStyled index={index}>
      {type === "activities" && (
        <TableTitleStyled>
          <span>
            Task {day && formatDateTitle(day, t("Wavelop.application.locale"))}
          </span>
          <TitleSpan>{formatTotalHours(data)}</TitleSpan>
        </TableTitleStyled>
      )}
      <Table
        onDelete={onDelete}
        onCopy={onCopy}
        onSwitch={onSwitch}
        data={data}
        type={type}
        day={day}
        wsRole={wsRole}
      />
    </ActivityTableStyled>
  );
};

export default ActivityTable;
