import React from "react";
import {
  InputStyled,
  InputSectionStyled,
  InputLabelStyled,
  InputContainer,
  SpanInput
} from "./Input.style";

interface IProps {
  attributes?: any;
  disabled?: boolean;
  error: boolean;
  errorMessage: string;
  htmlFor: string;
  id: string;
  inputLabel: string;
  name: string;
  onBlur: (event: any) => void;
  onChange: (event: any) => void;
  placeholder: string;
  readOnly?: boolean;
  required: boolean;
  showErrorOnInput: boolean;
  type: string[];
  value?: string;
}

const Input: React.FC<any> = ({
  attributes,
  disabled,
  error,
  htmlFor,
  id,
  inputLabel,
  name,
  onBlur,
  onChange,
  placeholder,
  readOnly,
  required,
  type,
  value
}: IProps) => {
  return (
    <InputSectionStyled>
      {inputLabel && (
        <InputLabelStyled htmlFor={htmlFor}>{inputLabel}</InputLabelStyled>
      )}
      <InputContainer>
        {type && type[1] === "icon" && (
          <SpanInput styleType={type && type[1]}>
            <img src={attributes?.icon} alt="" />
          </SpanInput>
        )}

        <InputStyled
          disabled={disabled}
          error={error}
          id={id}
          name={name}
          onBlur={(event: any): void => {
            !readOnly && !disabled && onBlur(event);
          }}
          onChange={(event: any): void => {
            !readOnly && !disabled && onChange(event);
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          type={type && type[0]}
          value={value || ""}
          styleType={type && type[1]}
          min={attributes && attributes.min}
          max={attributes && attributes.max}
        />
      </InputContainer>
    </InputSectionStyled>
  );
};

export default Input;
