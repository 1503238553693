import React from "react";
import {
  DatePickerSelector,
  DatePickerStyled,
  DatePickerBlock,
  Input,
  CalendarIcon,
  ClockIcon,
  Label,
  LabelDiv,
  Hour,
  Minutes,
  SelectContainerStyled
} from "./DateSelector.style";
import { Select } from "Components";
import { useTranslate } from "i18n";
import { formatMinutesOptions } from "Utils";
import { CalendarIconDark, MiniClockIcon2 } from "Assets";

export interface IProps {
  date: Date;
  hours: number;
  minutes: number;
  handleDateChange: (newDate: any) => void;
  handleHourChange: (event: any) => void;
  handleMinutesChange: (event: any) => void;
}

const DateSelector: React.FC<IProps> = ({
  date,
  hours,
  minutes,
  handleDateChange,
  handleHourChange,
  handleMinutesChange
}: IProps) => {
  const { t } = useTranslate();

  return (
    <DatePickerStyled>
      <DatePickerBlock>
        <LabelDiv>
          <CalendarIcon src={CalendarIconDark} />
          <Label>
            {t("Wavelop.application.activityForm.datePickerLabel.date")}
          </Label>
          <DatePickerSelector
            type="secondary"
            date={date}
            handleDateChange={handleDateChange}
          />
        </LabelDiv>
      </DatePickerBlock>
      <DatePickerBlock>
        <LabelDiv>
          <ClockIcon src={MiniClockIcon2} />
          <Label>
            {t("Wavelop.application.activityForm.datePickerLabel.hours")}
          </Label>
        </LabelDiv>
        <span>
          <Input
            type="number"
            min="0"
            placeholder="0"
            onChange={handleHourChange}
            value={hours}
          />
          <Hour>h</Hour>
        </span>
        <span>
          <Select
            type={["secondary"]}
            placeholder="00"
            options={formatMinutesOptions()}
            error={false}
            value={minutes}
            onChange={handleMinutesChange}
            onBlur={handleMinutesChange}
            selectContainer={SelectContainerStyled}
          />
          <Minutes>m</Minutes>
        </span>
      </DatePickerBlock>
    </DatePickerStyled>
  );
};

export default DateSelector;
