import React from "react";
import {
  NavbarLinkStyled,
  NavbarLiStyled,
  NavbarIcon
} from "./NavbarLink.style";

export interface IProps {
  icon: string;
  alt: string;
  to: string;
  text: string;
}

const NavbarLink: React.FC<IProps> = ({ icon, alt, to, text }: IProps) => {
  return (
    <NavbarLinkStyled to={to}>
      <NavbarLiStyled>
        <NavbarIcon src={icon} alt={alt} />
        {text}
      </NavbarLiStyled>
    </NavbarLinkStyled>
  );
};

export default NavbarLink;
