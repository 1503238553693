import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import { useTranslate } from "i18n";
import React from "react";

export interface ReportGroupingProps {
  group: string;
  setGroup: any;
  setCurrentPage: any;
  groupOne: string;
  groupTwo: string;
}

const ReportGrouping: React.FC<any> = (props: ReportGroupingProps) => {
  const { t } = useTranslate();
  const groups: string[] = ["none", "project", "user", "client", "task", "day"];
  const handleChange = (event: any) => {
    props.setGroup(event.target.value as string);
    props.setCurrentPage(1);
  };

  const filterGroups = item => {
    if (
      typeof props.groupOne === "string" &&
      typeof props.groupTwo !== "string"
    ) {
      return item !== props.groupOne;
    } else if (
      typeof props.groupOne === "string" &&
      typeof props.groupTwo === "string"
    ) {
      return item !== props.groupOne && item !== props.groupTwo;
    }
    return true;
  };

  const getMenuItems = () => {
    const groupsFiltered = groups.filter(filterGroups);

    return groupsFiltered.map((item, index) => {
      return (
        <MenuItem value={item} key={index}>
          {t(`Wavelop.application.reportsTable.${item}`)}
        </MenuItem>
      );
    });
  };

  return (
    <Box>
      <FormControl
        style={{ minWidth: "100px" }}
        variant={"outlined"}
        size="small"
      >
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          value={props.group}
          input={
            <OutlinedInput
              notched={false}
              style={{ borderRadius: "12px", backgroundColor: "#eff3fb" }}
              label={props.group}
            />
          }
          label="group"
          onChange={handleChange}
        >
          {getMenuItems()}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ReportGrouping;
