import styled from "styled-components";

export const MenuProfileStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: ${({ theme }): string => theme.spacing.unit(1.5)}
    ${({ theme }): string => theme.spacing.unit(2)};
  @media (max-width: 1024px) {
    gap: 1rem;
  }
`;

export const DefaultImageProfile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 130%;
  color: white;
  width: ${({ theme }): string => theme.spacing.unit(4)};
  height: ${({ theme }): string => theme.spacing.unit(4)};
  border-radius: ${({ theme }): string => theme.spacing.unit(4)};
  margin-right: ${({ theme }): string => theme.spacing.unit(1.5)};
  border: 2px solid transparent;
  transition: 200ms;
  background-color: #6672fb;
`;

export const ImageProfile = styled.img`
  width: ${({ theme }): string => theme.spacing.unit(4)};
  border-radius: ${({ theme }): string => theme.spacing.unit(4)};
  margin-right: ${({ theme }): string => theme.spacing.unit(1.5)};
  border: 2px solid transparent;
  opacity: 80%;
  transition: 200ms;
`;

export const FullName = styled.h3`
  font-weight: bold;
  border-bottom: 2px solid transparent;
  transition: 200ms;
`;

export const Logout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
`;

export const ImageLogout = styled.img`
  width: ${({ theme }): string => theme.spacing.unit(1.5)};
  opacity: ${({ theme }): string => theme.colors.menuImageOpacity};
  transition: 200ms;
  ${({ online }): string => {
    return online ? "cursor: pointer;" : "cursor: not-allowed;";
  }};
  &:hover {
    opacity: 100%;
  }};
`;

export const ProfileInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover ${ImageProfile} {
    opacity: 100%;
    border: 2px solid ${({ theme }): string => theme.colors.primary};
  }

  &:hover ${FullName} {
    border-bottom: 2px solid
      ${({ theme }): string => theme.colors.alpha(theme.colors.primary, 0.6)};
    color: ${({ theme }): string => theme.colors.primary};
  }
`;
