import { config } from "Config";
import { CrudType } from "Models";
import {
  Crud as crudService,
  LocalStorage as localStorageService,
  setLocalStorageInfo
} from "Services";
import { IUser } from "src/models/Services/IUser";
import { Utils as utilsService } from "Utils";

const create = async (body: any, token?: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Post,
      config.api.user.create,
      body,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readAll = async (queryParams: any, token?: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.user.readAll,
      null,
      queryParams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const read = async (
  id: string,
  queryparams: any,
  token?: string
): Promise<any> => {
  if (navigator.onLine) {
    try {
      const result = await crudService(
        CrudType.Get,
        config.api.user.read,
        id,
        queryparams,
        token
      );
      setLocalStorageInfo(result.item);
      localStorageService.set("userInfos", result);
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          id: result.item.id,
          workspaces: result.item.workspaces
        })
      );

      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  } else {
    const userData = JSON.parse(localStorage.getItem("currentUser") || "");
    if (!userData) {
      throw new Error("Current user not found");
    }

    setLocalStorageInfo(userData);

    const userInfos = localStorageService.get("userInfos");
    if (!userInfos) {
      throw new Error("User infos are missing in Local Storage");
    }

    return userInfos;
  }
};

const readByWorkspace = async (
  queryParams: any,
  token?: string
): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      config.api.user.readByWorkspace,
      null,
      queryParams,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const readById = async (id: string, token?: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.user.readById, { id }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const checkUserInvite = async (id: string, token?: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Get,
      utilsService.apiPlaceholderReplace(config.api.user.checkUserInvite, {
        id
      }),
      null,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const update = async (body: any, token?: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      config.api.user.update,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateTeamUser = async (
  id: string,
  body: any,
  token?: string
): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      utilsService.apiPlaceholderReplace(config.api.user.updateTeamUser, {
        id
      }),
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateInviteWorkspaces = async (
  id: string,
  status: string,
  body: any,
  token?: string
): Promise<any> => {
  try {
    const replacements = [status, id];
    return await crudService(
      CrudType.Put,
      utilsService.apiPlaceholderReplaceMultiple(
        config.api.user.updateInviteWorkspaces,
        replacements,
        0
      ),
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateCurrentWorkspace = async (
  body: any,
  token?: string
): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      config.api.user.updateCurrentWorkspace,
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateInfoCurrentWorkspace = async (
  id: string,
  body: any,
  token?: string
): Promise<any> => {
  try {
    return await crudService(
      CrudType.Put,
      utilsService.apiPlaceholderReplace(
        config.api.user.updateInfoCurrentWorkspace,
        { id }
      ),
      body,
      null,
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const remove = async (id: string, token?: string): Promise<any> => {
  try {
    return await crudService(
      CrudType.Delete,
      utilsService.apiPlaceholderReplace(config.api.user.remove, { id }),
      token
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const UserService: IUser = {
  create,
  readAll,
  read,
  readByWorkspace,
  readById,
  checkUserInvite,
  update,
  updateTeamUser,
  updateCurrentWorkspace,
  updateInviteWorkspaces,
  updateInfoCurrentWorkspace,
  delete: remove
};
