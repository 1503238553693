export const form: any = () => {
  return [
    {
      name: "email",
      label: "Email",
      helperText: "Inserisci la tua mail",
      tag: "input",
      type: "email",
      validations: [
        {
          kind: "required",
          message: "Campo required"
        },
        {
          kind: "pattern",
          reg: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          negate: true,
          message: "Campo required"
        }
      ]
    }
  ];
};
