import React from "react";
import { StyledToastContainer } from "./Toast.style";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "Theme";
import { useWindowSize } from "Utils";

const Toast: React.FC = () => {
  const theme = useTheme();

  const [width] = useWindowSize();

  return (
    <StyledToastContainer
      data-testid="toast"
      transition={Slide}
      position={
        width > theme.state.theme.breakpoints.values.md
          ? "top-center"
          : "bottom-center"
      }
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover={false}
    />
  );
};

export default Toast;
