import styled from "styled-components";
import { DatePicker } from "@material-ui/pickers";

export const PickerContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  display: flex;
`;

export const DatePickerSecondaryMUI = styled(DatePicker)`
  & .MuiInputBase-input {
    font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
    font-weight: bold;
    font-size: ${({ theme }): string => theme.fontSize.unit(2.5)};
    color: ${({ theme }): string => theme.colors.dropdownTextColor};
  }
`;

export const DatePickerMUI = styled(DatePicker)`
  font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};
  & .MuiInputBase-root:before {
    ${({ type, theme, attributes }): string =>
    type === "icon" || type === "rate"
      ? `
  position: absolute;
  z-index:1;
  content: url(${attributes?.icon});
  left: ${theme.spacing.unit(0.375)};
  top: ${theme.spacing.unit(0.375)};
  border-radius: ${theme.borderRadiusQuickInsertionSelectIcon};
  background-color: ${theme.colors.inputIconBackground}29;
  height: calc(100% - ${theme.spacing.unit(0.75)});
  width: ${theme.spacing.unit(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: ${theme.spacing.unit(1)};
    max-height: ${theme.spacing.unit(1)};
  }
`
      : ""}
  }

  & .MuiInputBase-input {
    font-family: ${({ theme }): string => theme.fonts.default.bold.fontFamily};

    line-height: 24px;

    transition: 0.3s;

    position: relative;

    border-radius: ${({ theme }): string =>
    theme.borderRadiusQuickInsertionSelect};

    background-color: ${({ theme, readOnly, disabled }): string => {
    let result = theme.colors.surface;
    result = readOnly ? theme.colors.readOnly : result;
    result = disabled ? theme.colors.disabled : result;
    return result;
  }};

    padding: ${({ theme, type }): string =>
    type === "icon" || type === "rate"
      ? `${theme.spacing.unit(0.75)} ${theme.spacing.unit(2)} 
      ${theme.spacing.unit(0.75)} ${theme.spacing.unit(3.5)}`
      : `${theme.spacing.unit(0.75)} ${theme.spacing.unit(2)} 
      ${theme.spacing.unit(0.75)} ${theme.spacing.unit(1)}`};

    border: ${({ theme, error, readOnly, disabled, isOpen, type }): string => {
    let result = "2px solid " + theme.colors.surface;
    result =
        type === "icon" || type === "rate"
          ? "2px solid " + theme.colors.inputIconBackground + "29"
          : result;
    result = isOpen ? "2px solid " + theme.colors.primary : result;
    result = readOnly ? "2px solid " + theme.colors.readOnly : result;
    result = disabled ? "2px solid " + theme.colors.disabled : result;
    result =
        error?.length > 0 ? "2px solid " + theme.colors.status01 : result;
    return result;
  }};

    box-shadow: ${({ theme, isOpen, err }): string => {
    let result = "none";
    result = isOpen
      ? "0px 0px 4px 3px " + theme.colors.alpha(theme.colors.primary, 0.2)
      : result;
    result =
        isOpen && err?.length > 0
          ? "0px 0px 4px 3px " + theme.colors.alpha(theme.colors.status01, 0.2)
          : result;
    return result;
  }};

    cursor: ${({ readOnly, disabled }): string => {
    let result = "pointer";
    result = readOnly ? "initial" : result;
    result = disabled ? "not-allowed" : result;
    return result;
  }};

    margin: ${({ theme }): string => theme.spacing.unit(0.25)}
      ${({ theme }): string => theme.spacing.unit(0.25)};

    color: ${({ theme, disabled }): string => {
    let result = "inherit";
    result = disabled ? theme.colors.textDisabled : result;
    return result;
  }};

    &:focus,
    &:hover {
      outline: none;
      border: ${({ theme, error, readOnly, disabled }): string => {
    let result = "2px solid " + theme.colors.primary;
    result = readOnly || disabled ? "inherith" : result;
    result =
          error?.length > 0 ? "2px solid " + theme.colors.status01 : result;
    return result;
  }};
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 4px 3px
        ${({ theme, error, readOnly, disabled }): string => {
    let result = theme.colors.alpha(theme.colors.primary, 0.2);
    result = readOnly ? theme.colors.readOnly : result;
    result = disabled ? theme.colors.disabled : result;
    result =
            error?.length > 0
              ? theme.colors.alpha(theme.colors.status01, 0.2)
              : result;
    return result;
  }};
    }

    &:active {
      outline: none;
      border: ${({ theme, error, readOnly, disabled }): string => {
    let result = "2px solid " + theme.colors.primary;
    result = readOnly || disabled ? "inherith" : result;
    result =
          error?.length > 0 ? "2px solid " + theme.colors.status01 : result;
    return result;
  }};
    }
  }
`;

export const PickerLabelStyled = styled.label`
  font-size: ${({ theme }): string => theme.fontSize.unit(1.5)};
  margin: 0px 4px;
  padding: 4px 2px 0px;
  color: gray;
  display: block;
`;
