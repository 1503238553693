import React from "react";
import { ModalStyled, Main } from "./Modal.style";
import { form } from "./Modal.config";
import { Button } from "Components";
import { DynamicForm, withDynamicForm } from "@wavelop/dynamic-form";

interface IProps {
  show: boolean;
  children: any;
  handleSubmit: (event: any) => void;
}

const Modal: React.FC<IProps> = ({ handleSubmit, show, children }: IProps) => {
  const showHideClassName = show ? "display-block" : "display-none";

  return (
    <ModalStyled className={showHideClassName}>
      <Main className="modal-main">{children}</Main>
      <form onSubmit={handleSubmit}>
        <DynamicForm config={form()} updateErrorAtBlur={false} debug={true} />
        <Button type="submit" name="confirm" text="Conferma" />
      </form>
    </ModalStyled>
  );
};

export default withDynamicForm()(Modal);
