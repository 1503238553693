import styled from "styled-components";

export const InsertionModal = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  gap: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
