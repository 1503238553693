import styled from "styled-components";

export const NavigationStyled = styled.div`
  display: flex;
  direction: row;
  margin-top: 14px;
`;

export const FilterStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  background-color: #ffffff;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 22px;
`;

export const CalendarStyled = styled.div`
  position: absolute;
  z-index: 2;
  border: 1px solid #ddd;
  border-radius: 4px;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  .rdrInputRanges {
    display: none;
  }
  @media (max-width: 768px) {
    .rdrDefinedRangesWrapper {
      display: none;
    }
  }
  @media (max-width: 1028px) {
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const MaxRowsStyled = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  margin-left: auto;
`;
