import { useTranslate } from "i18n";
import React from "react";
import { useAppState, User as userService, useUserState } from "Services";
import { errorToast, successToast } from "Utils";
import { DropdownRow } from "./SelectListItem.style";

interface IProps {
  focus: boolean;
  onOptionClicked: (value: string, text: string) => void;
  option: {
    value: string;
    name: string;
    fatherId: string;
  };
  selectedValue: boolean;
  type: string;
}

const SelectList: React.FC<IProps> = ({
  focus,
  onOptionClicked,
  option,
  selectedValue,
  type
}: IProps) => {
  const { state: appState } = useAppState();
  const { page } = appState;
  const userState = useUserState().state;
  const { token } = userState;
  const { t } = useTranslate();

  const selection = async (): Promise<any> => {
    if (page && page.includes("/team")) {
      try {
        const result = await userService.updateTeamUser(
          option.fatherId,
          { value: option.value },
          token
        );
        if (result.error) {
          errorToast(t("Wavelop.application.toast.team.error"));
        } else {
          successToast(t("Wavelop.application.toast.team.success"));
        }
      } catch (e) {
        console.log(e);
      }
    }
    !selectedValue && onOptionClicked(option.value, option.name);
  };

  return (
    <DropdownRow
      type={type}
      onClick={selection}
      value={option.value}
      selectedValue={selectedValue}
      focus={focus}
    >
      {option.name}
    </DropdownRow>
  );
};

export default SelectList;
