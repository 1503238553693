import { useCallback, useEffect, useState } from "react";

import { useLoading, useLoadingState, useUserState } from "Services";
import { UserRole, Pages } from "src/models/Enums/Enums";
import { checkAuthorization } from "src/utils/Grant/Grant";

export interface ClientHookData {
  isLoading: boolean;
  canISee: boolean;
}

export const useGuard = (componentName: Pages): ClientHookData => {
  const [isLoading, setIsLoadingd] = useState(true);
  const [canISee, setCanISee] = useState(false);
  const { startLoading, endLoading } = useLoading();
  const dispatchLoadingState = useLoadingState().dispatch;
  const { user } = useUserState().state;
  const userAuth = user?.workspaces.find(
    item => item.id === user?.currentWorkspace
  )?.role;
  const currentUserId = user?.id;
  const checkAuth = useCallback(async (): Promise<void> => {
    startLoading(dispatchLoadingState);

    try {
      setIsLoadingd(true);
      const result = await checkAuthorization(
        componentName,
        userAuth === "admin" ? UserRole.ADMIN : UserRole.TEAM
      );

      setCanISee(result);

      setIsLoadingd(false);
      endLoading(dispatchLoadingState);
    } catch (err) {
      console.error(err);
      setIsLoadingd(false);
      endLoading(dispatchLoadingState);
    }
  }, [startLoading, componentName, userAuth, endLoading, dispatchLoadingState]);

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentName, currentUserId]);

  return {
    isLoading,
    canISee
  };
};
