import React, { useEffect, useState } from "react";
import { TableContentStyled, RowStyled, ButtonStyled } from "./ClientRow.style";
import { DeleteIcon, ModifyIcon } from "Assets";
import { useTranslate } from "i18n";
import { Link, useHistory } from "react-router-dom";
import "moment/locale/it";
import "moment/locale/en-gb";
import { formatStatus } from "Utils";

export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
  wsRole?: string;
}

const ClientRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  data,
  type,
  wsRole
}: IProps) => {
  const { t } = useTranslate();
  const history = useHistory();

  const onDeleteButton = (event): void => {
    onDelete(id);
    event.stopPropagation();
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <TableContentStyled
      index={index}
      onClick={(): void | null =>
        wsRole === "admin" ? history.push(`clients/edit/${id}`) : null
      }
    >
      <RowStyled>{data.name}</RowStyled>
      {windowWidth < 768 ? (
        <></>
      ) : (
        <>
          <RowStyled>{data.code}</RowStyled>
          <RowStyled>{data.description}</RowStyled>
        </>
      )}
      <RowStyled>
        {data.activeProjects}/{data.totalProjects}
      </RowStyled>
      <RowStyled>{t(formatStatus(data.status))}</RowStyled>
      <RowStyled>
        {windowWidth < 768 ? (
          <></>
        ) : (
          <Link
            to={`clients/edit/${id}`}
            onClick={(event): void =>
              wsRole === "admin"
                ? event.stopPropagation()
                : event.preventDefault()
            }
          >
            <ButtonStyled
              type="image"
              src={ModifyIcon}
              alt={t("Wavelop.application.table.altEdit")}
              disabled={wsRole === "admin" ? false : true}
            />
          </Link>
        )}
        <ButtonStyled
          onClick={onDeleteButton}
          type="image"
          src={DeleteIcon}
          alt={t("Wavelop.application.table.altDelete")}
          disabled={wsRole === "admin" ? false : true}
        />
      </RowStyled>
    </TableContentStyled>
  );
};

export default ClientRow;
