import { Menu, MenuItem } from "@material-ui/core";
import { useTranslate } from "i18n";
import React from "react";
import { Button } from "src/components/Form";

const ReportExportMenu: React.FC<any> = props => {
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        style={{ marginLeft: "auto" }}
        btn="secondary"
        name="download"
        onClick={handleClick}
        text={t("Wavelop.application.reportsFilters.export")}
        type="button"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            props.setExportFormat("csv");
            if (props.groupOne === "none") {
              props.openColumnsModal();
            } else {
              props.exportAs("csv");
            }
            handleClose();
          }}
        >
          CSV
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.setExportFormat("pdf");
            if (props.groupOne === "none") {
              props.openColumnsModal();
            } else {
              props.exportAs("pdf");
            }
            handleClose();
          }}
        >
          PDF
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ReportExportMenu;
