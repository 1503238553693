import React, { useEffect, useState } from "react";
import {
  TableContentStyled,
  RowStyled,
  ButtonStyled
} from "./ProjectTeamRow.style";
import { DeleteIcon } from "Assets";
import { useTranslate } from "i18n";
import "moment/locale/it";
import "moment/locale/en-gb";
import { Chip, Switch } from "@material-ui/core";
import { GroupLink } from "./GroupLink/GroupLink";
import { RowStyledMobile } from "../TeamRow/TeamRow.style";
export interface IProps {
  id: string;
  index: number;
  onDelete: (id: string) => void;
  onSwitch?: (id: string) => void;
  data: any;
  type:
    | "activities"
    | "projects"
    | "clients"
    | "workspaces"
    | "team"
    | "projectTeam"
    | "tasks"
    | "groups"
    | "groupMembers"
    | "userGroups";
}

const ProjectTeamRow: React.FC<IProps> = ({
  id,
  index,
  onDelete,
  onSwitch,
  data
}: IProps) => {
  const { t } = useTranslate();
  const onDeleteButton = (event: any): void => {
    onDelete(id);
    event.stopPropagation();
  };
  const onSwitchButton = (): void => {
    if (onSwitch) onSwitch(id);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <TableContentStyled index={index}>
      <RowStyled>
        {data.fullName ? (
          data.fullName
        ) : (
          <>
            {data.name}{" "}
            <Chip
              style={{ backgroundColor: "#3B72E229" }}
              size="small"
              label={t("Wavelop.application.projectTeam.groupChip")}
            />
          </>
        )}
      </RowStyled>
      {windowWidth < 768 ? (
        <></>
      ) : (
        <RowStyled>
          {data.email ? data.email : <GroupLink data={data.id}></GroupLink>}
        </RowStyled>
      )}
      <RowStyled>
        {data.fullName ? (
          <Switch
            checked={data.isProjectManager}
            onClick={onSwitchButton}
            color="primary"
          ></Switch>
        ) : null}
      </RowStyled>
      <RowStyledMobile>
        <ButtonStyled
          onClick={onDeleteButton}
          type="image"
          src={DeleteIcon}
          alt={t("Wavelop.application.table.altDelete")}
        />
      </RowStyledMobile>
    </TableContentStyled>
  );
};

export default ProjectTeamRow;
