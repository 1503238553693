import {
  addWeeks,
  addYears,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfWeek,
  startOfYear
} from "date-fns";
import { useTranslate } from "i18n";
import React from "react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const ReportCalendar: React.FC<any> = (props: any) => {
  const { t } = useTranslate();

  return (
    <DateRangePicker
      color="#6672fb"
      showDateDisplay={false}
      fixedHeight={true}
      weekStartsOn={1}
      inputRanges={[]}
      staticRanges={[
        {
          ...defaultStaticRanges[0],
          label: t("Wavelop.application.reportsCalendarLabels.today")
        },
        {
          ...defaultStaticRanges[1],
          label: t("Wavelop.application.reportsCalendarLabels.yesterday")
        },
        {
          ...defaultStaticRanges[2],
          label: t("Wavelop.application.reportsCalendarLabels.currentWeek"),
          range: () => ({
            startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
            endDate: endOfWeek(new Date(), { weekStartsOn: 1 })
          })
        },
        {
          ...defaultStaticRanges[3],
          label: t("Wavelop.application.reportsCalendarLabels.lastWeek"),
          range: () => ({
            startDate: startOfWeek(addWeeks(new Date(), -1), {
              weekStartsOn: 1
            }),
            endDate: endOfWeek(addWeeks(new Date(), -1), {
              weekStartsOn: 1
            })
          })
        },
        {
          ...defaultStaticRanges[4],
          label: t("Wavelop.application.reportsCalendarLabels.currentMonth")
        },
        {
          ...defaultStaticRanges[5],
          label: t("Wavelop.application.reportsCalendarLabels.lastMonth")
        },
        {
          label: t("Wavelop.application.reportsCalendarLabels.currentYear"),
          range: () => ({
            startDate: startOfYear(new Date()),
            endDate: endOfYear(new Date())
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        },
        {
          label: t("Wavelop.application.reportsCalendarLabels.lastYear"),
          range: () => ({
            startDate: startOfYear(addYears(new Date(), -1)),
            endDate: endOfYear(addYears(new Date(), -1))
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        }
      ]}
      locale={locales[t("Wavelop.application.reportsCalendar.locale")]}
      onChange={item => {
        const dates = {
          startDate: new Date(item.selection.startDate.setHours(0, 0, 0)),
          key: item.selection.key,
          endDate: new Date(item.selection.endDate.setHours(23, 59, 59))
        };
        props.setTimeFilter([dates]);
      }}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={props.timeFilter}
      direction="horizontal"
    />
  );
};

export default ReportCalendar;
