import styled from "styled-components";

export const FormStyled = styled.form`
  border-radius: ${({ theme }): string => theme.borderRadiusQuickInsertion};
  padding: ${({ theme }): string => theme.spacing.unit(1)}
    ${({ theme }): string => theme.spacing.unit(1)};
  flex: 1;
  display: flex;
  flex-direction: column;
  span {
    padding: 0;
  }
  span:nth-child(1) {
    float: left;
  }
  .div:nth-child(1) {
    align-items: flex-start;
    flex-shrink: 0;
  }
`;

export const Buttons = styled.div`
  text-align: right;
  margin-top: ${({ theme }): string => theme.spacing.unit(1.5)};
`;
