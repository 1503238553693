import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  DatePickerSecondaryMUI,
  DatePickerMUI,
  PickerContainer,
  PickerLabelStyled
} from "./DatePicker.style";

export interface IProps {
  date: Date;
  handleDateChange: (newDate: any) => void;
  type: string;
  error: Array<any>;
  readOnly?: boolean;
  onChange: (event: any) => void;
  inputLabel: string;
  name: string;
  value: string;
  attributes: any;
  htmlFor: string;
}

const DatePicker: React.FC<any> = ({
  attributes,
  date,
  htmlFor,
  handleDateChange,
  type,
  name,
  inputLabel,
  error,
  value,
  onChange
}: IProps) => {
  const handleDateSelection = (date: any): void => {
    onChange({
      target: {
        value: date
      }
    });
  };

  const configs = {
    attributes,
    locale: "it",
    format: "DD MMMM",
    variant: "inline",
    id: name,
    error: error?.length > 0 ? true : false,
    InputProps: {
      disableUnderline: true
    },
    type,
    autoOk: true
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="it">
      <PickerContainer>
        {inputLabel && (
          <PickerLabelStyled htmlFor={htmlFor}>{inputLabel}</PickerLabelStyled>
        )}
        {type === "secondary" && (
          <DatePickerSecondaryMUI
            {...configs}
            value={date}
            onChange={handleDateChange}
          />
        )}
        {(type === "primary" || type === "icon") && (
          <DatePickerMUI
            {...configs}
            value={value}
            onChange={handleDateSelection}
          />
        )}
        {type === "rate" && (
          <DatePickerMUI
            {...configs}
            value={value}
            minDate={attributes.minDate}
            maxDate={new Date(Date.now())}
            onChange={handleDateSelection}
          />
        )}
      </PickerContainer>
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
