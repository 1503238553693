import styled from "styled-components";
export const Icon = styled.img`
  vertical-align: middle;
  margin-right: ${({ theme }): string => theme.spacing.unit(0.25)};
`;
export const SmallString = styled.span`
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
`;
