import styled from "styled-components";

export const CustomRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  @media (max-width: 768px) {
    flex-direction: column;
    width: -webkit-fill-available;
    width: -moz-available;
  }
`;
