import { useTranslate } from "i18n";
import React, { Fragment } from "react";
import { checkDateIsValidAndFormat, fromHoursToTimeString } from "Utils";
import { RowStyled, TableContentGroupedThreeStyled } from "./ReportRow.style";

const ReportRowGroupedThree: React.FC<any> = props => {
  const { t } = useTranslate();

  return (
    <Fragment key={`${props.type}.${props.index}`}>
      <TableContentGroupedThreeStyled>
        <RowStyled type={"grouped"} style={{ marginLeft: "32px" }}>
          {checkDateIsValidAndFormat(
            props.data._id.name,
            t("Wavelop.application.locale")
          )}
        </RowStyled>
        <RowStyled type={"grouped"}>{`${fromHoursToTimeString(
          props.data.hours
        )}`}</RowStyled>
      </TableContentGroupedThreeStyled>
    </Fragment>
  );
};

export default ReportRowGroupedThree;
